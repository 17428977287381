import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

//  CREATE NEW SERVICE CAR ISSUE
const createNewCargoIssue = async (issueData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/home/serviceCar/newIssue`,
    issueData,
    config
  );

  return response.data;
};

//  GET SERVICE CAR ISSUES
const getCargoIssues = async (cargoId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/serviceCar/issues/${cargoId}`,
    config
  );

  return response.data;
};

// GET LATEST SERVICE CAR ISSUE
const getLatestIssue = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/serviceCar/latestIssue`,
    config
  );

  return response.data;
};

//  GET RESOLVED SERVICE CAR ISSUES
const getResolvedCargoIssues = async (cargoId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/serviceCar/resolvedIssues/${cargoId}`,
    config
  );

  return response.data;
};

//  RESOLVE SERVICE CAR ISSUE
const updateCargoIssue = async (issueId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/home/serviceCar/issues/resolved/${issueId}`,
    issueId,
    config
  );

  return response.data;
};

const cargoIssueService = {
  createNewCargoIssue,
  getCargoIssues,
  getResolvedCargoIssues,
  updateCargoIssue,
  getLatestIssue,
};

export default cargoIssueService;
