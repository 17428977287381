const Button = ({ text, type, onClick }) => {
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className="border-1 px-3 py-2 my-2 mx-2 rounded-full border-[#1ce5be] text-[#ececec] text-xl font-medium hover:text-[#ff5783]"
      >
        {text}
      </button>
    </>
  );
};

export default Button;
