import { useEffect, useMemo } from "react";
import { getScooterStatus } from "../../../Features/opHoppData/opDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { Chart } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Spinner from "../../Spinner/Spinner";

const options = {
  plugins: {
    legend: {
      title: {
        display: false,
      },
      display: false,
      labels: {
        fontColor: "#ececec",
        font: {
          size: 15,
        },
        textAlign: "center",
      },
    },
    title: {
      display: false,
      text: "Vehicle status",
      color: "#ececec",
      font: {
        size: 18,
      },
    },
  },
};

const FleetInfo = ({ report, setReport }) => {
  const dispatch = useDispatch();

  const { vehicleStatus, isLoading, isSuccess } = useSelector(
    (state) => state.opData
  );

  const memoizedVehicleStatus = useMemo(() => vehicleStatus, [vehicleStatus]);

  useEffect(() => {
    if (!isSuccess) {
      dispatch(getScooterStatus());
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    if (memoizedVehicleStatus && typeof setReport === "function") {
      setReport({
        ...report,
        activeScooters: memoizedVehicleStatus.activeScooters,
        maintenanceScooters: memoizedVehicleStatus.maintenanceScooters,
        disabledScooters: memoizedVehicleStatus.disabledScooters,
      });
    }
  }, [memoizedVehicleStatus, setReport]);

  const dataset = {
    labels: ["Active scooters", "Maintenance scooters", "Disabled scooters"],
    datasets: [
      {
        backgroundColor: ["#1ce5be", "#ff5783", "#457FE5"],
        borderColor: "#1f2022",
        data: [
          memoizedVehicleStatus.activeScooters,
          memoizedVehicleStatus.maintenanceScooters,
          memoizedVehicleStatus.disabledScooters,
        ],
        borderWidth: 3,
      },
    ],
  };

  return (
    <div className="flex items-center justify-center h-80 w-full mt-5 mr-5 my-5">
      {isLoading ? <Spinner /> : <Doughnut options={options} data={dataset} />}
    </div>
  );
};

export default FleetInfo;
