import React from "react";
import { VscWarning } from "react-icons/vsc";
import { BsCheckLg } from "react-icons/bs";

const IssueCount = ({ issues }) => {
  return (
    <div className="flex items-center justify-center">
      {issues > 0 ? (
        <>
          <VscWarning
            className={`text-lg font-semibold mr-1 ${
              issues > 2 ? "text-[#e74c3c]" : "text-[#f1c40f]"
            }`}
          />
          <span className="text-[#ececec] text-lg font-semibold">{issues}</span>
        </>
      ) : (
        <BsCheckLg className="text-[#1ce5be] text-lg font-semibold " />
      )}
    </div>
  );
};

export default IssueCount;
