import { Chart } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const options = {
  plugins: {
    legend: {
      title: {
        display: false,
      },
      display: false,
      labels: {
        fontColor: "#ececec",
        font: {
          size: 15,
        },
        textAlign: "center",
      },
    },
    title: {
      display: false,
      text: "Vehicle status",
      color: "#ececec",
      font: {
        size: 18,
      },
    },
  },
};

const FleetChart = ({
  activeScooters,
  maintenanceScooters,
  disabledScooters,
}) => {
  const dataset = {
    labels: ["Active scooters", "Maintenance scooters", "Disabled scooters"],
    datasets: [
      {
        backgroundColor: ["#1ce5be", "#ff5783", "#457FE5"],
        borderColor: "#1f2022",
        data: [activeScooters, maintenanceScooters, disabledScooters],
        borderWidth: 3,
      },
    ],
  };

  return (
    <div className="flex items-center justify-center h-48 w-48 lg:h-80 lg:w-full mt-5 mr-5 my-5">
      <Doughnut options={options} data={dataset} />
    </div>
  );
};

export default FleetChart;
