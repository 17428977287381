const Input = ({
  label,
  type,
  id,
  name,
  onChange,
  value,
  autoComplete,
  placeholder,
  maxLength,
  hideLabel = false,
}) => {
  return (
    <div className="w-80 flex flex-col my-3">
      {hideLabel ? null : (
        <label className="text-[#ececec] mb-2 text-lg">{label}:</label>
      )}
      <input
        className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default Input;
