import { useState } from "react";
import ShiftReportIndex from "../../Components/Forms/ShiftReportForm/ShiftReportIndex";
import {
  createShiftReport,
  reset,
} from "../../Features/shiftReport/shiftReportSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Spinner from "../../Components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import ShiftReportAccordion from "../../Components/Forms/ShiftReportForm/ShiftReportAccordion";

const successToast = (message) =>
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const ShiftReport = () => {
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [report, setReport] = useState({
    shiftType: "",
    maintenanceScooters: 0,
    activeScooters: 0,
    disabledScooters: 0,
    employeeCount: 0,
    firstSc: [],
    secondSc: [],
    reports: [],
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isError, isLoading, message, isSuccess } = useSelector(
    (state) => state.shiftReport
  );

  const { shiftNumbers } = useSelector((state) => state.shiftReport);

  useEffect(() => {
    if (isSuccess) {
      successToast("Shift Report created!");
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
    }
  }, [isSuccess, isError, message, navigate, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();

    const reports = report.reports;
    const batteryCount = shiftNumbers;

    const addField = batteryCount.reduce(
      (a, { staff_id, batteries_replaced }) => {
        a[staff_id] = batteries_replaced;
        return a;
      },
      {}
    );

    const result = reports.map((e) => {
      e.batteries_replaced = addField[e.hopper];

      return e;
    });

    setReport({ ...report, report: result });

    dispatch(createShiftReport(report));
  };

  if (isLoading) {
    return <Spinner />;
  }

  const componentList = [
    <ShiftReportIndex
      page={page}
      report={report}
      setReport={setReport}
      setPage={setPage}
      setLastPage={setLastPage}
      lastPage={lastPage}
    />,
    <ShiftReportAccordion
      report={report}
      setReport={setReport}
      onSubmit={onSubmit}
      shiftNumbers={shiftNumbers}
    />,
  ];

  return (
    <>
      {componentList[page]}
      <ToastContainer />
    </>
  );
};

export default ShiftReport;
