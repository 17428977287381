import { useState } from "react";
import Moment from "react-moment";
import { MdHideImage, MdImage } from "react-icons/md";

import ImageModal from "../../Modal/ImageModal";

const ResolvedIssueTable = ({ resolvedIssues }) => {
  const [imageModal, setImageModal] = useState(false);
  const [imageLink, setImageLink] = useState("");

  return (
    <>
      {resolvedIssues.length > 0 ? (
        <section className="my-5">
          <table>
            <tbody>
              <tr className="text-center ">
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  Issue type:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                  Description:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                  Image:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                  Date created:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3  mx-2 text-center pb-2">
                  Created by:
                </th>
              </tr>
              {resolvedIssues.map((issue, index) => {
                return (
                  <tr key={index} className="border-b border-[#1ce5be]">
                    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                      {issue.issueType}
                    </td>
                    <td className="pr-3 py-3 text-sm text-center w-20 h-20 text-[#ececec]">
                      {issue.description}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      {issue.image.length > 0 ? (
                        <MdImage
                          className="text-xl text-center ml-2 text-[#ececec]"
                          onClick={() => {
                            setImageLink(issue.image[0].url);
                            setImageModal(true);
                          }}
                        />
                      ) : (
                        <MdHideImage className="text-xl text-center ml-2 text-[#ececec]" />
                      )}
                    </td>
                    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                      <Moment format="DD-MM-YYYY">{issue.updatedAt}</Moment>
                    </td>
                    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                      {issue.userCreated?.firstName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      ) : null}

      {imageModal && (
        <ImageModal setImageModal={setImageModal} imageLink={imageLink} />
      )}
    </>
  );
};

export default ResolvedIssueTable;
