import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// ADD ADDITIONAL INFO TO  DEILIBILAR ISSUE
const additionalInfo = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(
    `${API_LINK}/api/dashboard/fleet/allIssues/update`,
    data,
    config
  );

  return response.data;
};

const infoService = {
  additionalInfo,
};

export default infoService;
