import React from 'react'

const ShiftInput = ({label, placeholder, value, name, onChange}) => {
  return (
    <>
    <div className="flex flex-col items-center py-2 my-4">
        <label className="text-[#ececec] mb-2 text-lg">{label}</label>
        <input
        className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
        type='number'
        min="0"
        max="1000"
        onWheel={(e) => e.target.blur()}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        />
    </div>
    </>
  )
}

export default ShiftInput