import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import CaptainHome from "./Pages/CaptainHome/CaptainHome";
import ShiftReport from "./Pages/ShiftReport/ShiftReport";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import CarReport from "./Components/SingleComponents/CarReport/CarReport";
import Layout from "./Layouts/Layout/Layout";
import AdminLayout from "./Layouts/Layout/AdminLayout";
import NewFleet from "./Components/Fleet/NewFleet/NewFleet";
import NewCargo from "./Components/Fleet/NewFleet/NewCargo";
import CargoCar from "./Components/SingleComponents/CargoCar/CargoCar";
import UserProfile from "./Components/UserProfile/UserProfile";
import AllDeilibilars from "./Pages/Fleet/AllDeilibilars";
import AllCargoCars from "./Components/Fleet/CargoCars/AllCargoCars";
import AllCarReportTables from "./Components/Tables/LargeTables/DailyCarReport/AllCarReportTables";
import AllShiftReportTable from "./Components/Tables/LargeTables/ShiftReport/AllShiftReportTable";
import SingleShiftReport from "./Components/SingleComponents/ShiftReport/ShiftReport";
import AllCharts from "./Pages/Charts/AllCharts";
import UpdateShiftReport from "./Components/SingleComponents/ShiftReport/UpdateShiftReport";
import EditDeilibilar from "./Components/SingleComponents/DailyCar/EditDeilibilar";
import CaptainPromote from "./Components/CaptainPromote/CaptainPromote";
import NewIssueModal from "./Components/SingleComponents/CargoCar/NewIssueModal";
import DailyCarForm from "./Pages/DailyCarReport/DailyCarForm";
import Announcement from "./Pages/Announcement/Announcement";
import ServiceCars from "./Pages/ServiceCars/ServiceCars";
import DailyCars from "./Pages/DailyCars/DailyCars";
import NewEmployee from "./Pages/NewEmployee/NewEmployee";
import Hoppers from "./Pages/Hoppers/Hoppers";
import Captains from "./Pages/Captains/Captains";
import EmployeeProfile from "./Components/EmployeeProfile/EmployeeProfile";
import DailyCarPage from "./Pages/DailyCarPage/DailyCarPage";
import FixerReport from "./Pages/FixerReport/FixerReport";
import FixerReportTable from "./Components/Tables/LargeTables/FixerReport/FixerReportTable";
import FixerReportCard from "./Components/SingleComponents/FixerReportCard/FixerReportCard";
import ServiceCarReport from "./Pages/ServiceCarReport/ServiceCarReport";
import AllServiceCarReportTable from "./Components/Tables/LargeTables/ServiceCarReport/AllServiceCarReportTable";
import ServiceReport from "./Components/SingleComponents/ServiceReport/ServiceReport";
import UpdateCarReport from "./Components/SingleComponents/CarReport/UpdateCarReport";
import DeilibilarIssues from "./Components/Tables/LargeTables/DeilibilarIssues/DeilibilarIssues";
import DailyCarIssueForm from "./Components/SingleComponents/DailyCarIssueForm/DailyCarIssueForm";
import EditDailyCarIssue from "./Components/SingleComponents/DailyCarIssueForm/EditDailyCarIssue";
import Map from "./Pages/Map/Map";
import SuspendedUsers from "./Pages/SuspendedUsers/SuspendedUsers";
import HopperLayout from "./Layouts/Layout/HopperLayout";
import HopperHome from "./Pages/HopperHome/HopperHome";
// import WorkshopDashboard from "./Pages/Workshop/WorkshopDashboard";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/invitationLink/:id/:token" element={<CaptainPromote />} />
      <Route path="/" element={<Login />} />

      <Route path="/index" element={<HopperLayout />}>
        <Route index element={<HopperHome />} />
        <Route path="serviceCarReport" element={<ServiceCarReport />} />
        <Route path="serviceCars" element={<ServiceCars />} />
        <Route path="serviceCars/:id" element={<CargoCar />} />
      </Route>

      <Route path="/home/*" element={<Layout />}>
        <Route index element={<CaptainHome />} />
        <Route path="carReport" element={<DailyCarForm />} />
        <Route path="allDeilibilars" element={<DailyCars />} />
        <Route path="allDeilibilars/:id" element={<DailyCarPage />} />
        <Route
          path="deilibilar/allIssues/:id"
          element={<DailyCarIssueForm />}
        />
        <Route path="cargoVehicles/:id" element={<CargoCar />} />
        <Route path="shiftReport" element={<ShiftReport />} />
        <Route path="fixerReport" element={<FixerReport />} />
        <Route path="serviceCars" element={<ServiceCars />} />
        <Route path="serviceCars/newIssue/:id" element={<NewIssueModal />} />
        <Route path="shiftReports" element={<AllShiftReportTable />} />
        <Route path="fixerReports" element={<FixerReportTable />} />
        <Route path="deilibilarReports" element={<AllCarReportTables />} />
        <Route path="deilibilarReports/:id" element={<CarReport />} />
        <Route
          path="deilibilarReport/update/:id"
          element={<UpdateCarReport />}
        />
        <Route path="shiftReports/:id" element={<SingleShiftReport />} />
        <Route path="fixerReports/:id" element={<FixerReportCard />} />
        <Route path="profile" element={<UserProfile />} />
        <Route path="serviceCarReport" element={<ServiceCarReport />} />
      </Route>

      <Route path="/dashboard/*" element={<AdminLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="map" element={<Map />} />

        <Route path="allCharts" element={<AllCharts />} />
        <Route path="announcement" element={<Announcement />} />
        {/* _________________ Shift report routes ________________________ */}
        <Route path="shiftReports/*">
          <Route index element={<AllShiftReportTable />} />
          <Route path=":id" element={<SingleShiftReport />} />
          <Route path="update/:id" element={<UpdateShiftReport />} />
        </Route>
        {/* _________________ Service report routes _______________________ */}
        <Route path="serviceReports/*">
          <Route index element={<AllServiceCarReportTable />} />
          <Route path=":id" element={<ServiceReport />} />
        </Route>
        {/* _________________ Fleet routes ________________________________ */}
        <Route path="allFleet/*">
          <Route path="deilibilar/*" />
          <Route
            path="deilibilar/createNew"
            element={<NewFleet title={"New Deilibilar"} />}
          />
          <Route path="deilibilar/all" element={<AllDeilibilars />} />
          <Route path="deilibilar/:id" element={<DailyCarPage />} />
          <Route path="deilibilar/update/:id" element={<EditDeilibilar />} />
          <Route path="deilibilar/allIssues" element={<DeilibilarIssues />} />
          <Route
            exact
            path="deilibilar/updateIssue/:id"
            element={<EditDailyCarIssue />}
          />
          {/* ______________ Service vehicles ________________________ */}
          <Route path="cargoVehicles/*" />
          <Route
            path="cargoVehicles/createNew"
            element={<NewCargo title={"New service car"} />}
          />
          <Route path="cargoVehicles/:id" element={<CargoCar />} />
          <Route path="cargoVehicles/all" element={<AllCargoCars />} />
        </Route>
        {/* ____________________ Team routes ____________________________ */}
        <Route path="team/*">
          <Route path=":id" element={<EmployeeProfile />} />
          <Route path="hoppers" element={<Hoppers />} />
          <Route path="captains" element={<Captains />} />
          <Route path="create" element={<NewEmployee />} />
          <Route path="disabled" element={<SuspendedUsers />} />
        </Route>
        <Route path="workshop/*">
          {/* <Route index element={<WorkshopDashboard />} /> */}
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
