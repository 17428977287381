import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import serviceCarReportService from "./serviceCarReportService";

const initialState = {
  serviceReport: [],
  allReports: [],
  singleReport: [],
  delete: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: null,
};

// create new service car report
export const createServiceCarReport = createAsyncThunk(
  "home/serviceCar/newReport",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceCarReportService.createServiceCarReport(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// DELETE service car report
export const deleteServiceReport = createAsyncThunk(
  "home/serviceCar/delete/:id",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceCarReportService.deleteServiceCarReport(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get all service vehicle reports
export const getAllServiceReports = createAsyncThunk(
  "dashboard/serviceCar/allReports",
  async (filter, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceCarReportService.getServiceReports(filter, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get service report by ID
export const getSingleReport = createAsyncThunk(
  "dashboard/serviceCar/report/:id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await serviceCarReportService.getSingleServiceReport(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const serviceCarReportSlice = createSlice({
  name: "serviceReport",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createServiceCarReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createServiceCarReport.fulfilled, (state, action) => {
        state.isSuccess = action.payload.success;
        state.serviceReport.push(action.payload);
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(createServiceCarReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteServiceReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteServiceReport.fulfilled, (state, action) => {
        state.isSuccess = action.payload.success;
        state.delete.push(action.payload);
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(deleteServiceReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllServiceReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllServiceReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allReports = action.payload;
      })
      .addCase(getAllServiceReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSingleReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleReport = action.payload;
      })
      .addCase(getSingleReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = serviceCarReportSlice.actions;
export default serviceCarReportSlice.reducer;
