import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CarReportTable from "../../Components/Tables/SmallTables/DailyCarReports/CarReportTable";
import ShiftReportTable from "../../Components/Tables/SmallTables/ShiftReports/ShiftReportTable";
import Charts from "../Charts/Charts";
import { Chart, registerables } from "chart.js";
import CargoLatestIssue from "../../Components/SingleComponents/CargoCar/CargoLatestIssue";
import DailyCarLatestIssue from "../../Components/DailyCar/DailyCarLatestIssue/DailyCarLatestIssue";
Chart.register(...registerables);

export const Dashboard = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <>
      <main className="flex justify-center items-center flex-col">
        <h3 className="text-2xl md:my-5 my-3 text-[#ececec] font-bold uppercase">
          DASHBOARD
        </h3>
        <section className="mb-10 flex justify-center items-center flex-col">
          <Charts />
        </section>
        <h3 className="text-2xl md:my-5 my-3 text-[#ececec] font-bold uppercase">
          LATEST FLEET ISSUES
        </h3>
        <section className="flex flex-col mb-10 md:flex-row">
          <DailyCarLatestIssue />
          <CargoLatestIssue />
        </section>
        <h3 className="text-2xl md:my-5 my-3 text-[#ececec] font-bold uppercase">
          LATEST REPORTS
        </h3>
        <section className="mb-5 flex items-center justify-center flex-col">
          <CarReportTable />
          <ShiftReportTable />
        </section>
      </main>
    </>
  );
};

export default Dashboard;
