import { getSumByDate } from "../../Features/Charts/DateRangeStats/dateRangeSlice";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import NewWeek from "./NewWeek";
import DateInput from "../../Components/UI/dateInput/DateInput";
import DataCard from "./DataCard";
import Spinner from "../../Components/Spinner/Spinner";

const AllCharts = () => {
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
    extraStartDate: "",
    extraEndDate: "",
  });

  const updateDates = (newDates) => {
    setDates(newDates);
  };

  const dispatch = useDispatch();

  const { sumData, isLoading } = useSelector((state) => state.barRangeData);

  const applySecondFilter = (e) => {
    e.preventDefault();
    dispatch(getSumByDate(dates));
  };

  return (
    <>
      <div className="flex items-center justify-center text-3xl my-3 text-[#ff5783] uppercase">
        <h3 className="text-2xl mt-3 text-[#ff5783] uppercase">ANalytics</h3>
      </div>
      <section className="flex items-center justify-center flex-col">
        <div className="flex flex-col md:flex-row justify-center items-center my-5">
          <DateInput
            onChange={(e) => {
              setDates({ ...dates, startDate: e.target.value });
            }}
          />

          <span className="text-[#ff5783] my-2">to</span>
          <DateInput
            onChange={(e) => {
              setDates({ ...dates, endDate: e.target.value });
            }}
          />
        </div>

        <NewWeek
          setDates={updateDates}
          dates={dates}
          applySecondFilter={applySecondFilter}
        />
      </section>
      <section className="flex flex-col md:flex-row items-center justify-center">
        {isLoading ? (
          <Spinner />
        ) : (
          sumData &&
          sumData.total &&
          sumData.extraTotal && (
            <>
              <DataCard
                startDate={dates.startDate}
                endDate={dates.endDate}
                batteries={sumData.total.batteries}
                hikes={sumData.total.hikes}
                rebalance={sumData.total.rebalance}
                fixed={sumData.total.fixed}
                pickedMtc={sumData.total.pickedMtc}
                leftMtc={sumData.total.leftMtc}
                hours={sumData.deilibilarService.hours}
                minutes={sumData.deilibilarService.minutes}
                morning={sumData.morningHours}
                evening={sumData.eveningHours}
                night={sumData.nightHours}
                total={
                  sumData.morningHours +
                  sumData.eveningHours +
                  sumData.nightHours
                }
                morningTotal={sumData.morningTotal}
                eveningTotal={sumData.eveningTotal}
                nightTotal={sumData.nightTotal}
              />
              <DataCard
                startDate={dates.extraStartDate}
                endDate={dates.extraEndDate}
                batteries={sumData.extraTotal.batteries}
                hikes={sumData.extraTotal.hikes}
                rebalance={sumData.extraTotal.rebalance}
                fixed={sumData.extraTotal.fixed}
                pickedMtc={sumData.extraTotal.pickedMtc}
                leftMtc={sumData.extraTotal.leftMtc}
                hours={sumData.extraDeilibilarService.extraHours}
                minutes={sumData.extraDeilibilarService.extraMinutes}
                morning={sumData.extraMorningHours}
                evening={sumData.extraEveningHours}
                night={sumData.extraNightHours}
                total={
                  sumData.extraMorningHours +
                  sumData.extraEveningHours +
                  sumData.extraNightHours
                }
                morningTotal={sumData.extraMorningTotal}
                eveningTotal={sumData.extraEveningTotal}
                nightTotal={sumData.extraNightTotal}
              />
            </>
          )
        )}
      </section>
    </>
  );
};

export default AllCharts;
