import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GoIssueOpened } from "react-icons/go";
import { useState } from "react";
import { HiCheck } from "react-icons/hi";
import Spinner from "../../Spinner/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  getCargoIssuesById,
  getResolvedCargoIssues,
} from "../../../Features/cargoIssues/cargoIssueSlice";
import NewIssueBtn from "./NewIssueBtn";
import ResolvedIssueTable from "../../DailyCar/DailyCarResolvedIssues/ResolvedIssueTable";

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const Tabs = () => {
  const [toggleTab, setToggleTab] = useState(1);

  const handleToggle = (index) => {
    setToggleTab(index);
  };

  const { id } = useParams();

  const { allCargoIssues, resolvedCargoIssues, isLoading, isError, message } =
    useSelector((state) => state.cargoIssues);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      return errToast(message);
    }

    dispatch(getCargoIssuesById(id));
    dispatch(getResolvedCargoIssues(id));
  }, [dispatch, id, message, isError]);

  return (
    <>
      <section className="my-5">
        {/* Tabs */}
        <div className="border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li className="mr-2">
              <span
                className={
                  toggleTab === 1
                    ? "inline-flex hover:cursor-pointer  items-center text-lg p-4 text-[#1ce5be] border-b-2 border-[#1ce5be] rounded-t-lg active dark:text-[#1ce5be] dark:border-[#1ce5be] group"
                    : "inline-flex hover:cursor-pointer  items-center text-lg p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                }
                onClick={() => handleToggle(1)}
              >
                <GoIssueOpened className="w-5 h-5 mx-2" />
                Issues{" "}
                <span
                  className="bg-[#f1c40f] text-lg
                   rounded-full mx-2 w-7 h-7 text-[#1f2022]"
                >
                  {allCargoIssues ? allCargoIssues?.length : null}
                </span>
              </span>
            </li>

            <li className="mr-2">
              <span
                className={
                  toggleTab === 2
                    ? "inline-flex hover:cursor-pointer items-center text-lg p-4 text-[#1ce5be] border-b-2 border-[#1ce5be] rounded-t-lg active dark:text-[#1ce5be] dark:border-[#1ce5be] group"
                    : "inline-flex hover:cursor-pointer items-center text-lg p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                }
                onClick={() => handleToggle(2)}
              >
                <HiCheck className="w-5 h-5 mx-2" />
                Issue history
              </span>
            </li>
          </ul>
        </div>

        {/* Content */}
        <div className="">
          <div
            className={toggleTab === 1 ? "block md:block" : "hidden md:hidden"}
          >
            <NewIssueBtn allIssues={allCargoIssues} />
          </div>
          <div
            className={toggleTab === 3 ? "block md:block" : "hidden md:hidden"}
          ></div>
          <div
            className={toggleTab === 2 ? "block md:block" : "hidden md:hidden"}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <ResolvedIssueTable resolvedIssues={resolvedCargoIssues} />
            )}
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Tabs;
