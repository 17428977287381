import AllCargoCars from "../../Components/Fleet/CargoCars/AllCargoCars";

const ServiceCars = () => {
  return (
    <>
      <section className="flex items-center flex-col justify-center">
        <div className="text-2xl my-3 text-[#ff5783] uppercase">
          <h3>Service cars</h3>
        </div>
        <div>
          <AllCargoCars />
        </div>
      </section>
    </>
  );
};

export default ServiceCars;
