import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// GET DATA BY DATE RANGE
const getSumByDateRange = async (dates, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      startDate: dates.startDate,
      endDate: dates.endDate,
      extraStartDate: dates.extraStartDate,
      extraEndDate: dates.extraEndDate,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/charts/monthlyStats/dateRange`,
    config
  );

  return response.data;
};

const getTotalNumbers = async (dates, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      startDate: dates.startDate,
      endDate: dates.endDate,
      extraStartDate: dates.extraStartDate,
      extraEndDate: dates.extraEndDate,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/charts/dataRangeSum`,
    config
  );

  return response.data;
};

const dateRangeService = {
  getSumByDateRange,
  getTotalNumbers,
};

export default dateRangeService;
