import React from "react";
import AllDailyCars from "../../Components/Fleet/DailyCars/AllDailyCars";

const AllDeilibilars = () => {
  return (
    <main>
      <AllDailyCars />
    </main>
  );
};

export default AllDeilibilars;
