import Spinner from "../Spinner/Spinner";
import Moment from "react-moment";
import { useEffect, useState } from "react";
import { BiUser } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getUserProfile,
  updateUser,
  promoteEmployee,
  resetPassword,
  reset,
} from "../../Features/team/teamSlice";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../Modal/Modal";
import Button from "../UI/button/Button";

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const EmployeeProfile = () => {
  const [disableModal, setDisableModal] = useState(false);
  const [promoteModal, setPromoteModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { employeeProfile, isLoading, isSuccess, message, isError } =
    useSelector((state) => state.team);

  useEffect(() => {
    if (isError) {
      errToast(message);
      dispatch(reset());
    }

    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }

    dispatch(getUserProfile(id));
  }, [dispatch, isError, isSuccess, message, navigate, id]);

  const disableUser = () => {
    dispatch(updateUser(id));
    setDisableModal(false);
  };

  const handlePromote = () => {
    dispatch(promoteEmployee({ email: employeeProfile.email }));
    setPromoteModal(false);
  };

  const handleResetPassword = () => {
    dispatch(resetPassword({ email: employeeProfile.email }));
    setPromoteModal(false);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <main className="flex justify-center flex-col items-center mt-3">
          <div className="md:flex">
            <section className="flex flex-col items-center justify-center mx-20">
              <BiUser className="text-[#1ce5be] text-7xl mr-2 mt-1" />
              <span className="text-[#ececec] text-lg my-2 ">
                Role: {employeeProfile && employeeProfile.role}
              </span>
              <div className="flex flex-col">
                {employeeProfile && employeeProfile.role === "Hopper" ? (
                  <div className="">
                    <Button
                      text="Promote to captain"
                      type="button"
                      onClick={() => setPromoteModal(true)}
                    />
                    <Button
                      text="Reset password"
                      type="button"
                      onClick={() => setResetModal(true)}
                    />
                  </div>
                ) : null}
                {employeeProfile && employeeProfile.role === "Captain" ? (
                  <Button
                    text="Reset password"
                    type="button"
                    onClick={() => setResetModal(true)}
                  />
                ) : null}
                <Button
                  text="Disable"
                  type="button"
                  onClick={() => setDisableModal(true)}
                />
              </div>
            </section>
            <section className="flex flex-col items-start ">
              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  First Name:
                </span>
                <span className="text-[#ececec] mb-3">
                  {employeeProfile && employeeProfile.firstName}
                </span>
              </div>

              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  Last Name:
                </span>
                <span className="text-[#ececec] mb-3">
                  {employeeProfile && employeeProfile.lastName}
                </span>
              </div>

              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  Email:
                </span>
                <span className="text-[#ececec] mb-3">
                  {employeeProfile && employeeProfile.email}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  Phone number:
                </span>
                <span className="text-[#ececec] mb-3">
                  {employeeProfile && employeeProfile.phoneNumber}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  OP ID:
                </span>
                <span className="text-[#ececec] mb-3">
                  {employeeProfile && employeeProfile.api_id}
                </span>
              </div>

              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  User since:
                </span>
                <Moment
                  className="text-[#ececec] mb-3"
                  format="DD-MM-YYYY HH:mm"
                >
                  {employeeProfile && employeeProfile.createdAt}
                </Moment>
              </div>
            </section>
          </div>
        </main>
      )}

      {promoteModal && (
        <Modal
          message="You are about to send invitation link to employee. Please note that link will expire after 1 hour."
          setOpenModal={setPromoteModal}
          onClick={handlePromote}
        />
      )}
      {resetModal && (
        <Modal
          message="You are about to send reset password link to employee. Please note that link will expire after 1 hour."
          setOpenModal={setPromoteModal}
          onClick={handleResetPassword}
        />
      )}

      {disableModal && (
        <Modal
          message="Are you sure you want to disable this employee ?"
          setOpenModal={setDisableModal}
          onClick={disableUser}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default EmployeeProfile;
