import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";

const TableSkeleton = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <table>
      <tbody>
        <tr className="text-center">
          {user.role === "Admin" ? (
            <th className="text-[#1ce5be] hidden md:table-cell pr-3 text-center pb-2">
              Shift average:
            </th>
          ) : null}
          <th className="text-[#1ce5be] pr-3 text-center pb-2">Shift type:</th>
          <th className="text-[#1ce5be] hidden md:table-cell pr-3 text-center pb-2">
            Active scooters:
          </th>
          <th className="text-[#1ce5be] hidden md:table-cell pr-3 text-center pb-2">
            Disabled scooters:
          </th>
          <th className="text-[#1ce5be] hidden md:table-cell pr-3 text-center pb-2">
            MTC scooters:
          </th>
          <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
            Employee count:
          </th>
          <th className="text-[#1ce5be] text-sm pr-3 mx-1 text-center pb-2">
            Captain:
          </th>
          <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
            Created at:
          </th>
        </tr>
        <tr>
          <td>
            <SkeletonTheme
              baseColor="transparent"
              highlightColor="#1ce5be"
              borderRadius="0.5rem"
              duration={2}
            >
              <Skeleton height={25} width={55} count={2} />
            </SkeletonTheme>
          </td>
          <td>
            <SkeletonTheme
              baseColor="transparent"
              highlightColor="#1ce5be"
              borderRadius="0.5rem"
              duration={2}
            >
              <Skeleton height={25} width={75} count={2} />
            </SkeletonTheme>
          </td>
          <td className="hidden md:table-cell">
            <SkeletonTheme
              baseColor="transparent"
              highlightColor="#1ce5be"
              borderRadius="0.5rem"
              duration={2}
            >
              <Skeleton height={25} width={75} count={2} />
            </SkeletonTheme>
          </td>
          <td>
            <SkeletonTheme
              baseColor="transparent"
              highlightColor="#1ce5be"
              borderRadius="0.5rem"
              duration={2}
            >
              <Skeleton height={25} width={75} count={2} />
            </SkeletonTheme>
          </td>
          <td className="hidden md:table-cell">
            <SkeletonTheme
              baseColor="transparent"
              highlightColor="#1ce5be"
              borderRadius="0.5rem"
              duration={2}
            >
              <Skeleton height={25} width={75} count={2} />
            </SkeletonTheme>
          </td>
          <td className="hidden md:table-cell">
            <SkeletonTheme
              baseColor="transparent"
              highlightColor="#1ce5be"
              borderRadius="0.5rem"
              duration={2}
            >
              <Skeleton height={25} width={75} count={2} />
            </SkeletonTheme>
          </td>
          <td>
            <SkeletonTheme
              baseColor="transparent"
              highlightColor="#1ce5be"
              borderRadius="0.5rem"
              duration={2}
            >
              <Skeleton height={25} width={75} count={2} />
            </SkeletonTheme>
          </td>
          <td className="hidden md:table-cell">
            <SkeletonTheme
              baseColor="transparent"
              highlightColor="#1ce5be"
              borderRadius="0.5rem"
              duration={2}
            >
              <Skeleton height={25} width={75} count={2} />
            </SkeletonTheme>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableSkeleton;
