import { Link } from "react-router-dom";

const HomeNavigation = (props) => {
  return (
    <>
      <Link to={props.route}>
        <div className="p-7 border-2 md:w-40 w-32  h-44 rounded-lg border-[#1ce5be] hover:shadow-lg hover:shadow-[#1ce5be] flex items-center justify-center flex-col m-3 shadow-[#1ce5be] hover:shadow-lg-[#1ce5be]">
          <i className="text-[#1ce5be] text-5xl md:text-6xl">{props.icon}</i>
          <h3 className="text-[#ececec] uppercase my-3 text-center font-semibold tracking-wide">
            {props.title}
          </h3>
        </div>
      </Link>
    </>
  );
};

export default HomeNavigation;
