import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeilibilars } from "../../../Features/newFleet/fleetSlice";

const DeilibilarSearch = ({ licensePlate, setLicensePlate }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDeilibilars, setFilteredDeilibilars] = useState([]);

  const dispatch = useDispatch();

  const { allDeilibilars } = useSelector((state) => state.fleet);

  useEffect(() => {
    dispatch(getDeilibilars());
  }, [dispatch]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const sortedDeilibilars = allDeilibilars.filter(
      (el) => el.status !== "Disabled"
    );

    const filteredCars = sortedDeilibilars.filter((car) => {
      const licensePlateMatch =
        car.licensePlate.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      const qrCodeMatch =
        car.qrCode.toLowerCase().indexOf(query.toLowerCase()) !== -1;

      return licensePlateMatch || qrCodeMatch;
    });

    setFilteredDeilibilars(filteredCars);
  };

  return (
    <div>
      <div className="flex flex-col my-4 px-5">
        <label className="text-[#ececec] mb-2 text-xl">
          Search by license plate or QR code:
        </label>
        <input
          className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 rounded-md text-lg leading-tight"
          type="text"
          name="searchQuery"
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>

      <div className="flex flex-col my-4 px-5">
        <label className="text-[#ececec] mb-2 text-xl">
          Select deilibilar license plate:
        </label>
        <select
          className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 rounded-md text-lg leading-tight"
          name="licensePlate"
          value={licensePlate}
          onChange={(e) => {
            setLicensePlate(e.target.value);
          }}
        >
          <option>Select</option>
          {filteredDeilibilars.map((car, index) => (
            <option key={index} value={car._id}>
              {car.licensePlate} -- #{car.qrCode}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DeilibilarSearch;
