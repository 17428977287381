import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// Create new accident report
const createAccidentReport = async (accidentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/home/accidentReport`,
    accidentData,
    config
  );

  return response.data;
};

//Get accident reports
const getAccidents = async (token) => {
  //Get token
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_LINK}/api/home/allAccidents`, config);

  return response.data;
};

const accidentService = {
  createAccidentReport,
  getAccidents,
};

export default accidentService;
