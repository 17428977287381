import LineChart from "../../Charts/LineChart/LineChart";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../Components/Spinner/Spinner";
import {
  getShiftAverageData,
  getNightAverageData,
} from "../../Features/Charts/LineChart/lineChartSlice";
import Button from "../../Components/UI/button/Button";
import DateInput from "../../Components/UI/dateInput/DateInput";

const Charts = () => {
  const dispatch = useDispatch();

  // CURRENT DATE - 7
  let prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 7);
  prevDate = prevDate.toISOString().split("T")[0];

  // TODAYS DATE
  let today = new Date();
  today = today.toISOString().split("T")[0];

  const [shifts, setShifts] = useState({
    morning: {
      startDate: `${prevDate}`,
      endDate: `${today}`,
      shiftType: "Morning",
    },
    night: {
      startDate: `${prevDate}`,
      endDate: `${today}`,
      shiftType: "Night",
    },
  });

  const { morningChart, nightChart, isLoading } = useSelector(
    (state) => state.lineChart
  );

  useEffect(() => {
    dispatch(getShiftAverageData(shifts.morning));
    dispatch(getNightAverageData(shifts.night));
  }, [dispatch, shifts.morning, shifts.night]);

  const applyFilter = (e) => {
    e.preventDefault();

    console.log(shifts.morning, shifts.night);
    // dispatch(getShiftAverageData(shifts.morning));
    // dispatch(getNightAverageData(shifts.night));
  };

  const handleShiftStartDateChange = (startDate) => {
    setShifts({
      morning: {
        ...shifts.morning,
        startDate: startDate,
      },
      night: {
        ...shifts.night,
        startDate: startDate,
      },
    });
  };

  const handleShiftEndDateChange = (endDate) => {
    setShifts({
      morning: {
        ...shifts.morning,
        endDate: endDate,
      },
      night: {
        ...shifts.night,
        endDate: endDate,
      },
    });
  };

  return (
    <>
      <section className="flex items-center flex-col md:my-5 justify-center my-2 border-1 px-2 rounded-md border-[#1ce5be]">
        {isLoading ? (
          <Spinner />
        ) : (
          <LineChart morningData={morningChart} nightData={nightChart} />
        )}
        <div className="hidden flex-col md:flex-row justify-center items-center my-2">
          <DateInput
            // value={shifts.morning.startDate}
            onChange={() => handleShiftStartDateChange()}
          />

          <span className="text-[#ff5783] my-3">to</span>
          <DateInput
            // value={shifts.morning.endDate}
            onChange={() => handleShiftEndDateChange()}
          />

          <Button text="Filter" onClick={applyFilter} />
        </div>
        <div className="flex justify-end my-2">
          <Link to="/dashboard/allCharts">
            <Button text="All charts" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default Charts;
