import AllCaptains from "../../Components/Tables/LargeTables/Captains/AllCaptains";

const Captains = () => {
  return (
    <>
      <section className="flex items-center flex-col justify-center text-[#ececec]">
        <div className="text-2xl my-3 text-[#ff5783] uppercase">
          <h3>Captains</h3>
        </div>
        <AllCaptains />
      </section>
    </>
  );
};

export default Captains;
