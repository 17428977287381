import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../Spinner/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { updateCargo, reset } from "../../../Features/newFleet/cargoSlice";

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const ConfirmDisable = ({ setOpenModal, serviceCarId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError, message, isSuccess, isLoading } = useSelector(
    (state) => state.cargo
  );

  useEffect(() => {
    if (isSuccess) {
      successToast("Service car deleted!");
      navigate("/dashboard/allFleet/cargoVehicles/all");
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
    }
  }, [isSuccess, isError, message, navigate, dispatch]);

  const disableCargo = (e) => {
    e.preventDefault();
    dispatch(updateCargo(serviceCarId));
    setOpenModal(false);
    successToast("Service car deleted!", navigate("/dashboard/allFleet"));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div
        id="popup-modal"
        className="fixed top-0 left-0 right-0 md:flex md:justify-center md:items-center z-50 block p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
      >
        <div className="relative w-full h-full max-w-md md:h-auto">
          <div className="relative bg-[#1f2022]  rounded-lg shadow dark:bg-[#1f2022] ">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-hide="popup-modal"
              onClick={() => setOpenModal(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <svg
                aria-hidden="true"
                className="mx-auto mb-4 text-[#ff5783] w-14 h-14 dark:text-[#ff5783]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h1 className="text-2xl  text-[#ececec] uppercase">
                Are you sure you want to delete service car?
              </h1>
              <button
                className="border-1 px-20 py-2 my-3 rounded-full border-[#1ce5be] text-[#ececec] text-xl hover:text-[#ff5783]"
                type="button"
                onClick={(e) => disableCargo(e)}
              >
                Confirm
              </button>
              <button
                onClick={() => setOpenModal(false)}
                className="border-1 px-20 py-2 rounded-full border-[#1ce5be] text-[#ececec] text-xl hover:text-[#ff5783]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmDisable;
