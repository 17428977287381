import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import announcementService from "./announcementService";

const initialState = {
  newAnnouncement: [],
  activeAnnouncements: [],
  disableAnnouncement: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// NEW ANNOUNCEMENT
export const newAnnouncement = createAsyncThunk(
  "dashboard/announcement/newAnnouncement",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await announcementService.createNewAnnouncement(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET ACTIVE ANNOUNCEMENTS
export const getAnnouncements = createAsyncThunk(
  "dashboard/announcement/activeAnnouncement",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await announcementService.getActiveAnnouncements(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// DISABLE ANNOUNCEMENT
export const disableAnnouncement = createAsyncThunk(
  "dashboard/announcement/disable/:id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await announcementService.disableAnnouncement(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newAnnouncement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(newAnnouncement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.newAnnouncement.push(action.payload);
        state.message = action.payload.message;
      })
      .addCase(newAnnouncement.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAnnouncements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAnnouncements.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeAnnouncements = action.payload;
      })
      .addCase(getAnnouncements.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(disableAnnouncement.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(disableAnnouncement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.disableAnnouncement.push(action.payload);
        state.message = action.payload.message;
      })
      .addCase(disableAnnouncement.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = announcementSlice.actions;
export default announcementSlice.reducer;
