const DateAndTime = ({ value }) => {
  return (
    <div>
      <input
        type="datetime-local"
        id="createdAt"
        name="createdAt"
        value={value}
      />
    </div>
  );
};

export default DateAndTime;
