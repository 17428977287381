const DateInput = ({ onChange, value }) => {
  return (
    <>
      <input
        type="date"
        className="mx-3 bg-transparent text-[#1ce5be] border-1 border-[#1ce5be] p-2 rounded-full"
        onChange={onChange}
      />
    </>
  );
};

export default DateInput;
