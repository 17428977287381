import React from "react";

const Pagination = ({
  totalReports,
  rowsPerPage,
  setCurrentPage,
  currentPage,
}) => {
  let pages = [];
  let pageRangeDisplayed = 3;

  // Calculate the total number of pages
  const totalPages = Math.ceil(totalReports / rowsPerPage);

  // Calculate the starting and ending page numbers of the pagination range
  let startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
  let endPage = Math.min(
    totalPages,
    currentPage + Math.floor(pageRangeDisplayed / 2)
  );

  // Adjust the starting and ending page numbers if the range is too large or too small
  if (endPage - startPage + 1 < pageRangeDisplayed) {
    if (currentPage <= Math.floor(pageRangeDisplayed / 2)) {
      endPage = Math.min(totalPages, pageRangeDisplayed);
    } else {
      startPage = Math.max(1, totalPages - pageRangeDisplayed + 1);
    }
  }

  // Generate an array of page numbers for the pagination range
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="flex flex-row items-center my-5 ">
      {startPage > 1 && (
        <button
          className="text-[#ececec] text-lg text-center py-1 rounded-md px-3 border-1 border-[#1ce5be] mx-2"
          onClick={() => setCurrentPage(1)}
        >
          1
        </button>
      )}

      {startPage > 2 && (
        <span className="text-[#ececec] text-lg text-center py-1 mx-2">
          ...
        </span>
      )}

      {pages.map((page, index) => {
        return (
          <button
            key={index}
            className={
              page == currentPage
                ? "text-[#ff5783] font-semibold text-lg text-center py-1 rounded-md px-3 border-1 border-[#ff5783] mx-2"
                : "text-[#ececec] text-lg text-center py-1 rounded-md px-3 border-1 border-[#1ce5be] mx-2"
            }
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </button>
        );
      })}

      {endPage < totalPages - 1 && (
        <span className="text-[#ececec] text-lg text-center py-1 mx-2">
          ...
        </span>
      )}

      {endPage < totalPages && (
        <button
          className="text-[#ececec] text-lg text-center py-1 rounded-md px-3 border-1 border-[#1ce5be] mx-2"
          onClick={() => setCurrentPage(totalPages)}
        >
          {totalPages}
        </button>
      )}
    </div>
  );
};

export default Pagination;
