import React from "react";

const ListItem = ({ label, value }) => (
  <li className="text-[#ececec] text-lg mx-2 my-1">
    {label}:{" "}
    {value ? (
      <span className="text-[#ff5783] text-lg ml-4">{value}</span>
    ) : (
      <span className="text-[#ff5783] font-semibold ml-5">-</span>
    )}
  </li>
);

const ShiftDetails = ({ shiftName, shiftTotal }) => (
  <div className="flex flex-col my-3">
    <div className="flex items-center justify-center">
      <h3 className="text-[#1ce5be] font-semibold text-xl mx-2">
        {shiftName} shift
      </h3>
    </div>
    <ul>
      <ListItem
        label="Batteries replaced"
        value={shiftTotal ? shiftTotal.totalBatteries : null}
      />
      <ListItem
        label="Hopper hikes"
        value={shiftTotal ? shiftTotal.totalHikes : null}
      />
      <ListItem
        label="Fixed on street"
        value={shiftTotal ? shiftTotal.totalFixed : null}
      />
      <ListItem
        label="Scooters with fake issues"
        value={shiftTotal ? shiftTotal.totalFake : null}
      />
      <ListItem
        label="Left on MTC"
        value={shiftTotal ? shiftTotal.totalLeftMtc : null}
      />
      <ListItem
        label="Picked for MTC"
        value={shiftTotal ? shiftTotal.totalPickedMtc : null}
      />
      <ListItem
        label="Rebalanced scooters"
        value={shiftTotal ? shiftTotal.totalRebalance : null}
      />
      <ListItem
        label="Winter service"
        value={shiftTotal ? shiftTotal.totalWinterService : null}
      />
    </ul>
  </div>
);

const DataCard = ({
  startDate,
  endDate,
  batteries,
  hikes,
  rebalance,
  fixed,
  pickedMtc,
  leftMtc,
  hours,
  minutes,
  morning,
  evening,
  night,
  total,
  morningTotal,
  eveningTotal,
  nightTotal,
}) => {
  return (
    <>
      <div className="border-1 rounded-md border-[#1ce5be] w-80 mx-3">
        <div className="flex items-center justify-center flex-row">
          <h3 className="text-[#ececec] font-semibold text-xl mx-2">
            {startDate}
          </h3>
          {startDate && endDate && (
            <span className="text-[#ff5783] font-semibold">-</span>
          )}
          <h3 className="text-[#ececec] font-semibold text-xl mx-2">
            {endDate}
          </h3>
        </div>

        <div className="flex items-center my-3">
          <ul>
            <ListItem label="Batteries replaced" value={batteries} />
            <ListItem label="Hopper hikes" value={hikes} />
            <ListItem label="Rebalanced scooters" value={rebalance} />
            <ListItem label="Fixed on street" value={fixed} />
            <ListItem label="Picked for MTC" value={pickedMtc} />
            <ListItem label="Left on MTC" value={leftMtc} />
            <ListItem
              label="Deilibilar service"
              value={`${hours} H ${minutes} MIN`}
            />
          </ul>
        </div>

        <div className="flex flex-col my-3">
          <div className="flex items-center justify-center">
            <h3 className="text-[#1ce5be] font-semibold text-xl mx-2">
              Workforce
            </h3>
          </div>
          <ul className="my-3">
            <ListItem label="Morning shift hours" value={morning} />
            <ListItem label="Evening shift hours" value={evening} />
            <ListItem label="Night shift hours" value={night} />
            <li className="text-[#ececec] text-lg mx-2 my-1">
              Total:{" "}
              <span className="text-[#ff5783] text-lg ml-4">{total}</span>
            </li>
          </ul>
        </div>

        <ShiftDetails shiftName="Morning" shiftTotal={morningTotal} />
        <ShiftDetails shiftName="Evening" shiftTotal={eveningTotal} />
        <ShiftDetails shiftName="Night" shiftTotal={nightTotal} />
      </div>
    </>
  );
};

export default DataCard;
