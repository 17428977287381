import { AiOutlineClose } from "react-icons/ai";

const UploadImage = ({
  label,
  type,
  id,
  name,
  multiple,
  images,
  setImages,
}) => {
  const handleImages = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImages((prevState) => [...prevState, reader.result]);
      };
    });
  };

  const handleRemoveImage = (e, index) => {
    e.preventDefault();
    const newItems = [...images];
    newItems.splice(index, 1);
    setImages(newItems);
  };

  return (
    <>
      <label className="text-[#ececec] mb-2 text-xl">{label}:</label>

      <div className="my-2">
        <label>
          <input
            type={type}
            id={id}
            className="text-sm text-[#ececec]
      file:mr-5 file:py-2 file:px-6
      file:rounded-full file:border-1
      file:border-[#1ce5be]
      file:text-sm file:font-medium
      file:bg-transparent file:text-[#1ce5be]
      hover:file:cursor-pointer"
            name={name}
            multiple={multiple}
            onChange={handleImages}
          />
        </label>
      </div>
      {images.length > 0 && (
        <div className="flex flex-wrap">
          {images.map((image, index) => (
            <div key={index} className="relative">
              <img
                className="md:w-32 w-48 mx-1 pt-3"
                alt="daily-car"
                src={image}
                key={index}
              />
              <button
                className="absolute top-0 right-0 bg-[#ff5783] text-[#ececec] rounded-full p-2"
                onClick={(e) => {
                  handleRemoveImage(e, index);
                }}
              >
                <AiOutlineClose className="text-lg font-semibold" />
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default UploadImage;
