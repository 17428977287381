const ImageModal = ({ setImageModal, imageLink }) => {
  return (
    <>
      <div className="w-screen h-screen fixed top-0 left-0 right-0 md:flex md:justify-center md:items-center z-50 block p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
        <div className="flex items-center justify-center w-full h-full">
          <img
            alt="Deilibilar issue"
            className="relative md:w-2/5 w-9/12"
            src={imageLink}
            onClick={() => setImageModal(false)}
          />
        </div>
      </div>
    </>
  );
};

export default ImageModal;
