import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;
// GET OP vehicle status
const getVehicleStatus = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_LINK}/api/home/status`, config);

  return response.data;
};

const opDataService = {
  getVehicleStatus,
};

export default opDataService;
