import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFixerReport } from "../../../Features/fixerReport/fixerReportSlice";
import Spinner from "../../Spinner/Spinner";

const FixerReportCard = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { fixerReport, isLoading } = useSelector((state) => state.fixerReport);

  useEffect(() => {
    dispatch(getFixerReport(id));
  }, [dispatch, id]);

  return (
    <>
      <section className="flex items-center flex-col justify-center text-[#ececec]">
        <div className="text-2xl my-3 text-[#ff5783] uppercase mt-5 font-semibold">
          <h3>Fixer report</h3>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <section className="flex my-3 py-3 border-1 rounded-md border-[#1ce5be] lg:w-96 mx-2">
            <div className="flex items-center justify-center mx-3">
              <h1 className="text-[#ececec] font-semibold underline decoration-[#ff5783]">
                {fixerReport &&
                  fixerReport.userCreated &&
                  fixerReport.userCreated.firstName}
              </h1>
            </div>
            <div className="mx-3 flex flex-col">
              <span className="text-[#ececec] my-2">Area assigned:</span>
              <ul className="list-disc mx-4">
                {fixerReport.areaAssigned?.map((area, index) => (
                  <li className="text-[#ff5783]" key={index}>
                    {area.label}
                  </li>
                ))}
              </ul>
              <span className="text-[#ececec] my-2">Cargo:</span>
              <ul className="list-disc mx-4">
                {fixerReport.serviceCar?.map((cargo, index) => (
                  <li className="text-[#ff5783]" key={index}>
                    {cargo.label}
                  </li>
                ))}
              </ul>
              <ul className="list-none">
                <li className="text-[#ececec] my-2">
                  Batteries changed:{" "}
                  <span className="text-[#ff5783]">
                    {fixerReport.batteries}
                  </span>
                </li>
                <li className="text-[#ececec] my-2">
                  Hopper hikes:{" "}
                  <span className="text-[#ff5783]">{fixerReport.hikes}</span>
                </li>
                <li className="text-[#ececec] my-2">
                  Scooters fixed on street:{" "}
                  <span className="text-[#ff5783]">{fixerReport.fixed}</span>
                </li>
                <li className="text-[#ececec] my-2">
                  Scooters left on street (MTC):{" "}
                  <span className="text-[#ff5783]">{fixerReport.leftMtc}</span>
                </li>
                <li className="text-[#ececec] my-2">
                  Scooters picked up for maintenance:{" "}
                  <span className="text-[#ff5783]">
                    {fixerReport.pickedMtc}
                  </span>
                </li>
                <li className="text-[#ececec] my-2">
                  Rebalanced scooters:{" "}
                  <span className="text-[#ff5783]">
                    {fixerReport.rebalance}
                  </span>
                </li>
                <li className="text-[#ececec] my-2">
                  Fake issues:{" "}
                  <span className="text-[#ff5783]">{fixerReport.fake}</span>
                </li>
                <li className="text-[#ececec] my-2">
                  Winter service damage:{" "}
                  <span className="text-[#ff5783]">
                    {fixerReport.winterService}
                  </span>
                </li>
                <li className="text-[#ececec] my-2">
                  Comment:{" "}
                  <span className="text-[#ff5783]">{fixerReport.comment}</span>
                </li>
              </ul>
            </div>
          </section>
        )}
      </section>
    </>
  );
};

export default FixerReportCard;
