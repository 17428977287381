import { useState } from "react";
import Moment from "react-moment";
import { FaWrench } from "react-icons/fa";
import { MdHideImage, MdImage } from "react-icons/md";
import { useSelector } from "react-redux";

import ConfirmModal from "./ConfirmModal";

const IssueTable = ({ allIssues, id }) => {
  const { user } = useSelector((state) => state.auth);

  const [issueId, setIssueId] = useState("");
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {allIssues.length > 0 ? (
        <table>
          <tbody>
            <tr className="text-center ">
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Issue type:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                Description:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                Image:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                Date created:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3  mx-2 text-center pb-2">
                Created by:
              </th>
            </tr>
            {allIssues.map((issue, index) => {
              return (
                <tr key={index} className="border-b border-[#1ce5be]">
                  <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                    {issue.issueType}
                  </td>
                  <td className="pr-3 py-3 text-sm text-center w-20 h-20 text-[#ececec]">
                    {issue.description}
                  </td>
                  <td className="pr-3 py-3 text-center text-[#ececec] ">
                    {issue.image.length > 0 ? (
                      <MdImage
                        className="text-xl text-center ml-2 text-[#ececec]"
                        onClick={() =>
                          window.open(issue.image[0].url, "_blank")
                        }
                      />
                    ) : (
                      <MdHideImage className="text-xl text-center ml-2 text-[#ececec]" />
                    )}
                  </td>
                  <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                    <Moment format="DD-MM-YYYY">{issue.createdAt}</Moment>
                  </td>
                  <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                    {issue.userCreated?.firstName}
                  </td>
                  {user.role === "Admin" && (
                    <td className="pr-3 py-3 text-center text-[#ececec]">
                      <button onClick={() => setOpenModal(true)}>
                        <FaWrench
                          className="text-xl text-[#1ce5be]"
                          onClick={() => setIssueId(issue._id)}
                        />
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}

      {openModal && (
        <ConfirmModal setOpenModal={setOpenModal} issueId={issueId} id={id} />
      )}
    </>
  );
};

export default IssueTable;
