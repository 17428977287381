import { useEffect } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { getShiftReportById } from "../../../Features/shiftReport/shiftReportSlice";
import Spinner from "../../Spinner/Spinner";
import ShiftBar from "./ShiftBar";
import ShiftReportCard from "./ShiftReportCard";
import FleetChart from "./FleetChart";
import Warning from "../../Warning/Warning";

const ShiftReport = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { singleShiftReport, isLoading } = useSelector(
    (state) => state.shiftReport
  );

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getShiftReportById(id));
  }, [dispatch, id]);

  return (
    <>
      <div className="">
        <Warning message="We are currently experiencing issues with the display of areas names and are working diligently to resolve this matter. Thank you for your patience." />
      </div>

      <div>
        <section className="flex items-center flex-col justify-center text-[#ececec]">
          <div className="text-2xl my-3 text-[#ff5783] uppercase mt-5 font-semibold">
            <h3>Shift report</h3>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <section className="flex items-center justify-center my-3 mx-3 py-5 px-2 border-1 rounded-md border-[#1ce5be]">
              <div className="flex items-center justify-center mx-3">
                <h1 className="text-[#ececec]">OP DATA</h1>
              </div>
              <div className="border-l-2 border-[#1ce5be] pl-5 flex flex-col">
                <ul className="list-none">
                  <FleetChart
                    activeScooters={singleShiftReport.activeScooters}
                    maintenanceScooters={singleShiftReport.maintenanceScooters}
                    disabledScooters={singleShiftReport.disabledScooters}
                  />
                  <li className="text-[#ececec] my-2">
                    Shift captain:{" "}
                    <span className="text-[#ff5783]">
                      {singleShiftReport.userCreated?.firstName}{" "}
                      {singleShiftReport.userCreated?.lastName}
                    </span>
                  </li>
                  <li className="text-[#ececec] my-2">
                    Shift type:{" "}
                    <span className="text-[#ff5783]">
                      {singleShiftReport.shiftType}
                    </span>
                  </li>

                  <li className="text-[#ececec] my-2">
                    Report created at:{" "}
                    <Moment
                      className="text-[#ff5783] mb-3"
                      format="DD-MM-YYYY HH:mm"
                    >
                      {singleShiftReport.createdAt}
                    </Moment>
                  </li>
                </ul>
              </div>
            </section>
          )}

          <div className="text-2xl my-3 text-[#ff5783] uppercase mt-5 font-semibold">
            <h3>hopper Reports</h3>
          </div>

          <section className="flex items-center justify-center">
            <div className="flex items-center justify-center flex-wrap md:w-1/2">
              {isLoading ? (
                <Spinner />
              ) : (
                singleShiftReport.reports?.map((report, index) => (
                  <ShiftReportCard report={report} key={index} />
                ))
              )}
            </div>
          </section>

          {singleShiftReport.firstSc?.length > 0 ||
          singleShiftReport.secondSc?.length > 0 ? (
            <div className="text-xl my-3 text-[#ff5783] uppercase mt-5">
              <h3>Screenshots</h3>
            </div>
          ) : null}

          <div className="flex flex-col my-5">
            {singleShiftReport.firstSc?.length > 0
              ? singleShiftReport.firstSc?.map((screenshot, index) => (
                  <Link to={screenshot.url} target="_blank">
                    <img
                      key={index}
                      className="m-2 hover:cursor-zoom-in max-w-sm md:max-w-lg"
                      src={screenshot.url}
                      alt="Map screenshot at start"
                    />
                  </Link>
                ))
              : null}

            {singleShiftReport.secondSc?.length > 0
              ? singleShiftReport.secondSc?.map((screenshot, index) => (
                  <Link to={screenshot.url} target="_blank">
                    <img
                      key={index}
                      className="m-2 hover:cursor-zoom-in max-w-sm md:max-w-lg"
                      src={screenshot.url}
                      alt="Map screenshot at the end"
                    />
                  </Link>
                ))
              : null}
          </div>
        </section>
        <div className="mt-5 mb-15">
          {user.role === "Admin" && (
            <div>
              <div className="flex items-center justify-center">
                <Link to={`/dashboard/shiftReports/update/${id}`}>
                  <button
                    className="border-1 px-20 py-2 my-3 rounded-full border-[#1ce5be] text-[#ececec] text-xl hover:text-[#ff5783]"
                    type="button"
                  >
                    Edit
                  </button>
                </Link>
              </div>

              <div className="items-center justify-center">
                <div className="flex items-center justify-center">
                  <h1 className="text-xl my-3 text-[#ff5783] uppercase mt-5">
                    Visualizing Shift Report Data
                  </h1>
                </div>
              </div>
              <div>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <ShiftBar reportData={singleShiftReport.reports} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShiftReport;
