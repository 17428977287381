import { BsSunriseFill, BsFillSunFill, BsMoonStarsFill } from "react-icons/bs";

const ShiftType = ({ shiftType }) => {
  if (shiftType === "Morning") {
    return <BsSunriseFill className="text-xl  text-[#ececec] " />;
  } else if (shiftType === "Evening") {
    return <BsFillSunFill className="text-xl  text-[#ececec] " />;
  } else if (shiftType === "Night") {
    return <BsMoonStarsFill className="text-xl  text-[#ececec]" />;
  } else {
    return null;
  }
};

export default ShiftType;
