import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewCargoIssue,
  getCargoIssuesById,
  reset,
} from "../../../Features/cargoIssues/cargoIssueSlice";
import { getCargoProfile } from "../../../Features/newFleet/cargoSlice";
import Spinner from "../../Spinner/Spinner";

const notify = (message) =>
  toast.warn(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const NewIssueModal = () => {
  const { id } = useParams();

  const [issueType, setIssueType] = useState("");
  const [description, setDescription] = useState("");
  const [cargoId, setCargoId] = useState(id);
  const [image, setImages] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //    SERVICE CAR INFO
  const { cargoInfo } = useSelector((state) => state.cargo);

  //    NEW ISSUE REDUX
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.cargoIssues
  );

  useEffect(() => {
    dispatch(getCargoIssuesById(id));
    dispatch(getCargoProfile(id));
  }, [dispatch, id]);

  const licensePlate = cargoInfo.licensePlate;
  const model = cargoInfo.model;

  const handleImages = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImages((prevState) => [...prevState, reader.result]);
      };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const issueData = {
      issueType,
      description,
      image,
      cargoId,
      licensePlate,
      model,
    };

    if (issueType === "" || description === "") {
      return notify("Please fill required fields !");
    } else {
      dispatch(createNewCargoIssue(issueData));
    }
  };

  useEffect(() => {
    const handleSuccess = () => {
      successToast("New issue created!");
      navigate("/home/serviceCars");
      dispatch(reset());
    };

    const handleError = () => {
      errToast(message);
    };
    if (isSuccess) {
      handleSuccess();
    }
    if (isError) {
      handleError();
    }
  }, [isSuccess, isError, message, navigate, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div id="popup-modal" className="flex items-center justify-center mt-10">
        <div className="relative w-full h-full max-w-md md:h-auto">
          <div className="border-1 rounded-md border-[#ff5783] py-2 px-4 relative bg-[#1f2022] shadow dark:bg-[#1f2022] ">
            <div className="p-6 text-center">
              <svg
                aria-hidden="true"
                className="mx-auto mb-4 text-[#ff5783] w-14 h-14 dark:text-[#ff5783]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h1 className="text-2xl  text-[#ececec] uppercase">
                Add an issue
              </h1>
              {/* Form */}
              <form onSubmit={onSubmit}>
                <div className="flex flex-col my-4 px-5">
                  <label className="text-[#ececec] mb-2 text-xl">
                    <span className="text-red-500 mx-1">*</span>Issue type:
                  </label>
                  <select
                    className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 rounded-md text-lg leading-tight"
                    name="issueType"
                    value={issueType}
                    onChange={(e) => {
                      setIssueType(e.target.value);
                    }}
                  >
                    <option>Select</option>
                    <option value="Body">Body</option>
                    <option value="Interior">Interior</option>
                    <option value="Tires/Rims">Tires/Rims</option>
                    <option value="Fault codes">Fault code</option>
                  </select>
                </div>
                <div className="flex flex-col my-4 px-5">
                  <label className="text-[#ececec] mb-2 text-xl">
                    <span className="text-red-500 mx-1">*</span>Short
                    description:
                  </label>
                  <input
                    className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                    type="text"
                    maxLength={50}
                    placeholder="Max 50 characters"
                    name="description"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>
                <div className="my-2 mx-3">
                  <label>
                    <input
                      onChange={handleImages}
                      type="file"
                      id="file-upload"
                      className="text-sm text-[#ececec]
              file:mr-5 file:py-2 file:px-6
              file:rounded-full file:border-1
             file:border-[#1ce5be]
              file:text-sm file:font-medium
              file:bg-transparent file:text-[#1ce5be]
              hover:file:cursor-pointer"
                      name="image"
                      multiple="multiple"
                    />
                  </label>
                  {image.length > 0 && (
                    <div className="flex flex-wrap items-center justify-center">
                      {image.map((image, index) => (
                        <img
                          className="md:w-32 w-12 mx-1 pt-3"
                          alt="daily-car"
                          src={image}
                          key={index}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <button
                  className="border-1 px-20 py-2 my-3 rounded-full border-[#1ce5be] text-[#ececec] text-xl hover:text-[#ff5783]"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default NewIssueModal;
