import { BsFillChatLeftTextFill } from "react-icons/bs";

const MessageModal = ({ message, setOpenModal, setMessage }) => {
  return (
    <div
      id="popup-modal"
      className=" fixed top-0 left-0 right-0 md:flex md:justify-center md:items-center z-50 block p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div className="relative bg-[#1f2022] border-1 rounded-md border-[#ff5783] py-2 px-4  shadow dark:bg-[#1f2022] ">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={() => setOpenModal(false)}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 text-center">
            <div className="flex items-center justify-center my-3">
              <BsFillChatLeftTextFill className="text-3xl text-[#ececec]" />
            </div>
            <p className="text-lg  text-[#ececec]">{message}</p>

            <button
              onClick={() => setOpenModal(false)}
              className="border-1 px-20 py-2 rounded-full border-[#1ce5be] text-[#ececec] text-xl hover:text-[#ff5783] my-3"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
