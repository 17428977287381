import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { useParams, Link, useNavigate } from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import {
  getDeilibilarProfile,
  updateDeilibilarInfo,
  reset,
} from "../../../Features/newFleet/fleetSlice";
import Spinner from "../../Spinner/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const successToast = (message) =>
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const EditDeilibilar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [updatedDeilibilar, setUpdatedDeilibilar] = useState();

  const { fleetInfo, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.fleet
  );

  useEffect(() => {
    dispatch(getDeilibilarProfile(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (fleetInfo) {
      setUpdatedDeilibilar(fleetInfo);
    }
  }, [fleetInfo, setUpdatedDeilibilar]);

  useEffect(() => {
    if (isSuccess) {
      successToast("Deilibilar info updated!");
      navigate(`/dashboard/allFleet/deilibilar/${id}`);
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
    }
  }, [isSuccess, isError, message, navigate, dispatch, id]);

  const saveDeilibilarInfo = (e) => {
    e.preventDefault();

    dispatch(updateDeilibilarInfo(updatedDeilibilar));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <main className="flex md:justify-center md:items-center flex-col md:flex-col mt-3">
          <section className="flex md:flex-row flex-col">
            <section className="flex flex-col items-center md:mr-5">
              <div className="my-2">
                <label className="text-[#ff5783] text-lg mt-4 font-semibold">
                  Brand:
                </label>
                <input
                  className="bg-transparent border-1 mx-2 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                  type="text"
                  name="brand"
                  value={updatedDeilibilar?.brand}
                  onChange={(e) => {
                    setUpdatedDeilibilar({
                      ...updatedDeilibilar,
                      brand: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="my-2">
                <label className="text-[#ff5783] text-lg mt-4 font-semibold">
                  Model:
                </label>
                <input
                  className="bg-transparent border-1 mx-2 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                  type="text"
                  name="model"
                  value={updatedDeilibilar?.model}
                  onChange={(e) => {
                    setUpdatedDeilibilar({
                      ...updatedDeilibilar,
                      model: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="my-2">
                <span className="text-[#ff5783] text-lg mt-4 font-semibold">
                  License plate:
                </span>
                <input
                  className="bg-transparent border-1 mx-2 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                  type="text"
                  name="model"
                  value={updatedDeilibilar?.licensePlate}
                  onChange={(e) => {
                    setUpdatedDeilibilar({
                      ...updatedDeilibilar,
                      licensePlate: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="my-2">
                <span className="text-[#ff5783] text-lg mt-4 font-semibold">
                  QR code:
                </span>
                <input
                  className="bg-transparent border-1 mx-2 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                  type="text"
                  name="model"
                  value={updatedDeilibilar?.qrCode}
                  onChange={(e) => {
                    setUpdatedDeilibilar({
                      ...updatedDeilibilar,
                      qrCode: e.target.value,
                    });
                  }}
                />
              </div>
            </section>
            <section className="flex flex-col md:items-start items-center justify-center mt-4">
              <div className="flex md:flex-col">
                <span className="text-[#ff5783] text-lg mr-2 font-semibold">
                  Created at:
                </span>
                <Moment
                  className="text-[#ececec] mb-3"
                  format="DD-MM-YYYY HH:mm"
                >
                  {fleetInfo.createdAt}
                </Moment>
              </div>

              <div className="flex md:flex-col">
                <span className="text-[#ff5783] text-lg font-semibold mr-2">
                  Mileage:
                </span>
                <span className="text-[#ececec] mb-3">
                  {fleetInfo.mileage} km
                </span>
              </div>

              <div className="flex md:flex-col">
                <span className="text-[#ff5783] text-lg mr-2 font-semibold">
                  User created:
                </span>
                <span className="text-[#ececec] mb-3">
                  {fleetInfo.userCreated?.firstName}{" "}
                  {fleetInfo.userCreated?.lastName}
                </span>
              </div>

              {fleetInfo.generalCheck?.checklist.length > 0 && (
                <div className="flex md:flex-col">
                  <span className="text-[#ff5783] text-lg font-semibold mr-2 mb-2">
                    Comes with:
                  </span>
                  <div className="flex flex-col mb-3">
                    <ul className="text-[#ececec]">
                      {fleetInfo.generalCheck?.checklist
                        ? fleetInfo.generalCheck?.checklist?.map(
                            (check, index) => (
                              <li
                                key={index}
                                className="flex mb-1 text-lg items-center"
                              >
                                <HiPlus className="text-[#1ce5be] text-lg mr-2 mt-1" />
                                {check}
                              </li>
                            )
                          )
                        : null}
                    </ul>
                  </div>
                </div>
              )}
            </section>
          </section>

          <section className="my-3">
            <button
              className="border-1 px-10 py-2  mx-2 rounded-full border-[#1ce5be] text-[#ececec] text-sm hover:text-[#ff5783] cursor-not-allowed"
              onClick={saveDeilibilarInfo}
            >
              Save
            </button>
            <Link to={`/dashboard/allFleet/deilibilar/${id}`}>
              <button className="border-1 px-10 py-2  rounded-full border-[#ff5783] text-[#ff5783] text-sm">
                Cancel
              </button>
            </Link>
          </section>
        </main>
      )}
    </>
  );
};

export default EditDeilibilar;
