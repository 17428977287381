import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const notify = (message) =>
  toast.warn(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const hoppLogo = require("../../Assets/Images/logo.jpg");

const CaptainPromote = () => {
  const [validUrl, setValidUrl] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const url = `${process.env.REACT_APP_API_LINK}/api/promote/${params.id}/${params.token}`;

  useEffect(() => {
    const verifyUrl = async () => {
      try {
        await axios.get(url);
        setValidUrl(true);
      } catch (error) {
        setValidUrl(false);
      } finally {
        setLoading(false);
      }
    };
    verifyUrl();
  }, [params, url]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        errToast("Passwords don't match!");
      } else {
        const { data } = await axios.post(url, { password });
        setMsg(data.message);
        setError("");
        window.location = "/";
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
        notify(error.response.data.message);
        console.log(error.response.data.message);
        setMsg("");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : validUrl ? (
        <div className="flex items-center justify-center flex-col h-screen">
          <h4 className="text-2xl py-3 text-[#ff5783]">
            Welcome to Hopp Manager
          </h4>
          <div className="my-3">
            <img src={hoppLogo} className="h-16 rounded-md" alt="Hopp Logo" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="w-80 flex flex-col my-3">
              <label className="text-[#ececec] mb-2 text-lg">Password:</label>
              <input
                className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                type="password"
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="w-80 flex flex-col my-3">
              <label className="text-[#ececec] mb-2 text-lg">
                Confirm password:
              </label>
              <input
                className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                type="password"
                id="confirmPassword"
                name="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-center my-4">
              <button
                type="submit"
                className="border-1 px-5 py-2 rounded-full border-[#1ce5be] text-[#ececec] text-2xl font-medium hover:text-[#ff5783]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <span className="text-[#ececec]">404 Not Found</span>
      )}
      <ToastContainer />
    </>
  );
};

export default CaptainPromote;
