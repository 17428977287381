import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import {
  getShiftReportById,
  updateReport,
  reset,
} from "../../../Features/shiftReport/shiftReportSlice";
import Spinner from "../../Spinner/Spinner";
import { MdDelete } from "react-icons/md";
import { getCaptains, getHoppers } from "../../../Features/team/teamSlice";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const successToast = (message) =>
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const UpdateShiftReport = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [updatedReport, setUpdatedReport] = useState();

  const { singleShiftReport, isLoading, isError, message, isSuccess } =
    useSelector((state) => state.shiftReport);

  const { captain, hopper } = useSelector((state) => state.team);

  useEffect(() => {
    dispatch(getShiftReportById(id));
    dispatch(getCaptains());
    dispatch(getHoppers());

    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }
    if (isError) {
      errToast(message);
    }
  }, [dispatch, id, isSuccess, isError, message]);

  let captainList =
    captain.length > 0 &&
    captain.map((cpt, index) => {
      return (
        <option key={index} value={cpt.firstName}>
          {cpt.firstName}
        </option>
      );
    });

  let hopperList =
    hopper.length > 0 &&
    hopper.map((hopp, index) => {
      return (
        <option key={index} value={hopp.firstName}>
          {hopp.firstName}
        </option>
      );
    });

  useEffect(() => {
    if (singleShiftReport) {
      setUpdatedReport(singleShiftReport);
    }
  }, [singleShiftReport, setUpdatedReport]);

  const deleteHandler = (index) => {
    const newList =
      updatedReport.reports &&
      updatedReport.reports.filter((element, i) => i !== index);
    setUpdatedReport({
      ...updatedReport,
      reports: newList,
      employeeCount: updatedReport.reports.length - 1,
    });
  };

  const editHandler = (index, e) => {
    const { name, value } = e.target;
    let info = [...updatedReport.reports];
    console.log(info);
    info[index] = { ...info[index], [name]: value };

    setUpdatedReport({
      ...updatedReport,
      reports: info,
    });
  };

  const saveReport = (e) => {
    e.preventDefault();

    dispatch(updateReport(updatedReport));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <section className="flex items-center flex-col  justify-center text-[#ececec]">
            <div className="text-2xl my-3 text-[#ff5783] uppercase">
              <h3>Shift report</h3>
            </div>

            {isLoading ? (
              <Spinner />
            ) : (
              <section className="flex items-center justify-center w-full flex-col md:flex-row md:max-w-md mt-5">
                <div className="flex items-center justify-center my-5 mx-3">
                  <h1 className="text-[#ececec]">OP DATA</h1>
                </div>
                <div className="border-l-2 border-[#1ce5be] pl-5 flex flex-col">
                  <ul className="list-none">
                    <li className="text-[#ececec] my-2 ">
                      Shift captain:{" "}
                      <span className="text-[#ff5783]">
                        {singleShiftReport.userCreated?.firstName}{" "}
                        {singleShiftReport.userCreated?.lastName}
                      </span>
                    </li>
                    <li className="text-[#ececec] my-2 flex flex-row justify-center items-center">
                      <label className="text-[#ececec] mb-2 text-lg">
                        Shift type:
                      </label>

                      <select
                        className="bg-[#1f2022] mx-2 text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-5 rounded-md text-lg leading-tight"
                        name="shiftType"
                        value={updatedReport?.shiftType}
                        onChange={(e) => {
                          setUpdatedReport({
                            ...updatedReport,
                            shiftType: e.target.value,
                          });
                        }}
                      >
                        <option value="">{singleShiftReport.shiftType}</option>
                        <option value="Morning">Morning</option>
                        <option value="Evening">Evening</option>
                        <option value="Night">Night</option>
                      </select>
                    </li>
                    <li className="text-[#ececec] my-2 flex flex-row items-center">
                      <label className="text-[#ececec] mb-2 text-lg">
                        Active scooters:
                      </label>
                      <input
                        className="bg-transparent border-1 mx-2 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                        type="number"
                        min="0"
                        max="10000"
                        onWheel={(e) => e.target.blur()}
                        name="secondInput"
                        value={updatedReport?.activeScooters}
                        onChange={(e) => {
                          setUpdatedReport({
                            ...updatedReport,
                            activeScooters: e.target.value,
                          });
                        }}
                      />
                    </li>
                    <li className="text-[#ececec] my-2">
                      <label className="text-[#ececec] mb-2 text-lg">
                        Disabled scooters:
                      </label>
                      <input
                        className="bg-transparent border-1 mx-2 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                        type="number"
                        min="0"
                        max="10000"
                        onWheel={(e) => e.target.blur()}
                        name="secondInput"
                        value={updatedReport?.disabledScooters}
                        onChange={(e) => {
                          setUpdatedReport({
                            ...updatedReport,
                            disabledScooters: e.target.value,
                          });
                        }}
                      />
                    </li>
                    <li className="text-[#ececec] my-2">
                      <label className="text-[#ececec] mb-2 text-lg">
                        Maintenance scooters:
                      </label>
                      <input
                        className="bg-transparent border-1 mx-2 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                        type="number"
                        min="0"
                        max="10000"
                        onWheel={(e) => e.target.blur()}
                        name="secondInput"
                        value={updatedReport?.maintenanceScooters}
                        onChange={(e) => {
                          setUpdatedReport({
                            ...updatedReport,
                            maintenanceScooters: e.target.value,
                          });
                        }}
                      />
                    </li>
                    <li className="text-[#ececec] my-2">
                      Report created at:{" "}
                      <Moment
                        className="text-[#ff5783] mb-3"
                        format="DD-MM-YYYY HH:mm"
                      >
                        {singleShiftReport.createdAt}
                      </Moment>
                    </li>
                  </ul>
                </div>
              </section>
            )}

            <div className="text-xl my-3 text-[#ff5783] uppercase mt-5">
              <h3>Reports</h3>
            </div>

            {isLoading ? (
              <Spinner />
            ) : (
              updatedReport?.reports?.map((report, index) => (
                <section
                  key={index}
                  className="flex items-center justify-center flex-col md:flex-row my-5 w-full md:max-w-lg"
                >
                  <div className="flex items-center justify-center my-5 mx-3">
                    <select
                      className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 rounded-md text-lg leading-tight"
                      name="hopper"
                      value={report.hopper}
                      onChange={(e) => {
                        editHandler(index, e);
                      }}
                    >
                      <option value="" disabled className="text-[#ececec]">
                        -- Captains --
                      </option>
                      {captainList}
                      <option value="" disabled className="text-[#ececec]">
                        -- Hoppers --
                      </option>
                      {hopperList}
                    </select>
                  </div>
                  <div className="border-l-2 border-[#1ce5be] pl-5 mx-3 flex flex-col">
                    <span className="text-[#ececec] my-2">Area assigned:</span>
                    <ul className="list-disc mx-4">
                      {report.district?.map((area, index) => (
                        <li className="text-[#ff5783]" key={index}>
                          {area.label}
                        </li>
                      ))}
                    </ul>
                    <span className="text-[#ececec] my-2">Cargo:</span>
                    <ul className="list-disc mx-4">
                      {report.cargos?.map((cargo, index) => (
                        <li className="text-[#ff5783]" key={index}>
                          {cargo.label}
                          <br />
                          {cargo.value}
                        </li>
                      ))}
                    </ul>
                    <ul className="list-none">
                      <li className="text-[#ececec] my-2">
                        Batteries changed:{" "}
                        <input
                          className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                          type="number"
                          placeholder="11"
                          min="0"
                          max="1000"
                          onWheel={(e) => e.target.blur()}
                          name="batteries"
                          value={report.batteries}
                          onChange={(e) => {
                            editHandler(index, e);
                          }}
                        />
                      </li>
                      <li className="text-[#ececec] my-2">
                        Hopper hikes:{" "}
                        <input
                          className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                          type="number"
                          placeholder="11"
                          min="0"
                          max="1000"
                          onWheel={(e) => e.target.blur()}
                          name="hikes"
                          value={report.hikes}
                          onChange={(e) => {
                            editHandler(index, e);
                          }}
                        />
                      </li>
                      <li className="text-[#ececec] my-2">
                        Scooters fixed on street:{" "}
                        <input
                          className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                          type="number"
                          placeholder="11"
                          min="0"
                          max="1000"
                          onWheel={(e) => e.target.blur()}
                          name="fixed"
                          value={report.fixed}
                          onChange={(e) => {
                            editHandler(index, e);
                          }}
                        />
                      </li>
                      <li className="text-[#ececec] my-2">
                        Scooters left on street (MTC):{" "}
                        <input
                          className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                          type="number"
                          placeholder="11"
                          min="0"
                          max="1000"
                          onWheel={(e) => e.target.blur()}
                          name="leftMtc"
                          value={report.leftMtc}
                          onChange={(e) => {
                            editHandler(index, e);
                          }}
                        />
                      </li>
                      <li className="text-[#ececec] my-2">
                        Scooters picked up for maintenance:{" "}
                        <input
                          className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                          type="number"
                          placeholder="11"
                          min="0"
                          max="1000"
                          onWheel={(e) => e.target.blur()}
                          name="pickedMtc"
                          value={report.pickedMtc}
                          onChange={(e) => {
                            editHandler(index, e);
                          }}
                        />
                      </li>
                      <li className="text-[#ececec] my-2">
                        Rebalanced scooters:{" "}
                        <input
                          className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                          type="number"
                          placeholder="11"
                          min="0"
                          max="1000"
                          onWheel={(e) => e.target.blur()}
                          name="rebalance"
                          value={report.rebalance}
                          onChange={(e) => {
                            editHandler(index, e);
                          }}
                        />
                      </li>
                      <li className="text-[#ececec] my-2">
                        Comment:{" "}
                        <input
                          className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
                          type="text"
                          onWheel={(e) => e.target.blur()}
                          name="comment"
                          value={report.comment}
                          onChange={(e) => {
                            editHandler(index, e);
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                  <button className="border-1 rounded-md px-3 py-2 my-2 text-[#ececec] text-2xl hover:text-[#ff5783]">
                    <MdDelete onClick={() => deleteHandler(index)} />
                  </button>
                </section>
              ))
            )}

            {singleShiftReport.firstSc?.length > 0 ||
            singleShiftReport.secondSc?.length > 0 ? (
              <div className="text-xl my-3 text-[#ff5783] uppercase mt-5">
                <h3>Screenshots</h3>
              </div>
            ) : null}

            <div className="flex md:flex-row flex-col my-5">
              {singleShiftReport.firstSc?.length > 0
                ? singleShiftReport.firstSc?.map((screenshot, index) => (
                    <img
                      key={index}
                      className="md:w-80 w-52 md:mx-2 my-2"
                      src={screenshot.url}
                      alt="Map screenshot at start"
                    />
                  ))
                : null}

              {singleShiftReport.secondSc?.length > 0
                ? singleShiftReport.secondSc?.map((screenshot, index) => (
                    <img
                      key={index}
                      className="md:w-80 w-52 md:mx-2 my-2"
                      src={screenshot.url}
                      alt="Map screenshot at the end"
                    />
                  ))
                : null}
            </div>
          </section>

          <div className="flex items-center justify-center flex-col md:flex-row mt-10 mb-15">
            <Link to={`/dashboard/shiftReports/${id}`}>
              <button className="border-1 px-20 py-2 mx-2 rounded-full border-[#1ce5be] text-[#ececec] text-xl hover:text-[#ff5783]">
                Cancel
              </button>
            </Link>
            <button
              className="border-1 px-20 py-2 my-3 mx-2 rounded-full border-[#1ce5be] text-[#ececec] text-xl hover:text-[#ff5783]"
              onClick={(e) => saveReport(e)}
            >
              Save
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default UpdateShiftReport;
