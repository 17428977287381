export const getHopperName = (report) => {
  if (report.hopper && typeof report.hopper === "object") {
    return `${report.hopper.firstName} ${report.hopper.lastName}`;
  } else if (report.hopper) {
    return report.hopper;
  } else if (report.firstName) {
    return report.firstName;
  }
  return "Unknown";
};
