import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import FleetInfo from "./FleetInfo";
import { getShiftNumbers } from "../../../Features/shiftReport/shiftReportSlice";

const notify = (message) =>
  toast.warn(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const ShiftReportIndex = ({
  page,
  setPage,
  report,
  setLastPage,
  lastPage,
  setReport,
}) => {
  const [firstScreenshot, setFirstScreenshot] = useState([]);
  const [secondScreenshot, setSecondScreenshot] = useState([]);

  const dispatch = useDispatch();

  const { shiftNumbers } = useSelector((state) => state.shiftReport);

  const handleFirstScreenshot = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFirstScreenshot((prevState) => [...prevState, reader.result]);
        setReport({
          ...report,
          firstSc: [reader.result],
        });
      };
    });
  };

  const handleSecondScreenshot = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSecondScreenshot((prevState) => [...prevState, reader.result]);
        setReport({
          ...report,
          secondSc: [reader.result],
        });
      };
    });
  };

  const handleNextPage = (e) => {
    if (
      report.shiftType === "" ||
      report.maintenanceScooters === "" ||
      report.activeScooters === "" ||
      report.disabledScooters === ""
    ) {
      notify("Please fill all required fields!");
    } else {
      setPage(1);
    }
  };

  return (
    <>
      <section className="w-full mt-3 flex items-center flex-col justify-center px-5">
        <h3 className="text-2xl mt-3 text-[#ff5783] uppercase">Shift report</h3>

        <div
          className="border-3 lg:px-10 m-3 lg:max-w-md lg:m-5 rounded-md border-[#1ce5be]"
          style={{
            padding: "10px",
            margin: "10px",
            diplay: "flex",
            flexDirection: "row",
          }}
        >
          <div className="flex flex-col items-center py-2 my-4">
            <label className="text-[#ececec] mb-2 text-lg">
              <span className="text-red-500 mx-1">*</span> Shift:
            </label>
            <select
              className="bg-[#1f2022]  text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-5 rounded-md text-lg leading-tight"
              name="shiftType"
              value={report.shiftType}
              onChange={(e) => {
                setReport({ ...report, shiftType: e.target.value });
                dispatch(getShiftNumbers(e.target.value));
              }}
            >
              <option value="">Select</option>
              <option value="Morning">Morning</option>
              <option value="Night">Night</option>
            </select>
          </div>

          <FleetInfo report={report} setReport={setReport} />

          <div className="flex flex-col items-center py-2 my-4">
            <label className="text-[#ececec] mb-2 text-lg">
              <span className="text-red-500 mx-1">*</span>Employees on shift:
            </label>
            <input
              className="bg-transparent border-1 focus:outline-none focus:border-[#1ce5be] py-2 px-2 rounded-md text-[#1ce5be] text-lg"
              type="number"
              min="0"
              placeholder={shiftNumbers ? shiftNumbers.length : 0}
              max="100"
              onWheel={(e) => e.target.blur()}
              name="employeeInput"
              onChange={(e) => {
                setReport({ ...report, employeeCount: e.target.value });
                setLastPage(e.target.value);
              }}
            />
          </div>

          <div className="flex flex-col items-center my-4 px-5">
            <label className="text-[#ececec] mb-2 text-lg">20% and PV:</label>

            <div className="my-2">
              <label>
                <input
                  onChange={handleFirstScreenshot}
                  onClick={() => setFirstScreenshot([])}
                  type="file"
                  id="file-upload"
                  className="text-sm text-[#ececec]
                  file:mr-5 file:py-2 file:px-6
                  file:rounded-full file:border-1
                  file:border-[#1ce5be]
                  file:text-sm file:font-medium
                  file:bg-transparent file:text-[#1ce5be]
                  hover:file:cursor-pointer"
                  name="images"
                />
              </label>
            </div>
            {firstScreenshot.length > 0 && (
              <div className="flex flex-wrap">
                {firstScreenshot.map((image, index) => (
                  <img
                    className="md:w-32 h-56 mx-1 pt-3"
                    alt="daily-car"
                    src={image}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col items-center my-4 px-5">
            <label className="text-[#ececec] mb-2 text-lg">
              On street and in shop maintenance:
            </label>

            <div className="my-2">
              <label>
                <input
                  onChange={handleSecondScreenshot}
                  onClick={() => setSecondScreenshot([])}
                  type="file"
                  id="file-upload"
                  className="text-sm text-[#ececec]
                  file:mr-5 file:py-2 file:px-6
                  file:rounded-full file:border-1
                  file:border-[#1ce5be]
                  file:text-sm file:font-medium
                  file:bg-transparent file:text-[#1ce5be]
                  hover:file:cursor-pointer"
                  name="images"
                />
              </label>
            </div>
            {secondScreenshot.length > 0 && (
              <div className="flex flex-wrap">
                {secondScreenshot.map((image, index) => (
                  <img
                    className="md:w-32 h-56 mx-1 pt-3"
                    alt="daily-car"
                    src={image}
                    key={index}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="w-full flex items-center justify-between flex-row mt-5 mb-10 px-5">
          <Link to="/home">
            <button className="border-1 px-10 py-2 my-2 rounded-full border-[#1ce5be] text-[#ececec] text-md hover:text-[#ff5783]">
              Cancel
            </button>
          </Link>
          {lastPage > 0 ? (
            <button
              className="border-1 px-10 py-2 my-2 rounded-full border-[#1ce5be] text-[#ececec] text-md hover:text-[#ff5783]"
              type="button"
              onClick={handleNextPage}
            >
              Next
            </button>
          ) : null}
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default ShiftReportIndex;
