import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

//User change password
const userChangePassword = async (passwordData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/home/userProfile/${passwordData.id}`,
    passwordData,
    config
  );

  return response.data;
};

const userProfileService = {
  userChangePassword,
};

export default userProfileService;
