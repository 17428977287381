import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDeilibilars } from "../../../Features/newFleet/fleetSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import IssueCount from "../../IssueCount/IssueCount";
import Input from "../../UI/input/Input";

const AllDailyCars = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");

  const handleRowClick = (id) => {
    if (user.role === "Admin") {
      navigate(`/dashboard/allFleet/deilibilar/${id}`);
    } else {
      navigate(`/home/allDeilibilars/${id}`);
    }
  };

  const { user } = useSelector((state) => state.auth);
  const { allDeilibilars, isLoading } = useSelector((state) => state.fleet);

  useEffect(() => {
    dispatch(getDeilibilars());
  }, [dispatch]);

  const filteredDeilibilars = allDeilibilars.filter(
    (car) =>
      car.licensePlate.toLowerCase().includes(searchQuery.toLowerCase()) ||
      car.qrCode.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="mt-10">
      <section className="flex items-center flex-col justify-center text-[#ececec]">
        <Input
          type="text"
          placeholder="Search for deilibilar..."
          value={searchQuery}
          hideLabel={true}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {isLoading ? (
          <Spinner />
        ) : (
          <table>
            <tbody>
              <tr className="text-center">
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  Issues:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  License plate:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  QR code:
                </th>
                <th className="hidden md:block text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  Make & model:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  Current mileage:
                </th>
              </tr>
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                filteredDeilibilars.map((car, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(car._id)}
                      className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
                    >
                      <td className="pr-3 py-3 text-center text-[#ececec] ">
                        <IssueCount issues={car.issues} />
                      </td>
                      <td className="pr-3 py-3 text-center text-[#ececec] ">
                        {car.licensePlate}
                      </td>
                      <td className="pr-3 py-3 text-center text-[#ececec] ">
                        {car.qrCode}
                      </td>
                      <td className="hidden md:block pr-3 py-3 uppercase text-center text-[#ececec] ">
                        {car?.brand} {car?.model}
                      </td>
                      <td className="pr-3 py-3 text-center text-[#ececec] ">
                        {car.mileage} km
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        )}
      </section>
    </div>
  );
};

export default AllDailyCars;
