import { getHopperName } from "../../../utils/getHopperName";

const ShiftReportCard = ({ report }) => {
  const hopperName = getHopperName(report);

  return (
    <section className="flex my-3 py-3 border-1 rounded-md border-[#1ce5be] lg:w-96 mx-2">
      <div className="flex items-center justify-center mx-3">
        <h1 className="text-[#ececec] font-semibold underline decoration-[#ff5783]">
          {hopperName}
        </h1>
      </div>
      <div className="mx-3 flex flex-col">
        <span className="text-[#ececec] my-2">Area assigned:</span>
        <ul className="list-disc mx-4">
          {report.district?.map((area, index) => (
            <li className="text-[#ff5783]" key={index}>
              {area.label}
            </li>
          ))}
        </ul>
        <span className="text-[#ececec] my-2">Cargo:</span>
        <ul className="list-disc mx-4">
          {report.cargos?.map((cargo, index) => (
            <li className="text-[#ff5783]" key={index}>
              {cargo.label}
            </li>
          ))}
        </ul>
        <ul className="list-none">
          <li className="text-[#ececec] my-2">
            Batteries changed:{" "}
            <span className="text-[#ff5783]">{report.batteries}</span>
          </li>
          {report.batteries_replaced ||
          report.hopper?.totalBatteriesReplaced ? (
            <li className="text-[#ececec] my-2">
              Batteries changed according to OP:{" "}
              <span className="text-[#ff5783]">
                {report.batteries_replaced ||
                  report.hopper.totalBatteriesReplaced}
              </span>
            </li>
          ) : null}
          <li className="text-[#ececec] my-2">
            Hopper hikes: <span className="text-[#ff5783]">{report.hikes}</span>
          </li>
          <li className="text-[#ececec] my-2">
            Scooters fixed on street:{" "}
            <span className="text-[#ff5783]">{report.fixed}</span>
          </li>
          <li className="text-[#ececec] my-2">
            Scooters left on street (MTC):{" "}
            <span className="text-[#ff5783]">{report.leftMtc}</span>
          </li>
          <li className="text-[#ececec] my-2">
            Scooters picked up for maintenance:{" "}
            <span className="text-[#ff5783]">{report.pickedMtc}</span>
          </li>
          <li className="text-[#ececec] my-2">
            Rebalanced scooters:{" "}
            <span className="text-[#ff5783]">{report.rebalance}</span>
          </li>
          <li className="text-[#ececec] my-2">
            Fake issues:{" "}
            <span className="text-[#ff5783]">
              {report.fake ? report.fake : 0}
            </span>
          </li>
          <li className="text-[#ececec] my-2">
            Winter service damage:{" "}
            <span className="text-[#ff5783]">
              {report.winterService ? report.winterService : 0}
            </span>
          </li>
          <li className="text-[#ececec] my-2">
            Deilibilar service (minutes):{" "}
            <span className="text-[#ff5783]">
              {report.deilibilarServiceTime ? report.deilibilarServiceTime : 0}
            </span>
          </li>
          <li className="text-[#ececec] my-2">
            Phone used on shift:{" "}
            <span className="text-[#ff5783]">
              {report.phones &&
                report.phones.map((phone, index) => (
                  <p key={index}>{phone.label}</p>
                ))}
            </span>
          </li>
          <li className="text-[#ececec] my-2">
            Comment: <span className="text-[#ff5783]">{report.comment}</span>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ShiftReportCard;
