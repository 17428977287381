import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dateRangeService from "./dateRangeService";

const initialState = {
  data: [],
  sumData: "",
  secondData: [],
  secondSumData: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET DATA BY DATE RANGE BAR CHART
export const getStatsByDate = createAsyncThunk(
  "/dashboard/charts/dateRange",
  async (dates, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await dateRangeService.getSumByDateRange(dates, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET SECOND WEEK DATA BY DATE RANGE BAR CHART
export const getSecondStatsByDate = createAsyncThunk(
  "/dashboard/charts/secondDateRange",
  async (dates, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await dateRangeService.getSumByDateRange(dates, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET SUM BY DATE RANGE PIE CHART
export const getSumByDate = createAsyncThunk(
  "/dashboard/charts/dataRangeSum",
  async (dates, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await dateRangeService.getTotalNumbers(dates, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET SECOND WEEK SUM BY DATE RANGE PIE CHART
export const getSecondSumByDate = createAsyncThunk(
  "/dashboard/charts/secondDataRangeSum",
  async (dates, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await dateRangeService.getTotalNumbers(dates, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dateRangeSlice = createSlice({
  name: "barRangeData",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatsByDate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStatsByDate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getStatsByDate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSecondStatsByDate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSecondStatsByDate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.secondData = action.payload;
      })
      .addCase(getSecondStatsByDate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSumByDate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSumByDate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sumData = action.payload;
      })
      .addCase(getSumByDate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSecondSumByDate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSecondSumByDate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.secondSumData = action.payload;
      })
      .addCase(getSecondSumByDate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;
