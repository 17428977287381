import {
  FaCar,
  FaFlag,
  FaList,
  FaTruck,
  FaChartLine,
  FaTools,
} from "react-icons/fa";
import { GiHouseKeys } from "react-icons/gi";
import { BsListStars } from "react-icons/bs";
import { useSelector } from "react-redux";
import HomeNavigation from "../../Components/HomeNav/HomeNavigation";

const CaptainHome = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <div className="md:w-full md:flex md:items-center md:justify-center">
        <section className="flex items-center justify-center flex-wrap md:max-w-2xl md:h-full md:mt-10">
          <HomeNavigation
            route="/home/carReport"
            title="new Deilibilar report"
            icon={<FaCar />}
          />
          <HomeNavigation
            route="/home/allDeilibilars"
            title="Deilibilar"
            icon={<GiHouseKeys />}
          />
          <HomeNavigation
            title="new Shift report"
            route="/home/shiftReport"
            icon={<FaFlag />}
          />
          <HomeNavigation
            title="new Fixer report"
            route="/home/fixerReport"
            icon={<FaTools />}
          />
          <HomeNavigation
            route="/home/shiftReports"
            title="Shift report list"
            icon={<FaList />}
          />
          <HomeNavigation
            route="/home/deilibilarReports"
            title="Deilibilar report list"
            icon={<BsListStars />}
          />
          <HomeNavigation
            title="new Service car report"
            route="/home/serviceCarReport"
            icon={<FaTruck />}
          />
          <HomeNavigation
            title="Service cars"
            route="/home/serviceCars"
            icon={<FaTruck />}
          />
          {user && user.role === "Admin" && (
            <>
              <HomeNavigation
                title="Dashboard"
                route="/dashboard"
                icon={<FaChartLine />}
              />
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default CaptainHome;
