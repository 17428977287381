const TextArea = ({ label, aria, name, value, placeholder, onChange }) => {
  return (
    <div className="w-80 flex flex-col my-3">
      <label className="text-[#ececec] mb-2 mx-2 text-lg">{label}:</label>
      <textarea
        className="bg-[#1f2022] text-[#ececec] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 md:py-8 md:px-8 rounded-md text-lg leading-tight w-full h-40 resize-none"
        aria-label={aria}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default TextArea;
