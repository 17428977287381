import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import carReportService from "./carReportService";

const initialState = {
  carReports: [],
  latestCarReports: [],
  singleReport: [],
  newMileage: [],
  reportsById: [],
  delete: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: null,
};

// Create new car report
export const createCarReport = createAsyncThunk(
  "home/carReport/create",
  async (carReportData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await carReportService.createCarReport(carReportData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// DELETE Deilibilar report
export const deleteCarReport = createAsyncThunk(
  "home/carReport/delete/:id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await carReportService.deleteCarReport(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  UPDATE DEILIBILAR MILEAGE
export const updateMileage = createAsyncThunk(
  "home/carReport/updateMileage",
  async (updateData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await carReportService.updateDeilibilarMileage(updateData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  GET ALL DEILIBILAR REPORTS
export const getCarReports = createAsyncThunk(
  "/dashboard/allCarReports",
  async (page, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await carReportService.getCarReports(page, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  GET ALL DEILIBILAR REPORTS
export const getLatestCarReports = createAsyncThunk(
  "/dashboard/allCarReports/latest",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await carReportService.getLatestCarReports(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET DEILIBILAR REPORT BY ID
export const getCarReport = createAsyncThunk(
  "/dashboard/dailyCarReports/:id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await carReportService.getCarReportById(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET LATEST DEILIBILAR REPORTS BY DEILIBILAR ID
export const getAllCarReports = createAsyncThunk(
  "/dashboard/dailyCarReports/allReports/:id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await carReportService.getReportsById(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const carReportSlice = createSlice({
  name: "carReport",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCarReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCarReport.fulfilled, (state, action) => {
        state.isSuccess = action.payload.success;
        state.carReports.push(action.payload);
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(createCarReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCarReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCarReport.fulfilled, (state, action) => {
        state.isSuccess = action.payload.success;
        state.delete.push(action.payload);
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(deleteCarReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCarReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCarReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.carReports = action.payload;
      })
      .addCase(getCarReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getLatestCarReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLatestCarReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.latestCarReports = action.payload;
      })
      .addCase(getLatestCarReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCarReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCarReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleReport = action.payload;
      })
      .addCase(getCarReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateMileage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMileage.fulfilled, (state, action) => {
        state.newMileage.push(action.payload);
      })
      .addCase(updateMileage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllCarReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCarReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.reportsById = action.payload;
      })
      .addCase(getAllCarReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = carReportSlice.actions;
export default carReportSlice.reducer;
