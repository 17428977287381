import Moment from "react-moment";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFixerReports } from "../../../../Features/fixerReport/fixerReportSlice";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../../Spinner/Spinner";
import Pagination from "../../../Pagination/Pagination";

const FixerReportTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleRowClick = (id) => {
    navigate(`/home/fixerReports/${id}`);
  };

  const { allFixerReports, isLoading } = useSelector(
    (state) => state.fixerReport
  );

  console.log(allFixerReports);

  useEffect(() => {
    dispatch(getFixerReports(currentPage));
  }, [dispatch, currentPage]);

  const lastReportIndex = currentPage * rowsPerPage;
  const firstReportIndex = lastReportIndex - rowsPerPage;

  return (
    <>
      <div>
        <section className="flex items-center flex-col justify-center text-[#ececec]">
          <div className="text-2xl my-3 text-[#ff5783] uppercase">
            <h3>Fixer reports</h3>
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <table>
              <tbody>
                <tr className="text-center">
                  <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                    Fixer:
                  </th>
                  <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                    Fixed on street:
                  </th>
                  <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                    Picked MTC:
                  </th>
                  <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                    Date created:
                  </th>
                </tr>
                {allFixerReports &&
                  allFixerReports.data &&
                  allFixerReports.data.map((report, index) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
                        onClick={() => handleRowClick(report._id)}
                      >
                        <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                          {report.firstName}
                        </td>
                        <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                          {report.fixed}
                        </td>
                        <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                          {report.pickedMtc}
                        </td>
                        <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                          <Moment format="DD-MM-YYYY">
                            {report.createdAt}
                          </Moment>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
          <Pagination
            totalReports={allFixerReports.totalFixerReports}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </section>
      </div>
    </>
  );
};

export default FixerReportTable;
