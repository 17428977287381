import { useState, useEffect } from "react";
import Moment from "react-moment";
import { MdHideImage, MdImage } from "react-icons/md";
import { useDispatch } from "react-redux";
import Button from "../../UI/button/Button";
import { createNewDeilibilarIssue } from "../../../Features/deilibilarIssues/issuesSlice";
import DailyCarNewIssue from "../DailyCarNewIssue/DailyCarNewIssue";

import { useNavigate } from "react-router-dom";

const IssueTable = ({ allIssues, id, fleetInfo }) => {
  const [issueModal, setIssueModal] = useState(false);

  const [images, setImages] = useState([]);
  const [issueData, setIssueData] = useState({
    issueType: "",
    description: "",
    deilibilarId: id,
    images: [],
    licensePlate: "",
    qrCode: "",
    brand: "",
    model: "",
  });

  const resetIssueData = () => {
    setIssueData({
      issueType: "",
      description: "",
      deilibilarId: id,
      image: [],
      licensePlate: fleetInfo?.licensePlate || "",
      qrCode: fleetInfo?.qrCode || "",
      brand: fleetInfo?.brand || "",
      model: fleetInfo?.model || "",
    });
    setImages([]);
    setIssueModal(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (fleetInfo) {
      setIssueData((prevState) => ({
        ...prevState,
        licensePlate: fleetInfo.licensePlate,
        qrCode: fleetInfo.qrCode,
        brand: fleetInfo.brand,
        model: fleetInfo.model,
        image: images,
      }));
    }
  }, [fleetInfo, images]);

  const onChange = (e) => {
    setIssueData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const createNewIssue = (e) => {
    e.preventDefault();
    dispatch(createNewDeilibilarIssue(issueData));
    resetIssueData();
  };

  const openModal = () => {
    setIssueModal(true);
  };

  const handleRowClick = (id) => {
    navigate(`/home/deilibilar/allIssues/${id}`);
  };

  return (
    <>
      <div className="flex items-center justify-center my-3">
        <Button text="New issue" type="button" onClick={openModal} />
      </div>
      {allIssues && allIssues.length > 0 ? (
        <table>
          <tbody>
            <tr className="text-center ">
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Issue type:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                Description:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                Image:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                Date created:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3  mx-2 text-center pb-2">
                Created by:
              </th>
            </tr>
            {allIssues.map((issue, index) => {
              return (
                <tr
                  key={index}
                  className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
                  onClick={() => handleRowClick(issue._id)}
                >
                  <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                    {issue.issueType}
                  </td>
                  <td className="pr-3 py-3 text-sm text-center w-20 h-20 text-[#ececec]">
                    {issue.description}
                  </td>
                  <td className="pr-3 py-3 text-center text-[#ececec] ">
                    {issue.image.length > 0 ? (
                      <MdImage className="text-xl text-center ml-2 text-[#ececec]" />
                    ) : (
                      <MdHideImage className="text-xl text-center ml-2 text-[#ececec]" />
                    )}
                  </td>
                  <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                    <Moment format="DD-MM-YYYY">{issue.createdAt}</Moment>
                  </td>
                  <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                    {issue.userCreated?.firstName}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}

      {issueModal && (
        <DailyCarNewIssue
          issueData={issueData}
          onChange={onChange}
          onSubmit={createNewIssue}
          images={images}
          setImages={setImages}
          reset={resetIssueData}
        />
      )}
    </>
  );
};

export default IssueTable;
