import Select from "react-select";

const districts = [
  { value: "Reykjavík West", label: "Reykjavík West" },
  { value: "Downtown", label: "Downtown" },
  { value: "Reykjavík East", label: "Reykjavík East" },
  { value: "Kópavogur", label: "Kópavogur" },
  { value: "Breiðholt", label: "Breiðholt" },
  { value: "Grafarvogur", label: "Grafarvogur" },
  { value: "Hafnarfjörður", label: "Hafnarfjörður" },
  { value: "Árbær", label: "Árbær" },
  { value: "Grafarholt", label: "Grafarholt" },
  { value: "Garðabær", label: "Garðabær" },
  { value: "Mosfellsbær", label: "Mosfellsbær" },
  { value: "Hlemmur", label: "Hlemmur" },
  { value: "Hlíðar", label: "Hlíðar" },
];

const DistrictMultiSelect = ({ onChange }) => {
  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#1f2022",
      borderRadius: "5px",
      border: "1px solid #1ce5be",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
      paddingRight: "5px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ececec",
      borderRadius: "7px",
      boxShadow: "none",
      backgroundColor: "#1f2022",
      color: "#1ce5be",
      fontSize: "18px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#1f2022",
      color: "#1ce5be",
      fontSize: "18px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
    }),
  };

  return (
    <Select
      isMulti
      name="district"
      options={districts}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={onChange}
      styles={customStyles}
    />
  );
};

export default DistrictMultiSelect;
