import { Line } from "react-chartjs-2";
import { getHopperName } from "../../utils/getHopperName";
import "./styles.css";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
        color: "#ececec",
        padding: 10,
        font: {
          size: 13,
        },
      },
    },
    title: {
      display: true,
      text: "Shift average score (past 7 days)",
      color: "#ff5783",
      font: {
        size: 25,
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#ececec",
      },
      grid: {
        color: "rgba(236, 236, 236, 0.25)",
      },
    },
    y: {
      ticks: {
        color: "#ececec",
      },
      grid: {
        color: "rgba(236, 236, 236, 0.25)",
      },
    },
  },
};

const LineChart = ({ morningData, nightData }) => {
  const labels = morningData.map((day, index) => day.createdAt);

  const calcAverage = (data) => {
    return data?.map((item, index) => {
      let hopperName = getHopperName(item);

      let arr = hopperName.match(/[A-Z][a-z]+/g);

      return Math.ceil(
        (item.batteries +
          item.rebalance * 1.5 +
          item.hikes * 1.1 +
          item.fixed * 1.3 +
          item.pickedMtc * 1.2) /
          (arr?.length || 1)
      );
    });
  };

  const morning = morningData ? calcAverage(morningData) : null;
  const night = nightData ? calcAverage(nightData) : null;

  const chartData = {
    labels,
    datasets: [
      {
        label: "Morning shift",
        data: morning,
        borderColor: "#1ce5be",
        backgroundColor: "#1ce5be",
      },

      {
        label: "Night shift",
        data: night,
        borderColor: "#457FE5",
        backgroundColor: "#457FE5",
      },
    ],
  };

  return (
    <>
      <div className="flex items-center justify-center mt-5 my-5">
        {morningData && nightData && (
          <Line
            options={options}
            data={chartData}
            className="chart"
            // width="380px"
            // height="450px"
          />
        )}
      </div>
    </>
  );
};

export default LineChart;
