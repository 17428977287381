import { FaTruck } from "react-icons/fa";
import HomeNavigation from "../../Components/HomeNav/HomeNavigation";

const HopperHome = () => {
  return (
    <>
      <div className="md:w-full md:flex md:items-center md:justify-center">
        <section className="flex items-center justify-center flex-wrap md:max-w-2xl md:h-full md:mt-10">
          <HomeNavigation
            title="new Service car report"
            route="/index/serviceCarReport"
            icon={<FaTruck />}
          />
          <HomeNavigation
            title="Service cars"
            route="/index/serviceCars"
            icon={<FaTruck />}
          />
        </section>
      </div>
    </>
  );
};

export default HopperHome;
