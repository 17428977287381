import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createFixerReport,
  reset,
} from "../../Features/fixerReport/fixerReportSlice";
import CargoMultiselect from "../../Components/Forms/ShiftReportForm/components/CargoMultiselect/CargoMultiselect";
import DistrictMultiSelect from "../../Components/Forms/ShiftReportForm/components/DistrictMultiselect/DistrictMultiSelect";
import ShiftInput from "../../Components/UI/shiftInput/ShiftInput";
import Input from "../../Components/UI/input/Input";
import Button from "../../Components/UI/button/Button";
import Spinner from "../../Components/Spinner/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const FixerReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    district: [],
    cargos: [],
    batteries: 0,
    hikes: 0,
    fixed: 0,
    pickedMtc: 0,
    fake: 0,
    winterService: 0,
    rebalance: 0,
    comment: "",
  });

  const { isError, isLoading, message, isSuccess } = useSelector(
    (state) => state.fixerReport
  );

  const setServiceCar = (e) => {
    let newForm;
    newForm = e;
    setFormData({ ...formData, serviceCar: newForm });
  };

  const setDistrict = (e) => {
    let newForm;
    newForm = e;
    setFormData({ ...formData, areaAssigned: newForm });
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const cancelBtn = () => {
    navigate("/home");
  };

  useEffect(() => {
    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
    }
  }, [isSuccess, isError, message, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(createFixerReport(formData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="flex items-center justify-center">
        <h3 className="text-2xl mt-3 text-[#ff5783] uppercase">Fixer report</h3>
      </div>

      <div>
        <form onSubmit={onSubmit}>
          <div className="flex items-center justify-center">
            <div
              className="border-3 lg:px-10 m-3 lg:max-w-md lg:m-5 rounded-md border-[#1ce5be]"
              style={{
                padding: "10px",
                margin: "10px",
                diplay: "flex",
                flexDirection: "row",
              }}
            >
              <div className="flex flex-col items-center justify-center py-2 my-4 mx-3">
                <label className="text-[#ececec] mb-2 text-lg">
                  Area assigned:
                </label>

                <div className="flex my-2">
                  <DistrictMultiSelect onChange={setDistrict} />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center  py-2 my-4 mx-3">
                <label className="text-[#ececec] mb-2 text-lg">
                  Vehicles used during shift:
                </label>

                <div className="flex my-2">
                  <CargoMultiselect onChange={setServiceCar} />
                </div>
              </div>

              <section className="flex flex-col items-center justify-center">
                <ShiftInput
                  label="Batteries changed:"
                  placeholder={11}
                  value={formData.batteries}
                  name="batteries"
                  onChange={onChange}
                />

                <ShiftInput
                  label="Hopper hikes:"
                  placeholder={7}
                  value={formData.hikes}
                  name="hikes"
                  onChange={onChange}
                />

                <ShiftInput
                  label="Scooters fixed on street:"
                  placeholder={2}
                  value={formData.fixed}
                  name="fixed"
                  onChange={onChange}
                />

                <ShiftInput
                  label="Scooters picked for maintenance:"
                  placeholder={7}
                  value={formData.pickedMtc}
                  name="pickedMtc"
                  onChange={onChange}
                />

                <ShiftInput
                  label="Good scooters with fake issues:"
                  placeholder={4}
                  value={formData.fake}
                  name="fake"
                  onChange={onChange}
                />

                <ShiftInput
                  label="Winter service damage:"
                  placeholder={6}
                  value={formData.winterService}
                  name="winterService"
                  onChange={onChange}
                />

                <ShiftInput
                  label="Rebalanced scooters:"
                  placeholder={6}
                  value={formData.rebalance}
                  name="rebalance"
                  onChange={onChange}
                />

                <Input
                  label="Comments"
                  type="text"
                  name="comment"
                  placeholder="Write comment here.."
                  value={formData.comment}
                  onChange={onChange}
                />
              </section>
            </div>
          </div>

          <div className="flex items-center flex-col justify-center ">
            <Button type="submit" text="Submit" />
            <Button text="Cancel" onClick={cancelBtn} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default FixerReport;
