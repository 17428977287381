import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cargoIssueService from "./cargoIssueService";

const initialState = {
  cargoIssues: [],
  resolvedCargoIssues: [],
  updateCargoIssue: [],
  allCargoIssues: [],
  cargoLatestIssue: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// NEW SERVICE CAR ISSUE
export const createNewCargoIssue = createAsyncThunk(
  "home/serviceCar/newIssue",
  async (issueData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cargoIssueService.createNewCargoIssue(issueData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//GET SERVICE CAR ISSUES BY ID
export const getCargoIssuesById = createAsyncThunk(
  "home/serviceCar/issues/id",
  async (cargoId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cargoIssueService.getCargoIssues(cargoId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET LATEST CARGO ISSUE
export const getNewestIssue = createAsyncThunk(
  "home/serviceCar/latestIssue",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cargoIssueService.getLatestIssue(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//GET RESOLVED SERVICE CAR ISSUES BY ID
export const getResolvedCargoIssues = createAsyncThunk(
  "home/serviceCar/resolvedIssues/id",
  async (cargoId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cargoIssueService.getResolvedCargoIssues(cargoId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//UPDATE SERVICE CAR ISSUE
export const updateCargoIssue = createAsyncThunk(
  "home/serviceCar/issues/resolved",
  async (updateData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await cargoIssueService.updateCargoIssue(updateData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const cargoIssueSlice = createSlice({
  name: "cargoIssues",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewCargoIssue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewCargoIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cargoIssues.push(action.payload);
      })
      .addCase(createNewCargoIssue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCargoIssue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCargoIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateCargoIssue.push(action.payload);
      })
      .addCase(updateCargoIssue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getResolvedCargoIssues.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResolvedCargoIssues.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resolvedCargoIssues = action.payload;
      })
      .addCase(getResolvedCargoIssues.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCargoIssuesById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCargoIssuesById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allCargoIssues = action.payload;
      })
      .addCase(getCargoIssuesById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getNewestIssue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNewestIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cargoLatestIssue = action.payload;
      })
      .addCase(getNewestIssue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = cargoIssueSlice.actions;
export default cargoIssueSlice.reducer;
