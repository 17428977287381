import React from "react";

const NewWeek = ({ setDates, dates, applySecondFilter }) => {
  const updateExtraDates = (newExtraDates) => {
    const updatedDates = { ...dates, ...newExtraDates };

    setDates(updatedDates);
  };

  return (
    <div className="flex justify-center items-center flex-col md:flex-row my-10">
      <input
        type="date"
        className="mx-3 bg-transparent text-[#1ce5be] border-1 border-[#1ce5be] p-2 rounded-full"
        onChange={(e) => {
          updateExtraDates({ extraStartDate: e.target.value });
        }}
      />
      <span className="text-[#ff5783]">to</span>
      <input
        type="date"
        className="mx-3 bg-transparent text-[#1ce5be] border-1 border-[#1ce5be] p-2 rounded-full"
        onChange={(e) => {
          updateExtraDates({ extraEndDate: e.target.value });
        }}
      />
      {dates.startDate !== "" &&
        dates.endDate !== "" &&
        dates.extraStartDate !== "" &&
        dates.extraEndDate !== "" && (
          <button
            className="border-1 px-10 py-2 my-2 rounded-full border-[#1ce5be] text-[#ececec] text-md hover:text-[#ff5783]"
            onClick={applySecondFilter}
            type="button"
          >
            Filter
          </button>
        )}
    </div>
  );
};

export default NewWeek;
