import { useEffect, useState } from "react";
import Moment from "react-moment";
import { getCargoProfile } from "../../../Features/newFleet/cargoSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import ConfirmDisable from "./ConfirmDisable";
import Tabs from "./Tabs";
import LicensePlate from "../../LicensePlate/LicensePlate";

const CargoCar = () => {
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const { cargoInfo, isLoading } = useSelector((state) => state.cargo);

  const { user } = useSelector((state) => state.auth);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getCargoProfile(id));
  }, [dispatch, id]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <main className="flex justify-center items-center flex-col mt-3">
          <section className="flex flex-col items-center mr-5 ">
            <h2 className="text-[#ff5783] uppercase mb-3 font-bold">
              {cargoInfo.model}
            </h2>
            {cargoInfo.image?.map((image, index) => {
              return (
                <img
                  key={index}
                  className="w-60 rounded-md object-cover"
                  src={image.url}
                  alt="Service car"
                />
              );
            })}
            <div className="flex my-2">
              <LicensePlate plateNumber={cargoInfo.licensePlate} />
            </div>

            <div className="flex my-2">
              <span className="text-[#ff5783] text-lg font-semibold mr-2">
                QR:
              </span>
              <span className="text-[#ececec] text-lg">
                #{cargoInfo.qrCode}
              </span>
            </div>
          </section>

          <section className="flex flex-col justify-center items-center">
            <div className="flex my-2">
              <span className="text-[#ff5783] text-lg font-semibold mr-2">
                Created at:
              </span>
              <Moment className="text-[#ececec]" format="DD-MM-YYYY HH:mm">
                {cargoInfo.createdAt}
              </Moment>
            </div>

            <div className="flex my-2">
              <span className="text-[#ff5783] text-lg font-semibold mr-2">
                User created:
              </span>
              <span className="text-[#ececec]">
                {cargoInfo.userCreated?.firstName}{" "}
                {cargoInfo.userCreated?.lastName}
              </span>
            </div>
          </section>
          {user.role === "Admin" ? (
            <section className="my-3">
              <button
                className="border-1 px-10 py-2  rounded-full border-[#ff5783] text-[#ff5783] text-sm"
                onClick={() => setOpenModal(true)}
              >
                Delete
              </button>
            </section>
          ) : null}
          <Tabs />
          {openModal && (
            <ConfirmDisable setOpenModal={setOpenModal} serviceCarId={id} />
          )}
        </main>
      )}
    </>
  );
};

export default CargoCar;
