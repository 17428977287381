import Spinner from "../Spinner/Spinner";
import {
  changePassword,
  reset,
} from "../../Features/userProfile/userProfileSlice";
import Moment from "react-moment";
import { useEffect, useState } from "react";
import { BiUser } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const UserProfile = () => {
  const dispatch = useDispatch();

  const { user, isLoading } = useSelector((state) => state.auth);

  const { isSuccess, isError, message } = useSelector(
    (state) => state.userProfile
  );

  const [formData, setFormData] = useState({
    id: user._id,
    oldPassword: "",
    newPassword: "",
    newPassword2: "",
  });

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
      dispatch(reset());
    }
  }, [isSuccess, isError, message, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.newPassword2) {
      errToast("Passwords doesn't match!");
    } else if (formData.newPassword === "" && formData.newPassword2 === "") {
      errToast("Please fill password fields!");
    } else {
      dispatch(changePassword(formData));
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <main className="flex justify-center flex-col items-center mt-3">
          <div className="md:flex">
            <section className="flex flex-col items-center justify-center mx-20">
              <BiUser className="text-[#1ce5be] text-7xl mr-2 mt-1" />
              <span className="text-[#ececec] text-lg my-2">
                ID: #{user._id.slice(-5)}
              </span>
              <span className="text-[#ececec] text-lg my-2 ">
                Role: {user.role}
              </span>
            </section>
            <section className="flex flex-col items-start ">
              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  First Name:
                </span>
                <span className="text-[#ececec] mb-3">{user.firstName}</span>
              </div>

              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  Last Name:
                </span>
                <span className="text-[#ececec] mb-3">{user.lastName}</span>
              </div>

              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  Email:
                </span>
                <span className="text-[#ececec] mb-3">{user.email}</span>
              </div>
              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  Phone number:
                </span>
                <span className="text-[#ececec] mb-3">
                  {user.phoneNumber ? user.phoneNumber : "No phone number"}
                </span>
              </div>

              <div className="flex flex-col">
                <span className="text-[#ff5783] text-lg font-semibold">
                  User since:
                </span>
                <Moment
                  className="text-[#ececec] mb-3"
                  format="DD-MM-YYYY HH:mm"
                >
                  {user.createdAt}
                </Moment>
              </div>
            </section>
          </div>

          <form onSubmit={onSubmit}>
            <section className="text-[#ececec] mt-10 flex justify-center flex-col items-center">
              <div className="flex md:flex-row flex-col justify-center items-center my-4">
                <Input
                  label="Old password"
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  value={formData.oldPassword}
                  onChange={onChange}
                  autoComplete="off"
                  placeholder="Enter old password"
                />
              </div>
              <div className="flex md:flex-row flex-col justify-center items-center my-4">
                <Input
                  label="New password"
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={onChange}
                  autoComplete="off"
                  placeholder="New password"
                />
              </div>
              <div className="flex md:flex-row flex-col justify-center items-center my-4">
                <Input
                  label="Repeat password"
                  type="password"
                  id="newPassword2"
                  name="newPassword2"
                  value={formData.newPassword2}
                  onChange={onChange}
                  autoComplete="off"
                  placeholder="Repeat password"
                />
              </div>
            </section>
            <div className="flex items-center justify-center flex-col mt-10 mb-15">
              <Button text="Update" type="submit" />
            </div>
          </form>
        </main>
      )}
      <ToastContainer />
    </>
  );
};

export default UserProfile;
