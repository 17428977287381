import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fixerReportService from "./fixerReportService";

const initialState = {
  fixerReport: {},
  allFixerReports: [],
  fixerReportById: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create fixer report
export const createFixerReport = createAsyncThunk(
  "home/fixer/newReport",
  async (reportData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await fixerReportService.createFixerReport(reportData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all fixer reports
export const getFixerReports = createAsyncThunk(
  "home/fixer/all",
  async (filter, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await fixerReportService.getAllFixerReports(filter, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get single fixer report
export const getFixerReport = createAsyncThunk(
  "home/fixer/:id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await fixerReportService.getFixerReport(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fixerSlice = createSlice({
  name: "fixerReport",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFixerReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFixerReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fixerReport = action.payload;
      })
      .addCase(createFixerReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getFixerReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFixerReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allFixerReports = action.payload;
      })
      .addCase(getFixerReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFixerReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFixerReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fixerReport = action.payload;
      })
      .addCase(getFixerReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = fixerSlice.actions;
export default fixerSlice.reducer;
