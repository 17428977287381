import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { getAllCarReports } from "../../../Features/carReport/carReportSlice";

const ReportTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { reportsById } = useSelector((state) => state.carReport);

  useEffect(() => {
    dispatch(getAllCarReports(id));
  }, [dispatch, id]);

  const handleRowClick = (id) => {
    navigate(`/home/deilibilarReports/${id}`);
  };

  return (
    <>
      <section className="flex items-center justify-center my-4">
        {reportsById.length > 0 ? (
          <table>
            <tbody>
              <tr className="text-center ">
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  Mileage:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                  Date created:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3  mx-2 text-center pb-2">
                  Created by:
                </th>
              </tr>
              {reportsById.map((report, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => handleRowClick(report._id)}
                    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
                  >
                    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                      {report.mileage} km
                    </td>
                    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                      <Moment format="DD-MM-YYYY">{report.createdAt}</Moment>
                    </td>
                    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                      {report.userCreated?.firstName}{" "}
                      {report.userCreated?.lastName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </section>
    </>
  );
};

export default ReportTable;
