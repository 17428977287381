import AnnouncementForm from "../../Components/Announcement/AnnouncementForm/AnnouncementForm";
import AnnouncementTabs from "../../Components/Announcement/AnnouncementTabs/AnnouncementTabs";

const Announcement = () => {
  return (
    <>
      <section>
        <div className="flex items-center flex-col justify-center">
          <h3 className="text-2xl mt-5 text-[#ff5783] font-bold tracking-wider uppercase">
            New announcement
          </h3>
          <AnnouncementForm />
        </div>

        <AnnouncementTabs />
      </section>
    </>
  );
};

export default Announcement;
