const InfoBanner = () => {
  return (
    <section className="max-w-lg flex flex-col md:flex-row justify-center items-center my-4">
      <div className="text-[#f1c40f] mx-2 md:text-lg px-4 ">
        When filling out the Service Vehicle Report Form, please note that if
        your report involves an insurance case, it is crucial to complete the
        <a
          href="https://arekstur.is/tilkynna-ohapp/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="font-semibold tracking-wide underline text-[#ff5783]">
            {" "}
            AREKSTUR Form{" "}
          </span>
        </a>
        as well. This ensures a thorough and efficient resolution process.
      </div>
    </section>
  );
};

export default InfoBanner;
