import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// create new deilibilar issue
const createNewIssue = async (issueData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/home/deilibilar/newIssue`,
    issueData,
    config
  );

  return response.data;
};

// GET ALL PENDING DEILIBILAR ISSUES
const getAllDeilibilarPendingIssues = async (page, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: page,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/fleet/allIssues`,
    config
  );

  return response.data;
};

// GET DEILIBILAR ISSUE BY ID
const getIssueById = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/fleet/allIssues/${id}`,
    config
  );

  return response.data;
};

//  GET DEILIBILAR ISSUES BY DEILIBILAR ID
const getDeilibilarIssues = async (deilibilarId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/deilibilar/issues/${deilibilarId}`,
    config
  );

  return response.data;
};

//  GET latest deilibilar issue
const getLatestIssue = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/deilibilar/latestIssue`,
    config
  );

  return response.data;
};

//  GET resolved deilibilar issues
const getResolvedIssues = async (deilibilarId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/deilibilar/resolvedIssues/${deilibilarId}`,
    config
  );

  return response.data;
};

//  RESOLVE DEILIBILAR ISSUE
const updateDeilibilarIssue = async (issueId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/home/deilibilar/issues/resolved/${issueId}`,
    issueId,
    config
  );

  return response.data;
};

const issueService = {
  createNewIssue,
  getDeilibilarIssues,
  getAllDeilibilarPendingIssues,
  updateDeilibilarIssue,
  getResolvedIssues,
  getLatestIssue,
  getIssueById,
};

export default issueService;
