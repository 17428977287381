import { useEffect, useState } from "react";
import {
  createEmployee,
  getAllUsersFromOp,
} from "../../../Features/team/teamSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../Spinner/Spinner";
import Input from "../../UI/input/Input";
import Select from "../../UI/select/Select";
import Button from "../../UI/button/Button";

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const notify = (message) =>
  toast.warn(message, {
    position: "top-left",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const EmployeeComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    api_id: 0,
    role: "",
  });

  const { allUsers, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.team
  );

  useEffect(() => {
    if (isSuccess) {
      successToast(message);
    }

    if (isError) {
      errToast(message);
    }

    dispatch(getAllUsersFromOp());
  }, [isSuccess, isError, message, navigate, dispatch]);

  const onPhoneChange = (e) => {
    const formattedPhoneNumber = `+354${e.target.value}`;
    const foundItem = allUsers.find(
      (item) => item.phone === formattedPhoneNumber
    );

    setFormData((prevState) => ({
      ...prevState,
      phoneNumber: e.target.value,
      api_id: foundItem ? foundItem.id : null,
    }));
  };

  //Event handler
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const cancelBtn = () => {
    navigate("/dashboard");
  };

  //Form submit
  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.api_id) {
      notify(
        "Please add employee to HOPP OP (dashboard) before adding to Hopp Manager"
      );
    } else {
      dispatch(createEmployee(formData));
    }
  };

  const roles = [
    {
      value: null,
      name: "Select role",
    },
    {
      value: "Captain",
      name: "Captain",
    },
    {
      value: "Hopper",
      name: "Hopper",
    },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className=" mt-3 flex items-center flex-col md:flex-row justify-center ">
        <form onSubmit={onSubmit}>
          <div className="border-1 rounded-md border-[#1ce5be] py-2 px-4">
            <div className="flex flex-col md:flex-row md:items-center my-4 md:justify-between ">
              <Input
                label="First name"
                type="text"
                id="firstName"
                name="firstName"
                onChange={onChange}
                value={formData.firstName}
                autoComplete="off"
                placeholder="First name"
              />
            </div>

            <div className="flex flex-col md:flex-row md:items-center my-4 md:justify-between">
              <Input
                label="Middle name"
                type="text"
                id="middleName"
                name="middleName"
                onChange={onChange}
                value={formData.middleName}
                autoComplete="off"
                placeholder="Middle name"
              />
            </div>

            <div className="flex flex-col md:flex-row md:items-center my-4 md:justify-between">
              <Input
                label="Last name"
                type="text"
                id="lastName"
                name="lastName"
                onChange={onChange}
                value={formData.lastName}
                autoComplete="off"
                placeholder="Last name"
              />
            </div>

            <div className="flex flex-col md:flex-row md:items-center my-4 md:justify-between">
              <Input
                label="Phone number"
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                onChange={onPhoneChange}
                value={formData.phoneNumber}
                autoComplete="off"
                placeholder="7773344"
              />
            </div>

            <div className="flex flex-col md:flex-row md:items-center my-4 md:justify-between">
              <Input
                label="Email address"
                type="email"
                id="email"
                name="email"
                onChange={onChange}
                value={formData.email}
                autoComplete="off"
                placeholder="john.johnsson@gmail.com"
              />
            </div>

            <div className="flex flex-col md:flex-row md:items-center my-4 md:justify-between">
              <Select
                label="Role"
                area="Role"
                name="role"
                value={formData.role}
                onChange={onChange}
                options={roles}
              />
            </div>

            <div className="flex flex-col md:flex-row md:items-center my-4 md:justify-between">
              <span className="text-[#ececec]">ID:</span>
              <span className="text-[#1ce5be]">
                {formData.phoneNumber && formData.api_id}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col md:flex-row mt-10 mb-15">
            <Button text="Cancel" type="button" onClick={cancelBtn} />
            <Button text="Submit" type="submit" />
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default EmployeeComponent;
