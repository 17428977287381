import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getDisabledUsers,
  enableSuspendedUser,
} from "../../Features/team/teamSlice";
import Pagination from "../../Components/Pagination/Pagination";
import Moment from "react-moment";
import Button from "../../Components/UI/button/Button";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import SuspendedSkeleton from "./SuspendedSkeleton";

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const UsersTable = ({ suspendedEmployee, enableUser }) => (
  <table>
    <tbody>
      <tr className="text-center">
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          First name:
        </th>
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Last name:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Phone number:
        </th>
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Suspended from:
        </th>
      </tr>

      {suspendedEmployee &&
        suspendedEmployee.suspendedUsers &&
        suspendedEmployee.suspendedUsers.map((user) => (
          <TableRow key={user._id} employee={user} enableUser={enableUser} />
        ))}
    </tbody>
  </table>
);

const TableRow = ({ employee, enableUser }) => (
  <tr className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer">
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {employee.firstName}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {employee.lastName}
    </td>
    <td className=" hidden md:table-cell pr-3 py-3 text-sm text-center text-[#ececec]">
      {employee.phoneNumber}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      <Moment format="DD-MM-YYYY">{employee.updatedAt}</Moment>
    </td>
    <td>
      <Button
        text="Enable"
        onClick={(event) => enableUser(event, employee._id)}
      />
    </td>
  </tr>
);

const SuspendedUsers = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const { isLoading, isSuccess, message, isError, suspendedUsers } =
    useSelector((state) => state.team);

  useEffect(() => {
    dispatch(getDisabledUsers(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (isSuccess) {
      successToast(message);
      dispatch(getDisabledUsers(currentPage));
    }

    if (isError) {
      errToast(message);
    }
  }, [isSuccess, currentPage, message, dispatch, isError]);

  const lastReportIndex = currentPage * rowsPerPage;
  const firstReportIndex = lastReportIndex - rowsPerPage;

  const handleEnableEmployee = (event, id) => {
    event.preventDefault();
    dispatch(enableSuspendedUser(id));
  };

  return (
    <>
      <div className="">
        <section className="flex items-center flex-col justify-center text-[#ececec]">
          <div className="text-2xl my-3 text-[#ff5783] uppercase">
            <h3>Suspended users</h3>
          </div>

          {isLoading ? (
            <SuspendedSkeleton />
          ) : (
            <UsersTable
              suspendedEmployee={suspendedUsers}
              enableUser={handleEnableEmployee}
            />
          )}
          <Pagination
            totalReports={suspendedUsers.totalSuspendedUsers}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </section>
      </div>
      <ToastContainer />
    </>
  );
};

export default SuspendedUsers;
