import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// create new service car report
const createServiceCarReport = async (data, token) => {
  //Get token
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/home/serviceCar/newReport`,
    data,
    config
  );

  return response.data;
};

// GET service vehicles reports
const getServiceReports = async (filter, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: filter.page,
      licensePlate: filter.licensePlate,
      date: filter.date,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/serviceCar/allReports`,
    config
  );

  return response.data;
};

// GET service vehicle report by ID
const getSingleServiceReport = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/serviceCar/report/${id}`,
    config
  );

  return response.data;
};

// DELETE service vehicle report
const deleteServiceCarReport = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${API_LINK}/api/home/serviceCar/delete/${id}`,
    config
  );

  return response.data;
};

const serviceCarReportService = {
  createServiceCarReport,
  getServiceReports,
  getSingleServiceReport,
  deleteServiceCarReport,
};

export default serviceCarReportService;
