import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getSingleIssue,
  updateIssue,
} from "../../../Features/deilibilarIssues/issuesSlice";
import Moment from "react-moment";
import Button from "../../UI/button/Button";
import Spinner from "../../Spinner/Spinner";
import LicensePlate from "../../LicensePlate/LicensePlate";
import Modal from "../../Modal/Modal";

const DailyCarIssueForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [resolveModal, setResolveModal] = useState(false);

  const { id } = useParams();

  const { singleIssue, isLoading } = useSelector(
    (state) => state.deilibilarIssues
  );

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSingleIssue(id));
  }, [dispatch, id]);

  const resolveIssue = (e) => {
    e.preventDefault();
    dispatch(updateIssue(id));
    setResolveModal(false);
  };

  const openResolveModal = () => {
    setResolveModal(true);
  };

  const handleEdit = () => {
    navigate(`/dashboard/allFleet/deilibilar/updateIssue/${id}`);
  };

  const handleNavigation = (id) => {
    navigate(`/home/deilibilar/allIssues/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <section className="">
            <div className="flex flex-col md:justify-evenly">
              {singleIssue && singleIssue.deilibilarId && (
                <div className="flex items-center justify-center flex-col">
                  <h3 className="uppercase text-[#ff5783] font-semibold my-2">
                    deilibilar info
                  </h3>
                  <img
                    className="px-3 py-3 md:w-64 hover:cursor-pointer"
                    src={
                      singleIssue &&
                      singleIssue.deilibilarId &&
                      singleIssue.deilibilarId.image &&
                      singleIssue.deilibilarId.image.map((item) => item.url)
                    }
                    alt="Deilibilar vehicle"
                    onClick={() =>
                      handleNavigation(singleIssue.deilibilarId._id)
                    }
                  />
                  <ul className="py-3">
                    <li className="text-[#ececec] my-2">
                      <span className="mx-2">Brand: </span>
                      {singleIssue.deilibilarId.brand}
                    </li>
                    <li className="text-[#ececec] my-2">
                      <LicensePlate
                        plateNumber={singleIssue.deilibilarId.licensePlate}
                      />
                    </li>
                    <li className="text-[#ececec] my-2">
                      <span className="mx-2">Mileage: </span>
                      {singleIssue.deilibilarId.mileage} km
                    </li>
                    <li className="text-[#ececec] my-2">
                      <span className="mx-2">Status: </span>
                      {singleIssue.deilibilarId.status}
                    </li>
                  </ul>
                </div>
              )}
              {singleIssue && (
                <div className="flex items-center justify-center flex-col my-2">
                  <h3 className="uppercase text-[#ff5783] font-semibold">
                    Accident information
                  </h3>
                  <ul>
                    <li className="text-[#ececec] my-2">
                      <span className="mx-2">Created at:</span>
                      <Moment format="DD-MM-YYYY HH:mm">
                        {singleIssue.createdAt}
                      </Moment>
                    </li>
                    <li className="text-[#ececec] my-2">
                      <span className="mx-2">Issue type:</span>
                      {singleIssue.issueType}
                    </li>
                    <li className="text-[#ececec] my-2">
                      <span className="mx-2">Description:</span>
                      {singleIssue.description}
                    </li>
                    <li className="text-[#ececec]">
                      <span className="mx-2">Reported by:</span>
                      {singleIssue.userCreated &&
                        singleIssue.userCreated.firstName}{" "}
                      {singleIssue.userCreated &&
                        singleIssue.userCreated.lastName}
                    </li>
                  </ul>

                  {singleIssue &&
                  singleIssue.image &&
                  singleIssue.image.length > 0
                    ? singleIssue.image.map((item, index) => (
                        <img
                          key={index}
                          src={item.url}
                          alt="Deilibilar damage"
                          className="px-3 py-3 hover:cursor-zoom-in md:w-96"
                          onClick={() => window.open(item.url, "_blank")}
                        />
                      ))
                    : null}
                </div>
              )}
            </div>
            {singleIssue && user.role === "Admin" && (
              <div className=" flex items-center justify-center flex-col">
                <h3 className="uppercase text-[#ff5783] font-semibold my-2">
                  additional information
                </h3>

                <ul>
                  <li className="text-[#ececec] my-2">
                    <span className="mx-2">Name:</span>
                    {singleIssue.additionalInformation
                      ? singleIssue.additionalInformation.name
                      : "No information"}
                  </li>
                  <li className="text-[#ececec] my-2">
                    <span className="mx-2">E-mail:</span>
                    {singleIssue.additionalInformation
                      ? singleIssue.additionalInformation.email
                      : "No information"}
                  </li>
                  <li className="text-[#ececec] my-2">
                    <span className="mx-2">Phone number:</span>
                    {singleIssue.additionalInformation
                      ? singleIssue.additionalInformation.phone
                      : "No information"}
                  </li>
                  <li className="text-[#ececec] my-2">
                    <span className="mx-2">Kennitala:</span>
                    {singleIssue.additionalInformation
                      ? singleIssue.additionalInformation.kennitala
                      : "No information"}
                  </li>
                  <li className="text-[#ececec] my-2">
                    <span className="mx-2">Document number:</span>
                    {singleIssue.additionalInformation
                      ? singleIssue.additionalInformation.documentNumber
                      : "No information"}
                  </li>
                  <li className="text-[#ececec] my-2">
                    <span className="mx-2">Location:</span>
                    {singleIssue.additionalInformation
                      ? singleIssue.additionalInformation.location
                      : "No information"}
                  </li>
                  <li className="text-[#ececec] my-2">
                    <span className="mx-2">Description:</span>
                    {singleIssue.additionalInformation
                      ? singleIssue.additionalInformation.description
                      : "No information"}
                  </li>
                  <li className="text-[#ececec] my-2 flex items-center justify-center flex-col">
                    <span className="mx-2">Extra pictures:</span>
                    {singleIssue.additionalInformation &&
                    singleIssue.additionalInformation.images
                      ? singleIssue.additionalInformation.images.map((item) => (
                          <Link
                            to={{ pathname: `${item.url}` }}
                            target="_blank"
                          >
                            <img
                              className="md:w-32 w-64 mx-1 pt-3"
                              alt="deilibilar issue"
                              src={item.url}
                              key={item.public_id}
                            />
                          </Link>
                        ))
                      : null}
                  </li>
                </ul>
              </div>
            )}
          </section>
          {user.role === "Admin" && (
            <section className="flex items-center justify-center my-4">
              <Button type="button" text="Edit" onClick={handleEdit} />
              <Button
                type="button"
                text="Fix issue"
                onClick={() => openResolveModal(id)}
              />
            </section>
          )}
        </div>
      )}

      {resolveModal && (
        <Modal
          message="Are you sure you want to resolve this issue ?"
          onClick={resolveIssue}
          setOpenModal={setResolveModal}
        />
      )}
    </>
  );
};

export default DailyCarIssueForm;
