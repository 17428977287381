import DailyCarProfile from "../../Components/DailyCar/DailyCarProfile/DailyCarProfile";
import DailyCarTabs from "../../Components/DailyCar/DailyCarTabs/DailyCarTabs";

const DailyCarPage = () => {
  return (
    <>
      <section>
        <div className="flex items-center flex-col justify-center">
          <DailyCarProfile />
        </div>
        <DailyCarTabs />
      </section>
    </>
  );
};

export default DailyCarPage;
