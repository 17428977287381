import { BsFillChatLeftTextFill, BsPower } from "react-icons/bs";
import Moment from "react-moment";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAnnouncements } from "../../../Features/announcements/announcementSlice";
import Spinner from "../../Spinner/Spinner";

import MessageModal from "../MessageModal/MessageModal";
import DisableModal from "../DisableModal/DisableModal";

const AnnouncementTable = () => {
  const [messageModal, setMessageModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const [announcementId, setAnnouncementId] = useState("");

  const [annMessage, setAnnMessage] = useState("");

  const dispatch = useDispatch();

  const { activeAnnouncements, isLoading } = useSelector(
    (state) => state.announcement
  );

  useEffect(() => {
    dispatch(getAnnouncements());
  }, [dispatch]);

  const viewMessage = (message) => {
    setAnnMessage(message);
    setMessageModal(true);
  };

  const disableAnnouncement = (id) => {
    setAnnouncementId(id);
    setDisableModal(true);
  };

  return (
    <>
      <section className="flex items-center justify-center my-4">
        <table>
          <tbody>
            <tr className="text-center ">
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Category:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Message:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2  text-center pb-2">
                Date created:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3  mx-2 text-center pb-2">
                Created by:
              </th>
            </tr>

            {isLoading ? (
              <Spinner />
            ) : (
              activeAnnouncements &&
              activeAnnouncements.map((item, index) => (
                <tr className="border-b border-[#1ce5be]" key={index}>
                  <td
                    className={
                      item.category === "High"
                        ? "pr-3 py-3 text-md text-center text-[#e74c3c]"
                        : item.category === "Medium"
                        ? "pr-3 py-3 text-md text-center text-[#f1c40f]"
                        : "pr-3 py-3 text-md text-center text-[#457FE5]"
                    }
                  >
                    {item.category}
                  </td>
                  <td className="pr-3 py-3 text-sm text-center flex justify-center items-center text-[#ececec]">
                    <BsFillChatLeftTextFill
                      className="text-[#ececec] text-2xl"
                      onClick={() => viewMessage(item.message)}
                    />
                  </td>
                  <td className="pr-3 py-3 text-md text-center text-[#ececec]">
                    <Moment format="DD-MM-YYYY">{item.createdAt}</Moment>
                  </td>
                  <td className="pr-3 py-3 text-md text-center text-[#ececec]">
                    {item.userCreated.firstName}
                  </td>
                  <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
                    <BsPower
                      className="text-[#FF5783] text-2xl"
                      onClick={() => disableAnnouncement(item._id)}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </section>

      {messageModal && (
        <MessageModal
          setOpenModal={setMessageModal}
          message={annMessage}
          setMessage={setAnnMessage}
        />
      )}
      {disableModal && (
        <DisableModal setOpenModal={setDisableModal} id={announcementId} />
      )}
    </>
  );
};

export default AnnouncementTable;
