import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// create new fixer report
const createFixerReport = async (reportData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/home/newFixerReport`,
    reportData,
    config
  );

  return response.data;
};

// get all fixer reports
const getAllFixerReports = async (filter, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: filter.page,
  };

  const response = await axios.get(
    `${API_LINK}/api/home/fixerReport/all`,
    config
  );

  return response.data;
};

// get fixer report by ID
const getFixerReport = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/fixerReport/${id}`,
    config
  );

  return response.data;
};

const fixerReportService = {
  createFixerReport,
  getAllFixerReports,
  getFixerReport,
};

export default fixerReportService;
