import AllHoppers from "../../Components/Tables/LargeTables/Hoppers/AllHoppers";

const Hoppers = () => {
  return (
    <>
      <section className="flex items-center flex-col justify-center text-[#ececec]">
        <div className="text-2xl my-3 text-[#ff5783] uppercase">
          <h3>Hoppers</h3>
        </div>
        <AllHoppers />
      </section>
    </>
  );
};

export default Hoppers;
