import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, reset } from "../../Features/Auth/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../Components/Spinner/Spinner";
import Button from "../../Components/UI/button/Button";
import Input from "../../Components/UI/input/Input";

const loginImage = require("../../Assets/Images/login-image.jpg");
const hoppLogo = require("../../Assets/Images/logo.jpg");

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const notify = (message) =>
  toast.warn(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      errToast(message);
      dispatch(reset());
    }

    if (isSuccess || user) {
      if (user?.role === "Hopper") {
        navigate("/index");
      } else {
        navigate("/home");
      }
      dispatch(reset());
    }

    if (!user) {
      navigate("/");
    }
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    if (email === "" || password === "") {
      return notify("Please enter your email and password !");
    }

    return dispatch(login(userData));
  };

  if (isError) {
    return errToast(message);
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <section className="h-screen w-screen flex items-center justify-center">
        <div className="w-full flex items-center justify-center">
          <div className="w-1/2 mr-2 py-5 flex items-center flex-col">
            <div className="">
              <img src={hoppLogo} className="h-16 rounded-md" alt="Hopp Logo" />
            </div>

            <h4 className="text-2xl py-3 text-[#ff5783] uppercase">Log In</h4>

            <form onSubmit={onSubmit}>
              <Input
                label="Email"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={onChange}
                autoComplete="off"
              />

              <Input
                label="Password"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={onChange}
              />

              <div className="flex items-center justify-center my-4">
                <Button type="submit" text="Login" />
              </div>
            </form>
          </div>

          <div className="w-1/2 mx-3 py-7 items-center justify-center hidden md:block">
            <img src={loginImage} className="w-80 h-95" alt="Hopp background" />
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default Login;
