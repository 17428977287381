import { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getSingleReport,
  deleteServiceReport,
} from "../../../Features/serviceCarReport/serviceCarReportSlice";
import Spinner from "../../Spinner/Spinner";
import { HiX, HiArrowLeft, HiArrowRight, HiCheck } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LicensePlate from "../../LicensePlate/LicensePlate";
import Button from "../../UI/button/Button";
import Modal from "../../Modal/Modal";

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const ServiceReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { singleReport, isLoading } = useSelector(
    (state) => state.serviceReport
  );
  const { user } = useSelector((state) => state.auth);

  const [deleteModal, setDeleteModal] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  const openDeleteModal = (e, id) => {
    e.stopPropagation();
    setDeleteModal(true);
  };

  const deleteReport = (e) => {
    setDeleteModal(false);

    dispatch(deleteServiceReport(id))
      .then(() => {
        successToast("Service vehicle report deleted successfully!");
        navigate("/dashboard/serviceReports");
      })
      .catch((error) => {
        errToast("Something went wrong!");
        console.log(error);
      });
  };

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(singleReport.images.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === singleReport.images.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  useEffect(() => {
    dispatch(getSingleReport(id));
  }, [dispatch, id]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {openModal && (
        <div className="fixed flex items-center justify-center w-full h-full top-0 bottom-0 left-0 right-0 bg-black/40 border-[#ececec] z-40">
          <HiX
            className="absolute text-3xl text-[#ff5783]/30 hover:text-[#ff5783] top-3 right-3 cursor-pointer"
            onClick={handleCloseModal}
          />
          <HiArrowLeft
            className="absolute text-4xl text-[#ff5783]/30 hover:text-[#ff5783] inset-y-50 left-5 cursor-pointer"
            onClick={prevSlide}
          />
          <HiArrowRight
            className="absolute text-4xl text-[#ff5783]/30 hover:text-[#ff5783] inset-y-50 right-5 cursor-pointer"
            onClick={nextSlide}
          />
          <div className="flex items-start justify-center w-full h-full">
            <img
              alt="Service car"
              className="h-auto w-auto"
              src={singleReport.images[slideNumber].url}
            />
          </div>
        </div>
      )}

      <main className="flex justify-center mt-3 flex-col">
        <section className="flex flex-col items-center my-10 md:mr-10">
          <h2 className="text-[#ff5783] uppercase mb-3 font-bold">
            {singleReport && singleReport.cargoId && singleReport.cargoId.model}
          </h2>
          <LicensePlate
            plateNumber={
              singleReport &&
              singleReport.cargoId &&
              singleReport.cargoId.licensePlate
            }
          />
        </section>

        <section className="flex flex-col justify-center items-center">
          <div className="flex flex-col">
            <span className="text-[#ff5783] text-lg font-semibold">
              Created at:
            </span>
            <Moment className="text-[#ececec] mb-3" format="DD-MM-YYYY HH:mm">
              {singleReport && singleReport.createdAt}
            </Moment>
          </div>

          <div className="flex flex-col">
            <span className="text-[#ff5783] text-lg font-semibold">
              Mileage:
            </span>
            <span className="text-[#ececec] mb-3">
              {singleReport && singleReport.mileage} km
            </span>
          </div>

          <div className="flex flex-col">
            <span className="text-[#ff5783] text-lg font-semibold">
              Employee:
            </span>
            <span className="text-[#ececec] mb-3">
              {singleReport &&
                singleReport.userCreated &&
                singleReport.userCreated.firstName}{" "}
              {singleReport &&
                singleReport.userCreated &&
                singleReport.userCreated.lastName}
            </span>
          </div>

          <div className="flex flex-col">
            <span className="text-[#ff5783] text-lg font-semibold mb-2">
              General checklist:
            </span>
            <div className="flex flex-col mb-3">
              <ul className="text-[#ececec]">
                {singleReport &&
                  singleReport.generalCheck &&
                  singleReport.generalCheck.checklist.map((check, index) => (
                    <li key={index} className="flex mb-1 text-lg items-center">
                      <HiCheck className="text-[#1ce5be] mr-2 mt-1" />

                      {check}
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="flex flex-col mb-10">
            <span className="text-[#ff5783] text-lg font-semibold mb-3">
              Images:
            </span>
            <div className="flex">
              {singleReport &&
                singleReport.images &&
                singleReport.images.map((img, index) => {
                  return (
                    <div
                      className="cursor-pointer flex flex-row"
                      key={index}
                      onClick={() => handleOpenModal(index)}
                    >
                      <img
                        className="w-28 mr-3 rounded-md"
                        src={img.url}
                        alt="report photos"
                      />
                    </div>
                  );
                })}
            </div>
          </div>

          {user.role === "Admin" && (
            <div className="max-w-xs flex items-center justify-center mb-5">
              <Button
                text="Delete"
                type="button"
                onClick={(e) => openDeleteModal(e, id)}
              />
            </div>
          )}
        </section>
      </main>

      {deleteModal && (
        <Modal
          message="Are you sure you want to delete service vehicle report ?"
          onClick={deleteReport}
          setOpenModal={setDeleteModal}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default ServiceReport;
