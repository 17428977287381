import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// create new shift report
const createShiftReport = async (shiftReportData, token) => {
  //Get token
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/home/newShiftReport`,
    shiftReportData,
    config
  );

  return response.data;
};

// GET SHIFT REPORT EMPLOYEE NUMBERS
const getShiftNumbers = async (filter, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      shiftType: filter,
    },
  };

  const response = await axios.get(`${API_LINK}/api/home/hopperStats`, config);

  return response.data;
};

//UPDATE SHIFT REPORT
const updateShiftReport = async (shiftReportData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/home/shiftReport/update/${shiftReportData._id}`,
    shiftReportData,
    config
  );

  return response.data;
};

// GET ALL SHIFT REPORTS
const getAllShiftReports = async (filter, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: filter.page,
      shiftType: filter.shiftType,
      date: filter.date,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/shiftReports/all`,
    config
  );

  return response.data;
};

// GET LATEST SHIFT REPORTS
const getLatestShiftReports = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/shiftReport/latest`,
    config
  );

  return response.data;
};

//GET SHIFT REPORT BY ID
const getSingleShiftReport = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/home/shiftReport/${id}`,
    config
  );

  return response.data;
};

const shiftReportService = {
  createShiftReport,
  getAllShiftReports,
  getLatestShiftReports,
  getSingleShiftReport,
  updateShiftReport,
  getShiftNumbers,
};

export default shiftReportService;
