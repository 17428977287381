import React from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

const ShiftAverage = ({ score }) => {
  return (
    <div className="flex items-center justify-center">
      <span className="text-center mx-1">
        {score >= 80 ? (
          <AiOutlineArrowUp className="text-[#1ce5be] font-medium text-xl" />
        ) : (
          <AiOutlineArrowDown className="text-[#ff5783] font-medium text-xl" />
        )}
      </span>
      <span className="text-[#ececec] text-center text-lg font-medium">
        {score}
      </span>
    </div>
  );
};

export default ShiftAverage;
