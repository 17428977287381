import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleIssue } from "../../../Features/deilibilarIssues/issuesSlice";
import {
  updateAdditionalInfo,
  reset,
} from "../../../Features/deilibilarIssues/infoSlice";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";

import Button from "../../UI/button/Button";
import Input from "../../UI/input/Input";
import TextArea from "../../UI/textArea/TextArea";
import UploadImage from "../../UI/uploadImage/UploadImage";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../Spinner/Spinner";
import { AiOutlineClose } from "react-icons/ai";

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const EditDailyCarIssue = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { singleIssue } = useSelector((state) => state.deilibilarIssues);

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.deilibilarIssueInfo
  );

  const [additionalInfo, setAdditionalInfo] = useState({
    name: "",
    email: "",
    phone: "",
    kennitala: "",
    documentNumber: "",
    location: "",
    description: "",
    images: [],
  });

  const [images, setImages] = useState([]);

  useEffect(() => {
    dispatch(getSingleIssue(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (singleIssue && singleIssue.additionalInformation) {
      setAdditionalInfo(singleIssue.additionalInformation);
    }
  }, [singleIssue, setAdditionalInfo]);

  useEffect(() => {
    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
      console.log(message);
    }
  }, [isSuccess, isError, message, dispatch]);

  const onChange = (e) => {
    setAdditionalInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRemoveImage = (index) => {
    setAdditionalInfo((prevInfo) => {
      const newImages = [...prevInfo.images];
      newImages.splice(index, 1);
      return { ...prevInfo, images: newImages };
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const data = {
      id: id,
      name: additionalInfo.name,
      email: additionalInfo.email,
      phone: additionalInfo.phone,
      kennitala: additionalInfo.kennitala,
      documentNumber: additionalInfo.documentNumber,
      location: additionalInfo.location,
      description: additionalInfo.description,
      images: images,
    };

    dispatch(updateAdditionalInfo(data));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <section className="">
        <div className="flex flex-col md:justify-evenly">
          {singleIssue && singleIssue.deilibilarId && (
            <div className="flex items-center justify-center flex-col">
              <h3 className="uppercase text-[#ff5783] font-semibold my-2">
                deilibilar info
              </h3>
              <img
                className="px-3 py-3 md:w-64"
                src={
                  singleIssue.deilibilarId.image &&
                  singleIssue.deilibilarId.image.map((item) => item.url)
                }
                alt="Deilibilar vehicle"
              />
              <ul className="py-3">
                <li className="text-[#ececec] my-2">
                  <span className="mx-2">Brand: </span>
                  {singleIssue.deilibilarId.brand}
                </li>
                <li className="text-[#ececec] my-2">
                  <span className="mx-2">License plate: </span>
                  {singleIssue.deilibilarId.licensePlate}
                </li>
                <li className="text-[#ececec] my-2">
                  <span className="mx-2">Mileage: </span>
                  {singleIssue.deilibilarId.mileage} km
                </li>
                <li className="text-[#ececec] my-2">
                  <span className="mx-2">Status: </span>
                  {singleIssue.deilibilarId.status}
                </li>
              </ul>
            </div>
          )}

          {singleIssue && (
            <div className="flex items-center justify-center flex-col">
              <h3 className="uppercase text-[#ff5783] font-semibold my-2">
                Accident information
              </h3>
              <ul>
                <li className="text-[#ececec] my-2">
                  <span className="mx-2">Created at:</span>
                  <Moment format="DD-MM-YYYY HH:mm">
                    {singleIssue.createdAt}
                  </Moment>
                </li>
                <li className="text-[#ececec] my-2">
                  <span className="mx-2">Issue type:</span>
                  {singleIssue.issueType}
                </li>
                <li className="text-[#ececec] my-2">
                  <span className="mx-2">Description:</span>
                  {singleIssue.description}
                </li>
                <li className="text-[#ececec]">
                  <span className="mx-2">Reported by:</span>
                  {singleIssue.userCreated &&
                    singleIssue.userCreated.firstName}{" "}
                  {singleIssue.userCreated && singleIssue.userCreated.lastName}
                </li>
              </ul>

              <img
                src={
                  singleIssue.image && singleIssue.image.map((item) => item.url)
                }
                alt="Accident visual representation"
                className="px-3 py-3"
              />
            </div>
          )}
        </div>
        {singleIssue && (
          <div className="flex items-center justify-center flex-col">
            <h3 className="uppercase text-[#ff5783] font-semibold my-2">
              additional information
            </h3>

            <ul>
              <li className="text-[#ececec] my-2">
                <Input
                  label="Name"
                  type="text"
                  id="name"
                  name="name"
                  value={additionalInfo.name}
                  autoComplete="off"
                  placeholder="Jason Borne"
                  onChange={onChange}
                />
              </li>
              <li className="text-[#ececec] my-2">
                <Input
                  label="Email"
                  type="email"
                  id="email"
                  name="email"
                  value={additionalInfo.email}
                  autoComplete="off"
                  placeholder="jason.borne@gmail.com"
                  onChange={onChange}
                />
              </li>
              <li className="text-[#ececec] my-2">
                <Input
                  label="Phone number"
                  type="tel"
                  id="phone"
                  name="phone"
                  value={additionalInfo.phone}
                  autoComplete="off"
                  placeholder="777 5599"
                  onChange={onChange}
                />
              </li>
              <li className="text-[#ececec] my-2">
                <Input
                  label="Kennitala"
                  type="text"
                  id="kennitala"
                  name="kennitala"
                  value={additionalInfo.kennitala}
                  autoComplete="off"
                  placeholder="010187-3333"
                  onChange={onChange}
                />
              </li>
              <li className="text-[#ececec] my-2">
                <Input
                  label="Document number"
                  type="text"
                  id="documentNumber"
                  name="documentNumber"
                  value={additionalInfo.documentNumber}
                  autoComplete="off"
                  placeholder="01232343"
                  onChange={onChange}
                />
              </li>
              <li className="text-[#ececec] my-2">
                <Input
                  label="Location"
                  type="text"
                  id="location"
                  name="location"
                  value={additionalInfo.location}
                  autoComplete="off"
                  placeholder="113 Hringbraut, Reykjavik 101"
                  onChange={onChange}
                />
              </li>
              <li className="text-[#ececec] my-2">
                <TextArea
                  label="Description"
                  aria="description"
                  name="description"
                  value={additionalInfo.description}
                  placeholder="Customer was driving drunk, hit gas station pump. Front of the car is completely destroyed"
                  onChange={onChange}
                />
              </li>
              <li className="text-[#ececec] my-2 flex items-center justify-center flex-col">
                {additionalInfo.images &&
                  additionalInfo.images.length > 0 &&
                  additionalInfo.images.map((item, index) => (
                    <div key={item.public_id} className="relative">
                      <img
                        className="md:w-32 w-48 mx-1 pt-3"
                        alt="deilibilar issue"
                        src={item.url}
                      />
                      <button
                        className="absolute top-0 right-0 bg-[#ff5783] text-[#ececec] rounded-full p-2"
                        onClick={() => {
                          handleRemoveImage(index);
                        }}
                      >
                        <AiOutlineClose className="text-lg font-semibold" />
                      </button>
                    </div>
                  ))}
              </li>

              <li className="text-[#ececec] my-2">
                <UploadImage
                  label="Upload photos"
                  type="file"
                  id="file-upload"
                  name="images"
                  multiple="multiple"
                  images={images}
                  setImages={setImages}
                />
              </li>
            </ul>
          </div>
        )}
      </section>
      <section className="flex items-center justify-center my-4">
        <Button type="button" text="Submit" onClick={(e) => submitHandler(e)} />
      </section>
      <ToastContainer />
    </>
  );
};

export default EditDailyCarIssue;
