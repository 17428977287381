import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import opDataService from "./opDataService"

const initialState = {
    vehicleStatus: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

export const getScooterStatus = createAsyncThunk(
    "home/vehicleStatus",
    async(_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token
        return await opDataService.getVehicleStatus(token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  )

export const opSlice = createSlice({
    name: "opData",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false
            state.message = ""
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getScooterStatus.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
          })
          .addCase(getScooterStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.vehicleStatus = action.payload;
          })
          .addCase(getScooterStatus.rejected, (state, action) => {
            state.isError = true;
            state.message = action.payload;
          })
    }
})

export const {reset} = opSlice.actions
export default opSlice.reducer