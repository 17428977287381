import logo from "./../../Assets/Images/main-logo.jpg";
import UserDropdown from "../../Components/UserDropdown/UserDropdown";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../Components/SideNav/Sidebar";
import { logout } from "../../Features/Auth/authSlice";
import Announcement from "./Announcement";

export const AdminHeader = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  if (!user.token) {
    return dispatch(logout());
  }

  return (
    <>
      <Announcement />
      <div className="d-flex flex-row justify-content-between align-items-center my-3">
        <div className="logo col-4 d-flex align-items-center">
          <Sidebar />
          <Link to="/home">
            <img src={logo} className="ml-3 w-24 md:w-32" alt="Hopp Logo" />
          </Link>
        </div>

        <UserDropdown />
      </div>
    </>
  );
};

export default AdminHeader;
