const Select = ({ label, area, name, value, onChange, options }) => {
  return (
    <>
      <label className="text-[#ececec] mb-2 mx-2 text-lg">{label}</label>
      <select
        className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 rounded-md text-lg leading-tight"
        aria-label={area}
        name={name}
        value={value}
        required
        onChange={onChange}
      >
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
