import Spinner from "../../Spinner/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCargoIssuesById } from "../../../Features/cargoIssues/cargoIssueSlice";
import IssueTable from "./IssueTable";

const NewIssueBtn = ({ allIssues }) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const { isLoading } = useSelector((state) => state.cargoIssues);

  useEffect(() => {
    dispatch(getCargoIssuesById(id));
  }, [dispatch, id]);

  const createNewIssue = () => {
    navigate(`/home/serviceCars/newIssue/${id}`);
  };

  return (
    <>
      <div className="flex items-center justify-center">
        {user && (user.role === "Admin" || user.role === "Captain") && (
          <button
            className="border-1 px-10 py-2 my-3 rounded-full border-[#1ce5be] text-[#ececec] text-md hover:text-[#ff5783]"
            onClick={() => createNewIssue()}
          >
            New issue
          </button>
        )}
      </div>

      {isLoading ? <Spinner /> : <IssueTable allIssues={allIssues} id={id} />}
    </>
  );
};

export default NewIssueBtn;
