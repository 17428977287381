import { useEffect, useState } from "react";
import Input from "../../Components/UI/input/Input";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../Components/UI/serviceCarDropdown/Dropdown";
import SwitchBtn from "../../Components/UI/switchBtn/SwitchBtn";
import UploadImage from "../../Components/UI/uploadImage/UploadImage";
import { Link } from "react-router-dom";
import Button from "../../Components/UI/button/Button";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";
import {
  createServiceCarReport,
  reset,
} from "../../Features/serviceCarReport/serviceCarReportSlice";
import InfoBanner from "../../Components/UI/infoBanner/InfoBanner";

const notify = (message) =>
  toast.warn(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const ServiceCarReport = () => {
  const dispatch = useDispatch();

  const [licensePlate, setLicensePlate] = useState("");
  const [mileage, setMileage] = useState(0);
  const [comment, setComment] = useState("");

  const [generalCheck, setGeneralCheck] = useState({
    checklist: [],
    response: [],
  });

  const { isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.serviceReport
  );

  const generalChecklistItems = [
    {
      label: "Exterior inspection",
      description: [
        "* Check for any visible damage, dents, or scratches on the vehicle body.",
        "* Ensure that all lights (headlights, brake lights, turn signals) are working properly.",
        "* Mirrors are clean and properly adjusted.",
      ],
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Exterior inspection",
      generalChecklist: generalCheck,
      setGeneralChecklist: setGeneralCheck,
    },
    {
      label: "Tires and Wheels",
      description: [
        "* Check tire pressure and ensure it meets the recommended levels.",
        "* Inspect tires for wear and tear, including tread depth.",
      ],
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Tires and Wheels",
      generalChecklist: generalCheck,
      setGeneralChecklist: setGeneralCheck,
    },
    {
      label: "Brakes",
      description: [
        "* Brakes responsiveness and effectiveness.",
        "* Any unusual noises during braking.",
      ],
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Brakes",
      generalChecklist: generalCheck,
      setGeneralChecklist: setGeneralCheck,
    },
    {
      label: "Interior inspection",
      description: [
        "* Cleanliness of the interior.",
        "* All essential controls (steering, brakes, accelerator) are functioning properly.",
        "* Seatbelts are in good condition and functional.",
      ],
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Interior inspection",
      generalChecklist: generalCheck,
      setGeneralChecklist: setGeneralCheck,
    },
    {
      label: "Windows and Windshield",
      description: [
        "* Check for any cracks or chips in the windshield.",
        "* Window viper condition on rainy conditions",
      ],
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Windows and Windshield",
      generalChecklist: generalCheck,
      setGeneralChecklist: setGeneralCheck,
    },
    {
      label: "Cleanliness",
      description: [
        "* Clean and organized vehicle interior",
        "* No trash leftovers (if found trash at the beginning of your shift inform your CAPTAIN)",
      ],
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Cleanliness",
      generalChecklist: generalCheck,
      setGeneralChecklist: setGeneralCheck,
    },
  ];

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
      dispatch(reset());
    }
  }, [isSuccess, isError, message, dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();

    const serviceCarData = {
      licensePlate,
      mileage,
      generalCheck,
      images,
    };

    if (!licensePlate) {
      return notify("Please select service vehicle!");
    } else if (generalCheck.checklist.length === 0) {
      return notify("Please check at least 2 checkboxes!");
    } else {
      dispatch(createServiceCarReport(serviceCarData));
    }
  };

  const onChange = (e) => {
    setMileage(e.target.value);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="flex items-center flex-col justify-center">
        <h3 className="text-2xl mt-5 text-[#ff5783] font-bold tracking-wider uppercase">
          Service vehicle report
        </h3>

        <InfoBanner />

        <form onSubmit={onSubmit}>
          <Dropdown
            licensePlate={licensePlate}
            setLicensePlate={setLicensePlate}
          />

          <div className="flex flex-col my-4 px-5">
            <Input
              label="Current mileage"
              type="number"
              placeholder="1733"
              name="mileage"
              value={mileage}
              onChange={onChange}
            />
          </div>

          <div className="flex flex-col my-4 px-5">
            <label className="text-[#ececec] mb-2 text-xl">
              General checklist:
            </label>

            {generalChecklistItems.map((item, index) => (
              <SwitchBtn
                key={index}
                label={item.label}
                description={item.description}
                type={item.type}
                id={item.id}
                name={item.name}
                value={item.value}
                generalChecklist={item.generalChecklist}
                setGeneralChecklist={item.setGeneralChecklist}
              />
            ))}
          </div>

          <div className="flex flex-col my-4 px-5">
            <UploadImage
              label="Upload photos"
              type="file"
              id="file-upload"
              name="images"
              multiple="multiple"
              images={images}
              setImages={setImages}
            />
          </div>

          <div className="flex items-center justify-center flex-col mt-10 mb-15">
            <Link to="/home">
              <Button text="Cancel" link="/home" />
            </Link>
            <Button text="Submit" type="submit" />
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default ServiceCarReport;
