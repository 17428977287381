import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TableSkeleton = () => (
  <table>
    <tbody>
      <tr className="text-center">
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          License plate:
        </th>
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Comment:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Make & model:
        </th>
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Employee:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          General checklist:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Service Checklist:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-1 text-center pb-2">
          Created at:
        </th>
      </tr>
      <tr>
        <td>
          <SkeletonTheme
            baseColor="transparent"
            highlightColor="#1ce5be"
            borderRadius="0.5rem"
            duration={2}
          >
            <Skeleton height={25} width={75} count={2} />
          </SkeletonTheme>
        </td>
        <td>
          <SkeletonTheme
            baseColor="transparent"
            highlightColor="#1ce5be"
            borderRadius="0.5rem"
            duration={2}
          >
            <Skeleton height={25} width={75} count={2} />
          </SkeletonTheme>
        </td>
        <td className="hidden md:table-cell">
          <SkeletonTheme
            baseColor="transparent"
            highlightColor="#1ce5be"
            borderRadius="0.5rem"
            duration={2}
          >
            <Skeleton height={25} width={75} count={2} />
          </SkeletonTheme>
        </td>
        <td>
          <SkeletonTheme
            baseColor="transparent"
            highlightColor="#1ce5be"
            borderRadius="0.5rem"
            duration={2}
          >
            <Skeleton height={25} width={75} count={2} />
          </SkeletonTheme>
        </td>
        <td className="hidden md:table-cell">
          <SkeletonTheme
            baseColor="transparent"
            highlightColor="#1ce5be"
            borderRadius="0.5rem"
            duration={2}
          >
            <Skeleton height={25} width={75} count={2} />
          </SkeletonTheme>
        </td>
        <td className="hidden md:table-cell">
          <SkeletonTheme
            baseColor="transparent"
            highlightColor="#1ce5be"
            borderRadius="0.5rem"
            duration={2}
          >
            <Skeleton height={25} width={75} count={2} />
          </SkeletonTheme>
        </td>
        <td>
          <SkeletonTheme
            baseColor="transparent"
            highlightColor="#1ce5be"
            borderRadius="0.5rem"
            duration={2}
          >
            <Skeleton height={25} width={75} count={2} />
          </SkeletonTheme>
        </td>
      </tr>
    </tbody>
  </table>
);

export default TableSkeleton;
