import { FaStar, FaRegStar } from "react-icons/fa";

const Checklist = ({ count, totalStars }) => {
  const emptyStars = Math.max(totalStars - count, 0);

  return (
    <div className="flex items-center justify-center">
      {[...Array(count)].map((_, index) => (
        <FaStar key={index} className="text-[#1ce5be] text-lg font-semibold" />
      ))}
      {[...Array(emptyStars)].map((_, index) => (
        <FaRegStar
          key={index + count}
          className="text-[#ff5783] text-lg font-semibold"
        />
      ))}
    </div>
  );
};

export default Checklist;
