import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../Features/Auth/authSlice";
import accidentReducer from "../Features/accidentReport/accidentSlice";
import carReportReducer from "../Features/carReport/carReportSlice";
import teamReducer from "../Features/team/teamSlice";
import fleetReducer from "../Features/newFleet/fleetSlice";
import cargoReducer from "../Features/newFleet/cargoSlice";
import userProfileReducer from "../Features/userProfile/userProfileSlice";
import deilibilarIssueReducer from "../Features/deilibilarIssues/issuesSlice";
import shiftReportReducer from "../Features/shiftReport/shiftReportSlice";
import barChartReducer from "../Features/Charts/MonthlyStats/BarChartSlice";
import barRangeReducer from "../Features/Charts/DateRangeStats/dateRangeSlice";
import lineChartReducer from "../Features/Charts/LineChart/lineChartSlice";
import cargoIssueReducer from "../Features/cargoIssues/cargoIssueSlice";
import announcementReducer from "../Features/announcements/announcementSlice";
import opDataReducer from "../Features/opHoppData/opDataSlice";
import fixerReducer from "../Features/fixerReport/fixerReportSlice";
import serviceReportReducer from "../Features/serviceCarReport/serviceCarReportSlice";
import deilibilarIssueInfoReducer from "../Features/deilibilarIssues/infoSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    team: teamReducer,
    accident: accidentReducer,
    carReport: carReportReducer,
    shiftReport: shiftReportReducer,
    fleet: fleetReducer,
    cargo: cargoReducer,
    userProfile: userProfileReducer,
    deilibilarIssues: deilibilarIssueReducer,
    deilibilarIssueInfo: deilibilarIssueInfoReducer,
    barChartData: barChartReducer,
    barRangeData: barRangeReducer,
    lineChart: lineChartReducer,
    cargoIssues: cargoIssueReducer,
    announcement: announcementReducer,
    opData: opDataReducer,
    fixerReport: fixerReducer,
    serviceReport: serviceReportReducer,
  },
});
