import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// GET BAR CHART DATA BY NAME
const getBarChartData = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/charts/monthlyStats`,
    config
  );

  return response.data;
};

const barChartService = {
  getBarChartData,
};

export default barChartService;
