import { Outlet } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import { logout } from "../../Features/Auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AdminLayout = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      dispatch(logout());
      navigate("/");
    }
  }, [dispatch, navigate, user]);

  if (!user) {
    return navigate("/");
  }

  if (user && user.role === "Hopper") {
    return navigate("/index");
  }

  return (
    <>
      <header>
        <AdminHeader />
      </header>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default AdminLayout;
