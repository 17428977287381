import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { MdOutlineDashboard, MdAnnouncement } from "react-icons/md";
import { GoBlocked } from "react-icons/go";
import { GiCaptainHatProfile } from "react-icons/gi";
import { AiOutlineUser, AiFillHome, AiOutlineUserAdd } from "react-icons/ai";
import { TbReport } from "react-icons/tb";
import { FaCarAlt, FaTruck, FaCarCrash } from "react-icons/fa";
import logo from "../../Assets/Images/main-logo.jpg";
import "./styles.css";

const SidebarNav = styled.nav`
  background: #1f2022;
  border-right: 1px ridge #f7427f;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 100ms;
  z-index: 10;

  &:hover {
    color: #00e9bc;
    cursor: pointer;
  }
`;

const Sidebar = () => {
  const navigation = [
    { name: "Home", link: "/home", icon: AiFillHome },
    { name: "Dashboard", link: "/dashboard", icon: MdOutlineDashboard },
    {
      name: "Analytics",
      link: "/dashboard/allCharts",
      icon: FaIcons.FaChartLine,
    },
    {
      name: "Announcement",
      link: "/dashboard/announcement",
      icon: MdAnnouncement,
    },
    {
      name: "Captains",
      link: "/dashboard/team/captains",
      icon: GiCaptainHatProfile,
      margin: true,
    },
    {
      name: "Hoppers",
      link: "/dashboard/team/hoppers",
      icon: AiOutlineUser,
    },
    {
      name: "New employee",
      link: "/dashboard/team/create",
      icon: AiOutlineUserAdd,
    },
    {
      name: "Suspended users",
      link: "/dashboard/team/disabled",
      icon: FaIcons.FaShieldAlt,
    },
    {
      name: "Shift reports",
      link: "/dashboard/shiftReports",
      icon: TbReport,
      margin: true,
    },
    {
      name: "Fixer reports",
      link: "/home/fixerReports",
      icon: TbReport,
    },
    {
      name: "Deilibilar reports",
      link: "/home/deilibilarReports",
      icon: TbReport,
    },
    {
      name: "Service vehicle reports",
      link: "/dashboard/serviceReports",
      icon: TbReport,
    },
    {
      name: "Deilibilars",
      link: "/home/allDeilibilars",
      icon: FaCarAlt,
      margin: true,
    },
    {
      name: "New deilibilar",
      link: "/dashboard/allFleet/deilibilar/createNew",
      icon: FaCarAlt,
    },
    {
      name: "Deilibilar issues",
      link: "/dashboard/allFleet/deilibilar/allIssues",
      icon: FaCarCrash,
      margin: true,
    },
    {
      name: "Service cars",
      link: "/home/serviceCars",
      icon: FaTruck,
      margin: true,
    },
    {
      name: "New service car",
      link: "/dashboard/allFleet/cargoVehicles/createNew",
      icon: FaTruck,
    },
  ];

  const [sidebar, setSidebar] = useState(false);

  const handleClick = () => {
    setSidebar(!sidebar);
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#ececec" }}>
        <div className="bg-[##1f2022] border-r-2 border-[#f7427f] h-20 flex justify-start items-center">
          <div
            className="ml-4 mr-4 text-2xl h-20 font-semibold flex items-center justify-end"
            onClick={handleClick}
          >
            <FaIcons.FaBars />
          </div>
        </div>

        <SidebarNav sidebar={sidebar} className="scrollbar">
          <div className="w-full" onClick={handleClick}>
            <div className="">
              <div
                className="mt-2 mr-5 text-2xl h-10 flex items-center justify-end"
                onClick={handleClick}
              >
                <AiIcons.AiOutlineClose />
              </div>
            </div>
            <div className="flex justify-center items-center mb-2">
              <img
                src={logo}
                className="lg:w-32 lg:my-4 w-24"
                alt="Hopp Logo"
              />
            </div>
            <div className="flex flex-col gap-2 relative">
              {navigation?.map((menu, i) => (
                <Link
                  to={menu?.link}
                  key={i}
                  className={` ${
                    menu?.margin && "mt-3"
                  } group flex items-center text-md text-[#ececec] gap-3.5 font-medium p-2 rounded-md`}
                >
                  <div>
                    {React.createElement(menu?.icon, {
                      size: "25",
                    })}
                  </div>
                  <h2
                    style={{
                      transitionDelay: `${i + 3}00ms`,
                    }}
                    className={`whitespace-pre duration-500 ${
                      !sidebar && "opacity-0 translate-x-28 overflow-hidden"
                    }`}
                  >
                    {menu?.name}
                  </h2>
                  <h2
                    className={`${
                      sidebar && "hidden"
                    } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                  >
                    {menu?.name}
                  </h2>
                </Link>
              ))}
            </div>
          </div>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
