import Moment from "react-moment";
import { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllServiceReports } from "../../../../Features/serviceCarReport/serviceCarReportSlice";
import { getCargos } from "../../../../Features/newFleet/cargoSlice";

import Spinner from "../../../Spinner/Spinner";
import Pagination from "../../../Pagination/Pagination";
import Checklist from "../../../Checklist/Checklist";

const ReportTable = ({
  serviceReports,
  handleRowClick,
  setLicensePlate,
  setDate,
  filteredReports,
  allCargos,
}) => (
  <table>
    <tbody>
      <tr>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          <select
            className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 rounded-md text-md leading-tight"
            name="licensePlate"
            onChange={(e) => setLicensePlate(e.target.value)}
          >
            <option value="">-- Select --</option>
            {allCargos &&
              allCargos.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.licensePlate}
                </option>
              ))}
          </select>
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          Make and model:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          Created by:
        </th>
        <th className=" text-[#1ce5be] hidden md:table-cell text-sm pr-3 mx-2 text-center pb-2">
          General checklist:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          <input
            type="date"
            className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 rounded-md text-sm md:text-md leading-tight"
            onChange={(e) => setDate(e.target.value)}
          />
        </th>
      </tr>
      {serviceReports && serviceReports.data && filteredReports ? (
        serviceReports.data.map((report) => (
          <TableRow
            key={report._id}
            report={report}
            handleRowClick={handleRowClick}
          />
        ))
      ) : (
        <tr>
          <td colSpan="5">No reports available</td>
        </tr>
      )}
    </tbody>
  </table>
);

const TableRow = ({ report, handleRowClick }) => (
  <tr
    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
    onClick={() => handleRowClick(report._id)}
  >
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.licensePlate}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.model}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.firstName}
    </td>
    <td className=" pr-3 py-3 text-sm hidden md:table-cell text-center text-[#ececec]">
      <Checklist count={report.generalChecklistLength} totalStars={6} />
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      <Moment format="DD-MM-YYYY">{report.createdAt}</Moment>
    </td>
  </tr>
);

const AllServiceCarReportTable = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleRowClick = (id) => {
    window.open(`/dashboard/serviceReports/${id}`, "_blank");
  };

  const { allReports, isLoading } = useSelector((state) => state.serviceReport);

  const { allCargos } = useSelector((state) => state.cargo);

  let filteredReports = allReports.data;

  const [licensePlate, setLicenseplate] = useState(null);
  const [date, setDate] = useState(null);

  useEffect(() => {
    const filter = {
      page: currentPage,
    };

    if (date) {
      filter.date = date;
    }

    if (licensePlate) {
      filter.licensePlate = licensePlate;
    }

    dispatch(getAllServiceReports(filter));

    dispatch(getCargos());
  }, [dispatch, currentPage, date, licensePlate]);

  const lastReportIndex = currentPage * rowsPerPage;
  const firstReportIndex = lastReportIndex - rowsPerPage;

  return (
    <>
      <div className="">
        <section className="flex items-center flex-col justify-center text-[#ececec]">
          <div className="text-2xl my-3 text-[#ff5783] uppercase">
            <h3>Service vehicle reports</h3>
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <ReportTable
              serviceReports={allReports}
              handleRowClick={handleRowClick}
              setLicensePlate={setLicenseplate}
              setDate={setDate}
              filteredReports={filteredReports}
              allCargos={allCargos}
            />
          )}
          <Pagination
            totalReports={allReports.totalServiceReports}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </section>
      </div>
    </>
  );
};

export default AllServiceCarReportTable;
