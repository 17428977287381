import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// Create employee
const createEmployee = async (employeeData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/dashboard/team/createEmployee`,
    employeeData,
    config
  );

  return response.data;
};

// GET EMPLOYEE from OP
const getOpUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_LINK}/api/home/allUsers`, config);

  return response.data;
};

// GET EMPLOYEE BY ID
const getEmployeeProfile = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/team/${id}`,
    config
  );

  return response.data;
};

// Promote employee, send invitation link
const inviteEmployee = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/promote/sendInvite`,
    userData,
    config
  );

  return response.data;
};

// RESET PASSWORD FOR EMPLOYEE
const resetEmployeePassword = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/promote/resetPassword`,
    userData,
    config
  );

  return response.data;
};

// DISABLE EMPLOYEE
const updateEmployeeStatus = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/dashboard/team/updateStatus/${id}`,
    id,
    config
  );

  return response.data;
};

//GET Hoppers
const getHoppers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/team/hoppers`,
    config
  );

  return response.data;
};

//GET Captains
const getCaptains = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/team/captains`,
    config
  );

  return response.data;
};

// GET CHARGER PHONES
const getChargerPhones = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/team/chargers`,
    config
  );

  return response.data;
};

//  GET SUSPENDED USERS
const getSuspendedUsers = async (page, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: page,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/suspended`,
    config
  );

  return response.data;
};

// SET SUSPENDED USER TO ACTIVE
const enableUser = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/dashboard/team/enableUser/${id}`,
    id,
    config
  );

  return response.data;
};

const teamService = {
  getCaptains,
  getHoppers,
  getChargerPhones,
  createEmployee,
  getEmployeeProfile,
  updateEmployeeStatus,
  inviteEmployee,
  resetEmployeePassword,
  getOpUsers,
  getSuspendedUsers,
  enableUser,
};

export default teamService;
