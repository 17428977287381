import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { getAllIssues } from "../../../../Features/deilibilarIssues/issuesSlice";

import Spinner from "../../../Spinner/Spinner";
import Pagination from "../../../Pagination/Pagination";

const IssueTable = ({ issues, handleRowClick }) => (
  <table>
    <tbody>
      <tr className="text-center">
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          License plate:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          QR code:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          Reported by:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          Date created:
        </th>
      </tr>
      {issues &&
        issues.data &&
        issues.data.map((item) => (
          <TableRow
            key={item._id}
            issue={item}
            handleRowClick={handleRowClick}
          />
        ))}
    </tbody>
  </table>
);

const TableRow = ({ issue, handleRowClick }) => (
  <tr
    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
    onClick={() => handleRowClick(issue._id)}
  >
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {issue.licensePlate}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      #{issue.qrCode}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {issue.firstName} {issue.lastName}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      <Moment format="DD-MM-YYYY HH:mm">{issue.createdAt}</Moment>
    </td>
  </tr>
);

const DeilibilarIssues = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleRowClick = (id) => {
    navigate(`/dashboard/allFleet/deilibilar/allIssues/${id}`);
  };

  const { allIssues, isLoading } = useSelector(
    (state) => state.deilibilarIssues
  );

  useEffect(() => {
    dispatch(getAllIssues(currentPage));
  }, [dispatch, currentPage]);

  const lastReportIndex = currentPage * rowsPerPage;
  const firstReportIndex = lastReportIndex - rowsPerPage;

  return (
    <>
      <div className="">
        <section className="flex items-center flex-col justify-center text-[#ececec]">
          <div className="text-2xl my-3 text-[#ff5783] uppercase">
            <h3>Deilibilar issues</h3>
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <IssueTable issues={allIssues} handleRowClick={handleRowClick} />
          )}
          <Pagination
            totalReports={allIssues.totalFleetIssues}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </section>
      </div>
    </>
  );
};

export default DeilibilarIssues;
