import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCargos } from "../../../Features/newFleet/cargoSlice";

const Dropdown = ({ licensePlate, setLicensePlate }) => {
  const dispatch = useDispatch();

  const { allCargos } = useSelector((state) => state.cargo);

  useEffect(() => {
    dispatch(getCargos());
  }, [dispatch]);

  return (
    <>
      <div className="flex flex-col my-4 px-5">
        <label className="text-[#ececec] mb-2 mx-2 text-lg">
          Select service car license plate:
        </label>
        <select
          className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 rounded-md text-lg leading-tight"
          name="licensePlate"
          value={licensePlate}
          required
          onChange={(e) => {
            setLicensePlate(e.target.value);
          }}
        >
          <option value="">-- Select --</option>
          {allCargos.map((item, index) => (
            <option key={index} value={item._id}>
              {item.licensePlate}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default Dropdown;
