import AllDailyCars from "../../Components/Fleet/DailyCars/AllDailyCars";

const DailyCars = () => {
  return (
    <>
      <section className="flex items-center flex-col justify-center">
        <div className="text-2xl my-3 text-[#ff5783] uppercase">
          <h3>Deilibilars</h3>
        </div>

        <div>
          <AllDailyCars />
        </div>
      </section>
    </>
  );
};

export default DailyCars;
