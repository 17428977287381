import { useEffect, useState } from "react";
import Moment from "react-moment";
import {
  getDeilibilarProfile,
  updateDeilibilar,
  reset,
} from "../../../Features/newFleet/fleetSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import { HiPlus } from "react-icons/hi";
import Button from "../../UI/button/Button";
import Modal from "../../Modal/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LicensePlate from "../../LicensePlate/LicensePlate";

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const DailyCarProfile = () => {
  const [disableModal, setDisableModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fleetInfo, isSuccess, isError, message, isLoading } = useSelector(
    (state) => state.fleet
  );

  const { user } = useSelector((state) => state.auth);

  const { id } = useParams();

  const handleEdit = () => {
    navigate(`/dashboard/allFleet/deilibilar/update/${id}`);
  };

  const handleDisableModal = () => {
    setDisableModal(true);
  };

  const disableDeilibilar = () => {
    dispatch(updateDeilibilar(id));
    setDisableModal(false);
  };

  useEffect(() => {
    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
    }

    dispatch(getDeilibilarProfile(id));
  }, [isSuccess, isError, message, dispatch, id]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <main className="flex md:justify-center md:items-center flex-col md:flex-col mt-3">
          <section className="flex md:flex-row flex-col">
            <section className="flex flex-col items-center md:mr-5">
              <h1 className="text-[#ff5783] text-xl uppercase mb-3 font-bold">
                {fleetInfo.brand} {fleetInfo.model}
              </h1>
              <img
                className="w-60 rounded-md object-cover"
                src={fleetInfo.image ? fleetInfo.image[0].url : null}
                alt={fleetInfo.make}
              />

              <div className="mt-5">
                <LicensePlate plateNumber={fleetInfo.licensePlate} />
              </div>
              <span className="text-[#ff5783] text-lg mt-4 font-semibold">
                QR code:
              </span>
              <span className="text-[#ececec] text-lg mt-2">
                #{fleetInfo.qrCode}
              </span>
            </section>
            <section className="flex flex-col md:items-start items-center justify-center mt-4">
              <div className="flex md:flex-col">
                <span className="text-[#ff5783] text-lg mr-2 font-semibold">
                  Created at:
                </span>
                <Moment
                  className="text-[#ececec] mb-3"
                  format="DD-MM-YYYY HH:mm"
                >
                  {fleetInfo.createdAt}
                </Moment>
              </div>

              <div className="flex md:flex-col">
                <span className="text-[#ff5783] text-lg font-semibold mr-2">
                  Mileage:
                </span>
                <span className="text-[#ececec] mb-3">
                  {fleetInfo.mileage} km
                </span>
              </div>

              {fleetInfo.userCreated ? (
                <div className="flex md:flex-col">
                  <span className="text-[#ff5783] text-lg mr-2 font-semibold">
                    User created:
                  </span>
                  <span className="text-[#ececec] mb-3">
                    {fleetInfo.userCreated?.firstName}{" "}
                    {fleetInfo.userCreated?.lastName}
                  </span>
                </div>
              ) : null}

              {fleetInfo.generalCheck?.checklist.length > 0 && (
                <div className="flex md:flex-col">
                  <span className="text-[#ff5783] text-lg font-semibold mr-2 mb-2">
                    Comes with:
                  </span>
                  <div className="flex flex-col mb-3">
                    <ul className="text-[#ececec]">
                      {fleetInfo.generalCheck?.checklist
                        ? fleetInfo.generalCheck?.checklist?.map(
                            (check, index) => (
                              <li
                                key={index}
                                className="flex mb-1 text-lg items-center"
                              >
                                <HiPlus className="text-[#1ce5be] text-lg mr-2 mt-1" />
                                {check}
                              </li>
                            )
                          )
                        : null}
                    </ul>
                  </div>
                </div>
              )}
            </section>
          </section>
          {user.role === "Admin" ? (
            <section className="my-5 flex items-center justify-center">
              <Button text="Edit" type="button" onClick={handleEdit} />
              <Button
                text="Delete"
                type="button"
                onClick={handleDisableModal}
              />
            </section>
          ) : null}

          {disableModal && (
            <Modal
              setOpenModal={setDisableModal}
              message="Are you sure you want to delete deilibilar?"
              onClick={disableDeilibilar}
            />
          )}
        </main>
      )}
    </>
  );
};

export default DailyCarProfile;
