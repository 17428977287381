import { useState } from "react";
import EmployeeDropdown from "./EmployeeDropdown";
import CargoMultiselect from "./components/CargoMultiselect/CargoMultiselect";
import DistrictMultiSelect from "./components/DistrictMultiselect/DistrictMultiSelect";
import { AiOutlinePlus } from "react-icons/ai";
import ShiftInput from "../../UI/shiftInput/ShiftInput";
import Input from "../../UI/input/Input";
import ChargerMultiselect from "./components/ChargerMultiselect/ChargerMultiselect";

const ShiftReportAccordion = ({
  report,
  setReport,
  onSubmit,
  shiftNumbers,
}) => {
  const [formVal, setFormVal] = useState([
    {
      hopper: 0,
      district: [],
      cargos: [],
      batteries: 0,
      hikes: 0,
      fixed: 0,
      fake: 0,
      leftMtc: 0,
      pickedMtc: 0,
      rebalance: 0,
      winterService: 0,
      deilibilarServiceTime: 0,
      comment: "",
      createdAt: new Date(),
    },
  ]);

  const addRow = (e) => {
    e.preventDefault();
    setFormVal([
      ...formVal,
      {
        hopper: null,
        district: [],
        cargos: [],
        phones: [],
        batteries: 0,
        hikes: 0,
        fixed: 0,
        fake: 0,
        leftMtc: 0,
        pickedMtc: 0,
        rebalance: 0,
        winterService: 0,
        deilibilarServiceTime: 0,
        comment: "",
        createdAt: new Date(),
      },
    ]);
  };

  const [userStats, setUserStats] = useState(null);

  const setDistrict = (e, i) => {
    let newForm = [...formVal];
    newForm[i].district = e;
    setFormVal(newForm);
  };

  const setCargo = (e, i) => {
    let newForm = [...formVal];

    newForm[i].cargos = e;
    setFormVal(newForm);
  };

  const setPhone = (e, i) => {
    let newForm = [...formVal];

    newForm[i].phones = e;
    setFormVal(newForm);
  };

  const onHandle = (e, i) => {
    let newForm = [...formVal];
    newForm[i][e.target.name] = e.target.value;

    setUserStats(shiftNumbers.find((item) => item.staff_id == e.target.value));

    setFormVal(newForm);
    setReport({ ...report, reports: newForm });
  };

  return (
    <div>
      <div className="flex items-center justify-center">
        <h3 className="text-2xl mt-3 text-[#ff5783] uppercase">Shift report</h3>
      </div>
      <div>
        <form onSubmit={onSubmit}>
          <div className="flex items-center justify-center md:justify-start lg:pl-60 flex-wrap">
            {formVal.map((item, i) => (
              <div
                key={i}
                className="lg:m-5 md:flex md:justify-center md:items-center"
              >
                <div
                  className="border-3 lg:px-10 m-3 lg:max-w-md lg:m-5 rounded-md border-[#1ce5be]"
                  style={{
                    padding: "10px",
                    margin: "10px",
                    diplay: "flex",
                    flexDirection: "row",
                  }}
                >
                  <h2 className="uppercase text-[#1ce5be] text-xl m-2">
                    Hopper {i + 1}
                  </h2>
                  <div className="flex items-center py-2 my-4">
                    <label className="text-[#ececec] mb-2 text-lg mx-2">
                      Hopper:
                    </label>
                    <EmployeeDropdown
                      onHandle={onHandle}
                      index={i}
                      shiftNumbers={shiftNumbers}
                    />
                  </div>

                  <div className="flex flex-col justify-start py-2 my-4 mx-3">
                    <label className="text-[#ececec] mb-2 text-lg">
                      Area assigned:
                    </label>

                    <div className="flex my-2">
                      <DistrictMultiSelect
                        onChange={(e) => setDistrict(e, i)}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col justify-start  py-2 my-4 mx-3">
                    <label className="text-[#ececec] mb-2 text-lg">
                      Vehicles used during shift:
                    </label>

                    <div className="flex my-2">
                      <CargoMultiselect onChange={(e) => setCargo(e, i)} />
                    </div>
                  </div>

                  <div className="flex flex-col justify-start  py-2 my-4 mx-3">
                    <label className="text-[#ececec] mb-2 text-lg">
                      Charger phone used:
                    </label>

                    <div className="flex my-2">
                      <ChargerMultiselect onChange={(e) => setPhone(e, i)} />
                    </div>
                  </div>

                  <section className="flex flex-wrap justify-around">
                    <ShiftInput
                      label="Batteries changed:"
                      placeholder={userStats && userStats.batteries_replaced}
                      value={
                        userStats
                          ? userStats.batteries_replaced
                          : item.batteries
                      }
                      name="batteries"
                      onChange={(e) => onHandle(e, i)}
                    />

                    <ShiftInput
                      label="Hopper hikes:"
                      placeholder={5}
                      value={item.hikes}
                      name="hikes"
                      onChange={(e) => onHandle(e, i)}
                    />

                    <ShiftInput
                      label="Scooters fixed on street:"
                      placeholder={15}
                      value={item.fixed}
                      name="fixed"
                      onChange={(e) => onHandle(e, i)}
                    />

                    <ShiftInput
                      label="Good scooters with fake issues:"
                      placeholder={6}
                      value={item.fake}
                      name="fake"
                      onChange={(e) => onHandle(e, i)}
                    />

                    <ShiftInput
                      label="Scooters picked for maintenance:"
                      placeholder={15}
                      value={item.pickedMtc}
                      name="pickedMtc"
                      onChange={(e) => onHandle(e, i)}
                    />

                    <ShiftInput
                      label="Scooters left on street(maintenance):"
                      placeholder={14}
                      value={item.leftMtc}
                      name="leftMtc"
                      onChange={(e) => onHandle(e, i)}
                    />

                    <ShiftInput
                      label="Winter service damage:"
                      placeholder={4}
                      value={item.winterService}
                      name="winterService"
                      onChange={(e) => onHandle(e, i)}
                    />

                    <ShiftInput
                      label="Rebalanced scooters:"
                      placeholder={3}
                      value={item.rebalance}
                      name="rebalance"
                      onChange={(e) => onHandle(e, i)}
                    />

                    <ShiftInput
                      label="Deilibilar service(minutes):"
                      placeholder={37}
                      value={item.deilibilarServiceTime}
                      name="deilibilarServiceTime"
                      onChange={(e) => onHandle(e, i)}
                    />

                    <Input
                      label="Comments"
                      type="text"
                      name="comment"
                      placeholder="Write comment here.."
                      value={item.comment}
                      onChange={(e) => onHandle(e, i)}
                    />
                  </section>
                </div>
              </div>
            ))}
            {report.employeeCount == formVal.length ? null : (
              <div className="border-4 border-dashed border-[#1ce5be] hover:border-[#ff5783] lg:w-48 lg:h-48 w-36 h-36 rounded-md flex items-center justify-center">
                <button
                  onClick={(e) => addRow(e)}
                  className={
                    report.employeeCount === formVal.length
                      ? "border-none hidden items-center w-full h-full text-[#ececec] text-md hover:text-[#ff5783]"
                      : "border-none flex items-center justify-center w-full h-full text-[#ececec] text-md hover:text-[#ff5783]"
                  }
                >
                  <span className="text-[#1ce5be] font-semibold text-4xl hover:text-[#ff5783]">
                    <AiOutlinePlus />
                  </span>
                </button>
              </div>
            )}
          </div>

          {report.employeeCount == formVal.length ? (
            <div className="flex items-center justify-center">
              <button
                className="border-1 px-10 py-2 my-2 rounded-full border-[#1ce5be] text-[#ececec] text-md md:text-xl hover:text-[#ff5783]"
                type="submit"
              >
                Submit
              </button>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default ShiftReportAccordion;
