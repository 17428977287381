import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// CREATE NEW ANNOUNCEMENT
const createNewAnnouncement = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/dashboard/announcement/newAnnouncement`,
    data,
    config
  );

  return response.data;
};

// GET ACTIVE ANNOUNCEMENTS
const getActiveAnnouncements = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/announcement/activeAnnouncement`,
    config
  );

  return response.data;
};

// DISABLE ANNOUNCEMENT
const disableAnnouncement = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/dashboard/announcement/disable/${id}`,
    id,
    config
  );

  return response.data;
};

const announcementService = {
  createNewAnnouncement,
  getActiveAnnouncements,
  disableAnnouncement,
};

export default announcementService;
