import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import teamService from "./teamService";

const initialState = {
  employee: {},
  captain: [],
  allUsers: [],
  hopper: [],
  chargers: [],
  updateEmployee: [],
  suspendedUsers: [],
  userData: [],
  enableUser: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Create user
export const createEmployee = createAsyncThunk(
  "dashboard/team/createEmployee",
  async (employeeData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.createEmployee(employeeData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET ALL OP USERS
export const getAllUsersFromOp = createAsyncThunk(
  "home/allUsers",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.getOpUsers(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET EMPLOYEE BY ID
export const getUserProfile = createAsyncThunk(
  "dashboard/team/:id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.getEmployeeProfile(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Send invitation link to user email
export const promoteEmployee = createAsyncThunk(
  "/promote/sendInvite",
  async (userData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.inviteEmployee(userData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Send reset password link to user
export const resetPassword = createAsyncThunk(
  "/promote/resetPassword",
  async (userData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.resetEmployeePassword(userData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// DISABLE USER
export const updateUser = createAsyncThunk(
  "dashboard/team/updateStatus",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.updateEmployeeStatus(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get Hoppers
export const getHoppers = createAsyncThunk(
  "dashboard/team/hoppers",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.getHoppers(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get Captains
export const getCaptains = createAsyncThunk(
  "dashboard/team/captains",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.getCaptains(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  GET all chargers (charger phone users)
export const getChargers = createAsyncThunk(
  "dashboard/team/chargers",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.getChargerPhones(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  GET SUSPENDED USERS
export const getDisabledUsers = createAsyncThunk(
  "dashboard/team/suspended",
  async (page, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.getSuspendedUsers(page, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//  ENABLE USER
export const enableSuspendedUser = createAsyncThunk(
  "dashboard/team/enableUser/:id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await teamService.enableUser(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.message = action.payload.message;
        state.employee = action.payload;
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employeeProfile = action.payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllUsersFromOp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsersFromOp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allUsers = action.payload;
      })
      .addCase(getAllUsersFromOp.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.updateEmployee.push(action.payload);
        state.message = action.payload.message;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(enableSuspendedUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(enableSuspendedUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.enableUser = action.payload;
        state.message = action.payload.message;

        console.log("Fulfilled payload:", action.payload);
      })
      .addCase(enableSuspendedUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getHoppers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHoppers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hopper = action.payload;
      })
      .addCase(getHoppers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getChargers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getChargers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chargers = action.payload;
      })
      .addCase(getChargers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getCaptains.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCaptains.fulfilled, (state, action) => {
        state.isLoading = false;
        state.captain = action.payload;
      })
      .addCase(getCaptains.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getDisabledUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDisabledUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.suspendedUsers = action.payload;
      })
      .addCase(getDisabledUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })

      .addCase(promoteEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(promoteEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.userData = action.payload;
        state.message = action.payload.message;
      })
      .addCase(promoteEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.userData = action.payload;
        state.message = action.payload.message;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = teamSlice.actions;
export default teamSlice.reducer;
