import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

//create new deilibilar report
const createCarReport = async (carReportData, token) => {
  //Get token
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/home/carReport/create`,
    carReportData,
    config
  );

  return response.data;
};

// DELETE deilibilar report
const deleteCarReport = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${API_LINK}/api/home/carReport/delete/${id}`,
    config
  );

  return response.data;
};

//UPDATE deilibilar mileage
const updateDeilibilarMileage = async (updateData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/home/carReport/updateMileage`,
    updateData,
    config
  );

  return response.data;
};

//GET car reports
const getCarReports = async (page, token) => {
  //Get token
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: page,
    },
  };
  const response = await axios.get(
    `${API_LINK}/api/dashboard/dailyCarReports`,
    config
  );

  return response.data;
};

//GET latest car reports
const getLatestCarReports = async (token) => {
  //Get token
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${API_LINK}/api/dashboard/dailyCarReports/latest`,
    config
  );

  return response.data;
};

//  GET LATEST DEILIBILAR REPORTS BY ID
const getReportsById = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/dailyCarReports/allReports/${id}`,
    config
  );

  return response.data;
};

// GET car reports by ID
const getCarReportById = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/dailyCarReports/${id}`,
    config
  );

  return response.data;
};

const carReportService = {
  createCarReport,
  getCarReports,
  getCarReportById,
  getReportsById,
  updateDeilibilarMileage,
  getLatestCarReports,
  deleteCarReport,
};

export default carReportService;
