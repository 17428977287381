import Moment from "react-moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getCaptains } from "../../../../Features/team/teamSlice";
import Spinner from "../../../Spinner/Spinner";

const AllCaptains = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { captain, isLoading } = useSelector((state) => state.team);

  useEffect(() => {
    dispatch(getCaptains());
  }, [dispatch]);

  const handleRowClick = (id) => {
    navigate(`/dashboard/team/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <table>
          <tbody>
            <tr className="text-center">
              <th className="text-[#1ce5be] hidden md:block text-sm pr-3 mx-2 text-center pb-2">
                Id:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                First Name:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Last Name:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Role:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Date created:
              </th>
            </tr>
            {captain &&
              captain.map((captain, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => handleRowClick(captain._id)}
                    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
                  >
                    <td className="pr-3 py-3 text-center hidden md:block text-[#ececec] ">
                      #00{index}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      {captain.firstName}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      {captain.lastName}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      {captain.role}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      <Moment format="DD-MM-YYYY">{captain.createdAt}</Moment>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default AllCaptains;
