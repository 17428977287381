import Moment from "react-moment";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getHoppers } from "../../../../Features/team/teamSlice";
import Spinner from "../../../Spinner/Spinner";

const AllHoppers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hopper, isLoading } = useSelector((state) => state.team);

  useEffect(() => {
    dispatch(getHoppers());
  }, [dispatch]);

  const handleRowClick = (id) => {
    navigate(`/dashboard/team/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <table>
          <tbody>
            <tr className="text-center">
              <th className="text-[#1ce5be] hidden md:block text-sm pr-3 mx-2 text-center pb-2">
                Id:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                First Name:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Last Name:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Role:
              </th>
              <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                Date created:
              </th>
            </tr>
            {hopper &&
              hopper.map((employee, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => handleRowClick(employee._id)}
                    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
                  >
                    <td className="pr-3 py-3 text-center hidden md:block text-[#ececec] ">
                      #00{index}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      {employee.firstName}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      {employee.lastName}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      {employee.role}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      <Moment format="DD-MM-YYYY">{employee.createdAt}</Moment>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default AllHoppers;
