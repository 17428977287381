import {
  createCarReport,
  updateMileage,
  reset,
} from "../../Features/carReport/carReportSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import Spinner from "../../Components/Spinner/Spinner";
import DeilibilarSearch from "../../Components/UI/deilibilarSearch/DeilibilarSearch";
import Input from "../../Components/UI/input/Input";
import SwitchBtn from "../../Components/UI/switchBtn/SwitchBtn";
import Button from "../../Components/UI/button/Button";
import UploadImage from "../../Components/UI/uploadImage/UploadImage";
import Select from "../../Components/UI/select/Select";
import TextArea from "../../Components/UI/textArea/TextArea";

const notify = (message) =>
  toast.warn(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const DailyCarForm = () => {
  const dispatch = useDispatch();

  const { isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.carReport
  );

  const [licensePlate, setLicensePlate] = useState("");
  const [mileage, setMileage] = useState("");
  const [chargingCable, setChargingCable] = useState("");
  const [comment, setComment] = useState("");

  const [generalCheck, setGeneralCheck] = useState({
    checklist: [],
    response: [],
  });

  const [serviceCheck, setServiceCheck] = useState({
    checklist: [],
    response: [],
  });

  const [images, setImages] = useState([]);

  const generalChecklistItems = [
    {
      label: "Charging cable (ID Buzz)",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Charging cable",
      generalChecklist: generalCheck,
      setGeneralChecklist: setGeneralCheck,
    },
    {
      label: "Car is not dirty",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Car was not dirty",
      generalChecklist: generalCheck,
      setGeneralChecklist: setGeneralCheck,
    },
    {
      label: "No visual damages",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "No visual damages",
      generalChecklist: generalCheck,
      setGeneralChecklist: setGeneralCheck,
    },
  ];

  const serviceChecklistItems = [
    {
      label: "Checked tire condition",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Good tire condition",
      generalChecklist: serviceCheck,
      setGeneralChecklist: setServiceCheck,
    },
    {
      label: "Filled windshield fluid",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Filled windshield fluid",
      generalChecklist: serviceCheck,
      setGeneralChecklist: setServiceCheck,
    },
    {
      label: "Washed outside (Loður)",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Washed outside",
      generalChecklist: serviceCheck,
      setGeneralChecklist: setServiceCheck,
    },
    {
      label: "Cleaned dashboard and all plastic covers",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Cleaned dashboard and plastic covers",
      generalChecklist: serviceCheck,
      setGeneralChecklist: setServiceCheck,
    },
    {
      label: "Cleaned windows inside",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Cleaned windows inside",
      generalChecklist: serviceCheck,
      setGeneralChecklist: setServiceCheck,
    },
    {
      label: "Vacuum inside",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Vacuum inside",
      generalChecklist: serviceCheck,
      setGeneralChecklist: setServiceCheck,
    },
    {
      label: "Washed floor mats",
      type: "checkbox",
      id: "custom-switch",
      name: "generalCheck",
      value: "Washed floor mats",
      generalChecklist: serviceCheck,
      setGeneralChecklist: setServiceCheck,
    },
  ];

  const resetForm = () => {
    setLicensePlate("");
    setMileage("");
    setGeneralCheck({ checklist: [], response: [] });
    setServiceCheck({ checklist: [], response: [] });
    setImages([]);
  };

  const onChange = (e) => {
    setMileage(e.target.value);
  };

  useEffect(() => {
    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
      dispatch(reset());
    }
  }, [isSuccess, isError, message, dispatch]);

  console.log(serviceCheck.checklist);

  const onSubmit = (e) => {
    e.preventDefault();
    if (images.length === 0) {
      notify("Please upload images!");
    } else if (
      serviceCheck &&
      serviceCheck.checklist &&
      !serviceCheck.checklist.includes("Good tire condition")
    ) {
      notify("Please check deilibilar tire condition!");
    } else if (
      serviceCheck &&
      serviceCheck.checklist &&
      !serviceCheck.checklist.includes("Filled windshield fluid")
    ) {
      notify("Please fill up windshield fluid!");
    } else {
      const carReportData = {
        licensePlate,
        mileage,
        generalCheck,
        serviceCheck,
        chargingCable,
        comment,
        images,
      };
      const updateData = {
        licensePlate,
        mileage,
      };

      dispatch(updateMileage(updateData));
      dispatch(createCarReport(carReportData));
      resetForm();
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="flex items-center flex-col justify-center">
        <h3 className="text-2xl mt-5 text-[#ff5783] font-bold tracking-wider uppercase">
          Deilibilar report
        </h3>
        <form className="" onSubmit={onSubmit}>
          <DeilibilarSearch
            licensePlate={licensePlate}
            setLicensePlate={setLicensePlate}
          />

          <div className="flex flex-col my-4 px-5">
            <Select
              label="Charging cable used:"
              area="Charging cable"
              name="chargingCable"
              value={chargingCable}
              options={[
                { name: "Fast Charged", value: "Fast Charged" },
                { name: "0", value: "0" },
                { name: "1", value: "1" },
                { name: "2", value: "2" },
                { name: "3", value: "3" },
                { name: "4", value: "4" },
                { name: "5", value: "5" },
                { name: "6", value: "6" },
                { name: "7", value: "7" },
                { name: "8", value: "8" },
              ]}
              onChange={(e) => setChargingCable(e.target.value)}
            />
          </div>

          <div className="flex flex-col my-4 px-5">
            <Input
              label="Current mileage"
              type="number"
              placeholder="1733"
              name="mileage"
              value={mileage}
              onChange={onChange}
            />
          </div>

          <div className="flex flex-col my-4 px-5">
            <label className="text-[#ececec] mb-2 text-xl">
              General checklist:
            </label>

            {generalChecklistItems.map((item, index) => (
              <SwitchBtn
                key={index}
                label={item.label}
                type={item.type}
                id={item.id}
                name={item.name}
                value={item.value}
                generalChecklist={item.generalChecklist}
                setGeneralChecklist={item.setGeneralChecklist}
              />
            ))}
          </div>

          <div className="flex flex-col my-4 px-5">
            <label className="text-[#ececec] mb-2 text-xl">
              Service checklist:
            </label>

            {serviceChecklistItems.map((item, index) => (
              <SwitchBtn
                key={index}
                label={item.label}
                type={item.type}
                id={item.id}
                name={item.name}
                value={item.value}
                generalChecklist={item.generalChecklist}
                setGeneralChecklist={item.setGeneralChecklist}
              />
            ))}
          </div>

          <div className="flex flex-col my-4 px-5">
            <UploadImage
              label="Upload photos"
              type="file"
              id="file-upload"
              name="images"
              multiple="multiple"
              images={images}
              setImages={setImages}
            />
          </div>

          <div className="flex flex-col my-4 px-5">
            <TextArea
              label="Comment"
              aria="comment"
              name="comment"
              value={comment}
              placeholder="Add additional comments here.."
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <div className="flex items-center justify-center flex-col mt-10 mb-15">
            <Link to="/home">
              <Button text="Cancel" link="/home" />
            </Link>
            <Button text="Submit" type="submit" />
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default DailyCarForm;
