import { useState, useEffect } from "react";
import AnnouncementDropdown from "../AnnouncementSelect/AnnouncementDropdown";
import Button from "../../UI/button/Button";
import {
  newAnnouncement,
  reset,
} from "../../../Features/announcements/announcementSlice";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../Spinner/Spinner";

const notify = (message) =>
  toast.warn(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const AnnouncementForm = () => {
  const [announcement, setAnnouncement] = useState({
    category: "",
    message: "",
  });

  const dispatch = useDispatch();

  const { isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.announcement
  );

  useEffect(() => {
    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }

    if (isError) {
      errToast(message);
      dispatch(reset());
    }
  }, [isSuccess, isError, message, dispatch]);

  const onSubmit = () => {
    if (announcement.message === "") {
      notify("Please provide message!");
    } else {
      dispatch(newAnnouncement(announcement));
    }
  };

  const handleTextareaChange = (e) => {
    setAnnouncement({ ...announcement, message: e.target.value });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="border-1 rounded-md border-[#1ce5be] py-2  px-4 my-3">
        <form onSubmit={onSubmit}>
          <AnnouncementDropdown
            label="Category"
            name="category"
            category={announcement.category}
            setCategory={(value) =>
              setAnnouncement({ ...announcement, category: value })
            }
          />

          <label className="text-[#ececec] mb-2 mx-2 text-lg">Message:</label>
          <textarea
            className="bg-[#1f2022] text-[#ececec] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 md:py-8 md:px-8 rounded-md text-lg leading-tight w-full h-40 resize-none"
            aria-label="Message"
            name="message"
            value={announcement.message}
            placeholder="Enter your message..."
            onChange={handleTextareaChange}
          />

          <div className="flex items-center justify-center">
            <Button text="Submit" type="submit" />
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default AnnouncementForm;
