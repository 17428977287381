import Moment from "react-moment";
import { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCarReports,
  deleteCarReport,
} from "../../../../Features/carReport/carReportSlice";
import Pagination from "../../../Pagination/Pagination";
import Checklist from "../../../Checklist/Checklist";
import Modal from "../../../Modal/Modal";
import TableSkeleton from "./TableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FaCheck } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const ReportTable = ({ carReports, handleRowClick, openDeleteModal, user }) => (
  <table>
    <tbody>
      <tr className="text-center">
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          License plate:
        </th>
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Comment:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Make & model:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Charging cable:
        </th>
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Employee:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          General checklist:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Service Checklist:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-1 text-center pb-2">
          Created at:
        </th>
      </tr>

      {carReports &&
        carReports.data &&
        carReports.data.map((report) => (
          <TableRow
            key={report._id}
            report={report}
            handleRowClick={handleRowClick}
            openDeleteModal={openDeleteModal}
            user={user}
          />
        ))}
    </tbody>
  </table>
);

const TableRow = ({ report, handleRowClick, openDeleteModal, user }) => (
  <tr
    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
    onClick={() => handleRowClick(report._id)}
  >
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.licensePlate}
    </td>
    <td className="flex items-center justify-center pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.comment && report.comment !== "" && (
        <FaCheck className="text-[#1ce5be]" />
      )}
    </td>
    <td className="hidden md:table-cell pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.brand} {report.model}
    </td>
    <td className="hidden md:table-cell pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.chargingCable}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.firstName}
    </td>
    <td className="hidden md:table-cell pr-3 py-3 text-sm text-center text-[#ececec]">
      <Checklist count={report.generalChecklistLength} totalStars={4} />
    </td>
    <td className="hidden md:table-cell pr-3 py-3 text-sm text-center text-[#ececec]">
      <Checklist count={report.serviceChecklistLength} totalStars={7} />
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      <Moment format="DD-MM-YYYY">{report.createdAt}</Moment>
    </td>
    {user.role === "Admin" && (
      <td className="hidden md:table-cell pr-3 py-3 text-sm text-center text-[#ececec]">
        <MdDelete
          className="text-lg"
          onClick={(e) => openDeleteModal(e, report._id)}
        />
      </td>
    )}
  </tr>
);

const CarReportTable = () => {
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState(false);
  const [reportId, setReportId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleRowClick = (id) => {
    window.open(`/home/deilibilarReports/${id}`, "_blank");
  };

  const openDeleteModal = (e, id) => {
    e.stopPropagation();
    setReportId(id);
    setDeleteModal(true);
  };

  const deleteReport = (e) => {
    setDeleteModal(false);

    dispatch(deleteCarReport(reportId))
      .then(() => {
        dispatch(getCarReports(currentPage));
        successToast("Report deleted successfully!");
      })
      .catch((error) => {
        errToast("Something went wrong!");
        console.log(error);
      });
  };

  const { carReports, isLoading } = useSelector((state) => state.carReport);

  const memoCarReports = useMemo(() => carReports, [carReports]);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCarReports(currentPage));
  }, [dispatch, currentPage]);

  const lastReportIndex = currentPage * rowsPerPage;
  const firstReportIndex = lastReportIndex - rowsPerPage;

  return (
    <>
      <div className="">
        <section className="flex items-center flex-col justify-center text-[#ececec]">
          <div className="text-2xl my-3 text-[#ff5783] uppercase">
            <h3>Deilibilar reports</h3>
          </div>

          {isLoading ? (
            <TableSkeleton />
          ) : (
            <ReportTable
              carReports={memoCarReports}
              handleRowClick={handleRowClick}
              openDeleteModal={openDeleteModal}
              user={user}
            />
          )}
          <Pagination
            totalReports={carReports.totalDailyCarReports}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </section>
      </div>
      {deleteModal && (
        <Modal
          message="Are you sure you want to delete deilibilar report ?"
          onClick={deleteReport}
          setOpenModal={setDeleteModal}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default CarReportTable;
