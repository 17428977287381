const AnnouncementDropdown = ({ label, name, category, setCategory }) => {
  const onChange = (e) => {
    setCategory(e.target.value);
  };

  return (
    <div className="flex flex-col md:flex-row md:items-center my-4 md:justify-between">
      <label className="text-[#ececec] mb-2 mx-2 text-lg">{label}:</label>
      <select
        className="bg-[#1f2022] text-[#ececec] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 rounded-md text-lg leading-tight"
        aria-label={label}
        name={name}
        value={category}
        required
        onChange={onChange}
      >
        <option></option>
        <option className="text-[#e74c3c]" value="High">
          High
        </option>
        <option className="text-[#f1c40f]" value="Medium">
          Medium
        </option>
        <option className="text-[#457FE5]" value="General information">
          General information
        </option>
      </select>
    </div>
  );
};

export default AnnouncementDropdown;
