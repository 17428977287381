import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// create cargo
const createCargo = async (cargoData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/dashboard/fleet/createNewCargo`,
    cargoData,
    config
  );

  return response.data;
};

//GET cargo vehicles
const getCargos = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${API_LINK}/api/dashboard/allFleet/cargo`,
    config
  );

  return response.data;
};

//GET Cargo by ID
const getSingleCargo = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/allFleet/cargo/${id}`,
    config
  );

  return response.data;
};

const updateCargoStatus = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/dashboard/allFleet/disableCargo/${id}`,
    id,
    config
  );

  return response.data;
};

const cargoService = {
  createCargo,
  getCargos,
  getSingleCargo,
  updateCargoStatus,
};

export default cargoService;
