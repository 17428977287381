import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCargos } from "../../../Features/newFleet/cargoSlice";
import Spinner from "../../Spinner/Spinner";
import IssueCount from "../../IssueCount/IssueCount";

const AllCargoCars = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allCargos, isLoading } = useSelector((state) => state.cargo);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCargos());
  }, [dispatch]);

  const handleRowClick = (id) => {
    if (user.role === "Admin") {
      navigate(`/dashboard/allFleet/cargoVehicles/${id}`);
    } else if (user.role === "Captain") {
      navigate(`/home/cargoVehicles/${id}`);
    } else {
      navigate(`/index/serviceCars/${id}`);
    }
  };

  const filteredCargos = allCargos.filter((el) => el.status !== "Disabled");

  return (
    <div className="mt-10">
      <section className="flex items-center flex-col justify-center text-[#ececec]">
        {isLoading ? (
          <Spinner />
        ) : (
          <table>
            <tbody>
              <tr className="text-center">
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  Issues:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  License plate:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  QR code:
                </th>
                <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
                  Make & model:
                </th>
              </tr>
              {filteredCargos.map((cargo, index) => {
                return (
                  <tr
                    key={index}
                    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
                    onClick={() => handleRowClick(cargo._id)}
                  >
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      <IssueCount issues={cargo.issues} />
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      {cargo.licensePlate}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      #{cargo.qrCode}
                    </td>
                    <td className="pr-3 py-3 text-center text-[#ececec] ">
                      {cargo.model}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </section>
    </div>
  );
};

export default AllCargoCars;
