import Moment from "react-moment";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getShiftReports } from "../../../../Features/shiftReport/shiftReportSlice";
import Pagination from "../../../Pagination/Pagination";
import ShiftType from "../../../ShiftType/ShiftType";
import ShiftAverage from "../../../ShiftAverage/ShiftAverage";
import TableSkeleton from "./TableSkeleton";

const ReportTable = ({
  allShiftReports,
  user,
  setDate,
  setShiftType,
  filteredReports,
  handleRowClick,
}) => (
  <table>
    <tbody>
      <tr className="text-center">
        {user.role === "Admin" ? (
          <th className="text-[#1ce5be] hidden md:table-cell pr-3 text-center pb-2">
            Shift average:
          </th>
        ) : null}
        <th className="text-[#1ce5be] pr-3 text-center pb-2">
          <select
            className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 rounded-md text-md leading-tight"
            name="hopper"
            onChange={(e) => setShiftType(e.target.value)}
          >
            <option value="" className="text-[#ececec]">
              Shift type:
            </option>
            <option value="Morning">Morning</option>
            <option value="Evening">Evening</option>
            <option value="Night">Night</option>
          </select>
        </th>
        <th className="text-[#1ce5be] hidden md:table-cell pr-3 text-center pb-2">
          Active scooters:
        </th>
        <th className="text-[#1ce5be] hidden md:table-cell pr-3 text-center pb-2">
          Disabled scooters:
        </th>
        <th className="text-[#1ce5be] hidden md:table-cell pr-3 text-center pb-2">
          MTC scooters:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          Employee count:
        </th>
        <th className="text-[#1ce5be] hidden md:table-cell text-sm pr-3 mx-2 text-center pb-2">
          Deilibilar service:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-1 text-center pb-2">
          Captain:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          <input
            type="date"
            className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 rounded-md text-sm md:text-md leading-tight"
            onChange={(e) => setDate(e.target.value)}
          />
        </th>
      </tr>
      {allShiftReports &&
        allShiftReports.data &&
        filteredReports.map((report) => (
          <TableRow
            key={report._id}
            report={report}
            handleRowClick={handleRowClick}
            user={user}
          />
        ))}
    </tbody>
  </table>
);

const TableRow = ({ report, handleRowClick, user }) => (
  <tr
    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
    onClick={() => handleRowClick(report._id)}
  >
    {user.role === "Admin" ? (
      <td className="pr-3 py-3 hidden md:table-cell text-sm text-center text-[#ececec]">
        <ShiftAverage score={report.shiftAverage} />
      </td>
    ) : null}
    <td className="pr-3 py-3 flex items-center justify-center text-center text-[#ececec]">
      <ShiftType shiftType={report.shiftType} />
    </td>
    <td className="pr-3 py-3 hidden md:table-cell text-sm text-center text-[#ececec]">
      {report.activeScooters}
    </td>
    <td className="pr-3 py-3 hidden md:table-cell text-sm text-center text-[#ececec]">
      {report.disabledScooters}
    </td>
    <td className="pr-3 py-3 hidden md:table-cell text-sm text-center text-[#ececec]">
      {report.maintenanceScooters}
    </td>
    <td className="pr-3 py-2 text-sm text-center text-[#ececec]">
      {report.employeeCount}
    </td>
    <td className="pr-3 py-2 hidden md:table-cell text-sm text-center text-[#ececec]">
      {report.deilibilarService.hours} h {report.deilibilarService.minutes} min
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.firstName}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      <Moment format="DD-MM-YYYY">{report.createdAt}</Moment>
    </td>
  </tr>
);

const AllShiftReportTable = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleRowClick = (id) => {
    if (user.role === "Admin") {
      window.open(`/dashboard/shiftReports/${id}`, "_blank");
    } else {
      window.open(`/home/shiftReports/${id}`, "_blank");
    }
  };

  const { allShiftReports, isLoading } = useSelector(
    (state) => state.shiftReport
  );

  let filteredReports = allShiftReports.data;

  const [shiftType, setShiftType] = useState(null);
  const [date, setDate] = useState(null);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const filter = {
      page: currentPage,
    };

    if (date) {
      filter.date = date;
    }

    if (shiftType) {
      filter.shiftType = shiftType;
    }

    dispatch(getShiftReports(filter));
  }, [dispatch, currentPage, date, shiftType]);

  const lastReportIndex = currentPage * rowsPerPage;
  const firstReportIndex = lastReportIndex - rowsPerPage;

  return (
    <div className="">
      <section className="flex items-center flex-col justify-center text-[#ececec]">
        <div className="text-2xl my-3 text-[#ff5783] uppercase">
          <h3>Shift reports</h3>
        </div>

        {isLoading ? (
          <TableSkeleton />
        ) : (
          <ReportTable
            allShiftReports={allShiftReports}
            user={user}
            setDate={setDate}
            setShiftType={setShiftType}
            filteredReports={filteredReports}
            handleRowClick={handleRowClick}
          />
        )}

        <Pagination
          totalReports={allShiftReports.totalShiftReports}
          rowsPerPage={rowsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </section>
    </div>
  );
};

export default AllShiftReportTable;
