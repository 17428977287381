import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shiftReportService from "./shiftReportService";

const initialState = {
  shiftReport: [],
  latestShiftReports: [],
  allShiftReports: [],
  shiftNumbers: [],
  singleShiftReport: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new shift report
export const createShiftReport = createAsyncThunk(
  "home/shiftReport/create",
  async (shiftReportData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shiftReportService.createShiftReport(shiftReportData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShiftNumbers = createAsyncThunk(
  "home/hopperStats",
  async (filter, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shiftReportService.getShiftNumbers(filter, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//UPDATE SHIFT REPORTS
export const updateReport = createAsyncThunk(
  "/dashboard/shiftReports/update",
  async (shiftReportData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shiftReportService.updateShiftReport(shiftReportData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//GET ALL SHIFT REPORTS
export const getShiftReports = createAsyncThunk(
  "/dashboard/shiftReports",
  async (filter, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shiftReportService.getAllShiftReports(filter, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//GET ALL SHIFT REPORTS
export const getLatestShiftReports = createAsyncThunk(
  "/dashboard/shiftReports/latest",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shiftReportService.getLatestShiftReports(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//GET SHIFT REPORT BY ID
export const getShiftReportById = createAsyncThunk(
  "/dashboard/shiftReports/id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await shiftReportService.getSingleShiftReport(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const shiftSlice = createSlice({
  name: "shiftReport",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createShiftReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createShiftReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.shiftReport.push(action.payload);
      })
      .addCase(createShiftReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getShiftNumbers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShiftNumbers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.shiftNumbers = action.payload;
      })
      .addCase(getShiftNumbers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.shiftReport.push(action.payload);
      })
      .addCase(updateReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getShiftReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShiftReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allShiftReports = action.payload;
      })
      .addCase(getShiftReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getLatestShiftReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLatestShiftReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.latestShiftReports = action.payload;
      })
      .addCase(getLatestShiftReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getShiftReportById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShiftReportById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleShiftReport = action.payload;
      })
      .addCase(getShiftReportById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = shiftSlice.actions;
export default shiftSlice.reducer;
