import Select from "react-select";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCargos } from "../../../../../Features/newFleet/cargoSlice";

const CargoMultiselect = ({ onChange }) => {
  const dispatch = useDispatch();

  const { allCargos } = useSelector((state) => state.cargo);

  useEffect(() => {
    dispatch(getCargos());
  }, [dispatch]);

  const filteredCargos = allCargos.filter((el) => el.status !== "Disabled");

  const cargos = filteredCargos.map((cargo) => ({
    value: cargo.licensePlate,
    label: cargo.licensePlate,
  }));

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#1f2022",
      borderRadius: "5px",
      border: "1px solid #1ce5be",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
      paddingRight: "5px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ececec",
      borderRadius: "7px",
      boxShadow: "none",
      backgroundColor: "#1f2022",
      color: "#1ce5be",
      fontSize: "18px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#1f2022",
      color: "#1ce5be",
      fontSize: "18px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
    }),
  };

  return (
    <Select
      isMulti
      name="cargos"
      options={cargos}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={onChange}
      styles={customStyles}
    />
  );
};

export default CargoMultiselect;
