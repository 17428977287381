import logo from "./../../Assets/Images/main-logo.jpg";
import UserDropdown from "../../Components/UserDropdown/UserDropdown";
import { Link } from "react-router-dom";
import Announcement from "./Announcement";

export const Header = () => {
  return (
    <>
      <Announcement />
      <div className="d-flex flex-row justify-content-between align-items-center my-3">
        <div className="logo col-4">
          <Link to="/home">
            <img src={logo} className="ml-3 w-24 md:w-32" alt="Hopp Logo" />
          </Link>
        </div>

        <UserDropdown />
      </div>
    </>
  );
};

export default Header;
