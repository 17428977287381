import React from "react";
import EmployeeComponent from "../../Components/Forms/EmployeeForm/EmployeeComponent";

const NewEmployee = () => {
  return (
    <>
      <section className="w-full flex justify-center items-center flex-col">
        <h3 className="text-2xl text-[#ff5783] uppercase">New employee</h3>
        <EmployeeComponent />
      </section>
    </>
  );
};

export default NewEmployee;
