import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCarReport } from "../../../Features/carReport/carReportSlice";

import Spinner from "../../Spinner/Spinner";
import { HiCheck } from "react-icons/hi";
import DateAndTime from "../../UI/dateAndTime/DateAndTime";

const UpdateCarReport = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [updatedReport, setUpdatedReport] = useState();

  const { singleReport } = useSelector((state) => state.carReport);

  useEffect(() => {
    dispatch(getCarReport(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (singleReport) {
      setUpdatedReport(singleReport);
    }
  }, [singleReport, setUpdatedReport]);

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const formatDate = formatDateTime(updatedReport && updatedReport.createdAt);

  console.log(formatDate);

  return (
    <>
      <main className="flex justify-center mt-3 flex-col">
        <section className="flex flex-col items-center my-10 md:mr-10">
          <h2 className="text-[#ff5783] uppercase mb-3 font-bold">
            {singleReport.deilibilarId?.brand}{" "}
            {singleReport.deilibilarId?.model}
          </h2>
          <span className="text-[#ececec] text-lg mt-2">
            {singleReport.deilibilarId?.licensePlate}
          </span>
        </section>

        <section className="flex flex-col justify-center items-center">
          <div className="flex flex-col">
            <span className="text-[#ff5783] text-lg font-semibold">
              Created at:
            </span>

            {updatedReport && updatedReport.createdAt && (
              <DateAndTime value={formatDate} />
            )}

            {/* <Moment className="text-[#ececec] mb-3" format="DD-MM-YYYY HH:mm">
              {singleReport?.createdAt}
            </Moment> */}
          </div>

          <div className="flex flex-col">
            <span className="text-[#ff5783] text-lg font-semibold">
              Mileage:
            </span>
            <span className="text-[#ececec] mb-3">
              {singleReport.mileage} km
            </span>
          </div>

          <div className="flex flex-col">
            <span className="text-[#ff5783] text-lg font-semibold">
              Captain:
            </span>
            <span className="text-[#ececec] mb-3">
              {singleReport.userCreated?.firstName}{" "}
              {singleReport.userCreated?.lastName}
            </span>
          </div>

          {singleReport.generalCheck?.checklist ? (
            <div className="flex flex-col">
              <span className="text-[#ff5783] text-lg font-semibold mb-2">
                General checklist:
              </span>
              <div className="flex flex-col mb-3">
                <ul className="text-[#ececec]">
                  {singleReport.generalCheck?.checklist
                    ? singleReport.generalCheck?.checklist?.map(
                        (check, index) => (
                          <li
                            key={index}
                            className="flex mb-1 text-lg items-center"
                          >
                            <HiCheck className="text-[#1ce5be] mr-2 mt-1" />

                            {check}
                          </li>
                        )
                      )
                    : null}
                </ul>
              </div>
            </div>
          ) : (
            <Spinner />
          )}

          {singleReport.serviceCheck?.checklist ? (
            <div className="flex flex-col">
              <span className="text-[#ff5783] text-lg font-semibold mb-2">
                Service checklist:
              </span>
              <div className="flex flex-col mb-3">
                <ul className="text-[#ececec]">
                  {singleReport.serviceCheck?.checklist
                    ? singleReport.serviceCheck?.checklist?.map(
                        (check, index) => (
                          <li
                            key={index}
                            className="flex mb-1 text-lg items-center"
                          >
                            <HiCheck className="text-[#1ce5be] mr-2 mt-1" />

                            {check}
                          </li>
                        )
                      )
                    : null}
                </ul>
              </div>
            </div>
          ) : (
            <Spinner />
          )}

          <div className="flex flex-col mb-10">
            <span className="text-[#ff5783] text-lg font-semibold mb-3">
              Images:
            </span>
            <div className="flex">
              {singleReport.images?.map((img, index) => {
                return (
                  <div className="cursor-disabled flex flex-row" key={index}>
                    <img
                      className="w-28 mr-3 rounded-md"
                      src={img.url}
                      alt="report photos"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default UpdateCarReport;
