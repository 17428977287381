import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// create deilibilar
const createNewDeilibilar = async (fleetData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_LINK}/api/dashboard/fleet/createNewDeilibilar`,
    fleetData,
    config
  );

  return response.data;
};

//GET deilibilars
const getDeilibilars = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${API_LINK}/api/dashboard/allFleet/deilibilar`,
    config
  );

  return response.data;
};

//GET Deilibilar by ID
const getSingleDeilibilar = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/allFleet/deilibilar/${id}`,
    config
  );

  return response.data;
};

// UPDATE DEILIBILAR INFO
const updateInfo = async (deilibilarData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/dashboard/allFleet/deilibilar/update/${deilibilarData._id}`,
    deilibilarData,
    config
  );

  return response.data;
};

//  DISABLE DEILIBILAR STATUS
const updateDeilibilarStatus = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_LINK}/api/dashboard/allFleet/disableDeilibilar/${id}`,
    id,
    config
  );

  return response.data;
};

const fleetService = {
  createNewDeilibilar,
  getDeilibilars,
  getSingleDeilibilar,
  updateDeilibilarStatus,
  updateInfo,
};

export default fleetService;
