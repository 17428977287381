import React from "react";
import flag from "../../Assets/Images/flag-iceland.svg";

const LicensePlate = ({ plateNumber }) => {
  return (
    <div className="flex bg-[#ececec] w-40 h-10 rounded">
      <div className="w-10 items-center justify-center h-full">
        <div className="flex flex-col items-center justify-center">
          <img src={flag} alt="Iceland flag" className="w-4" />
          <span className="text-black font-extrabold">IS</span>
        </div>
      </div>
      <div className="flex items-center justify-center w-full h-full">
        <h1 className="text-[black] font-extrabold tracking-widest text-2xl">
          {plateNumber}
        </h1>
      </div>
    </div>
  );
};

export default LicensePlate;
