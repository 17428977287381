import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import issueService from "./issuesService";

const initialState = {
  issues: [],
  resolvedIssues: [],
  updateAnIssue: [],
  latestIssue: {},
  singleIssue: [],
  issuesById: [],
  allIssues: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// NEW DEILIBILAR ISSUE
export const createNewDeilibilarIssue = createAsyncThunk(
  "home/deilibilar/newIssue",
  async (issueData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await issueService.createNewIssue(issueData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ALL PENDING DEILIBLAR ISSUES
export const getAllIssues = createAsyncThunk(
  "dashboard/fleet/allIssues",
  async (page, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await issueService.getAllDeilibilarPendingIssues(page, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// SINGLE DEILIBILAR ISSUE
export const getSingleIssue = createAsyncThunk(
  "dashboard/fleet/allIssues/:id",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await issueService.getIssueById(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//GET DEILIBILAR ISSUES BY ID
export const getDeilibilarIssueById = createAsyncThunk(
  "home/deilibilar/issues/id",
  async (deilibilarId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await issueService.getDeilibilarIssues(deilibilarId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//GET LATEST DEILIBILAR ISSUE
export const getNewestIssue = createAsyncThunk(
  "home/deilibilar/latestIssue",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await issueService.getLatestIssue(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//GET RESOLVED DEILIBILAR ISSUES BY ID
export const getResolvedDeilibilarIssues = createAsyncThunk(
  "home/deilibilar/resolvedIssues/id",
  async (deilibilarId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await issueService.getResolvedIssues(deilibilarId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//UPDATE DEILIBILAR ISSUE
export const updateIssue = createAsyncThunk(
  "/home/deilibilar/issues/resolved",
  async (updateData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await issueService.updateDeilibilarIssue(updateData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const issueSlice = createSlice({
  name: "deilibilarIssues",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewDeilibilarIssue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewDeilibilarIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.issues.push(action.payload);
        state.message = action.payload.message;
      })
      .addCase(createNewDeilibilarIssue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateIssue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.updateAnIssue.push(action.payload);
        state.message = action.payload.message;
      })
      .addCase(updateIssue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDeilibilarIssueById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDeilibilarIssueById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.issuesById = action.payload;
      })
      .addCase(getDeilibilarIssueById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSingleIssue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleIssue = action.payload;
      })
      .addCase(getSingleIssue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllIssues.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllIssues.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allIssues = action.payload;
      })
      .addCase(getAllIssues.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getResolvedDeilibilarIssues.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResolvedDeilibilarIssues.fulfilled, (state, action) => {
        state.isLoading = false;
        state.resolvedIssues = action.payload;
      })
      .addCase(getResolvedDeilibilarIssues.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getNewestIssue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNewestIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.latestIssue = action.payload;
      })
      .addCase(getNewestIssue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = issueSlice.actions;
export default issueSlice.reducer;
