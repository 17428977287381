import { Bar } from "react-chartjs-2";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Captain statistics",
      color: "#ececec",
      font: {
        size: 18,
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#ececec",
      },
      grid: {
        color: "rgba(236, 236, 236, 0.25)",
      },
    },
    y: {
      ticks: {
        color: "#ececec",
      },
      grid: {
        color: "rgba(236, 236, 236, 0.25)",
      },
    },
  },
};

const ShiftBar = ({ reportData }) => {
  const labels = [
    "Replaced batteries",
    "Scooters fixed",
    "Hopper hikes",
    "Scooters left on MTC",
    "Scooters picked up MTC",
    "Rebalanced scooters",
  ];

  let sumBatteries = 0;
  let sumFixed = 0;
  let sumHikes = 0;
  let sumLeftMtc = 0;
  let sumPickedMtc = 0;
  let sumRebalance = 0;

  reportData &&
    reportData.forEach((obj) => {
      sumBatteries += parseInt(obj.batteries);
      sumFixed += parseInt(obj.fixed);
      sumHikes += parseInt(obj.hikes);
      sumLeftMtc += parseInt(obj.leftMtc);
      sumPickedMtc += parseInt(obj.pickedMtc);
      sumRebalance += parseInt(obj.rebalance);
    });

  const data = {
    labels,
    datasets: [
      {
        data: [
          sumBatteries,
          sumFixed,
          sumHikes,
          sumLeftMtc,
          sumPickedMtc,
          sumRebalance,
        ],
        backgroundColor: "#1ce5be",
      },
    ],
  };

  return (
    <section className="flex items-center justify-center">
      <div className="flex items-center justify-center mt-5 ">
        <Bar options={options} data={data} height="450px" width="380px" />
      </div>
    </section>
  );
};

export default ShiftBar;
