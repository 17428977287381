import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCaptains, getHoppers } from "../../../Features/team/teamSlice";

const EmployeeDropdown = ({ onHandle, index, shiftNumbers }) => {
  const dispatch = useDispatch();

  const { captain, hopper } = useSelector((state) => state.team);

  useEffect(() => {
    dispatch(getCaptains());
    dispatch(getHoppers());
  }, [dispatch]);

  let hoppersOnShift = shiftNumbers.map((name, index) => {
    return (
      <option key={index} disabled value={name.staff_id}>
        {name.firstName ? name.firstName : name.staff_id}
      </option>
    );
  });

  let captainList =
    captain.length > 0 &&
    captain.map((cpt, index) => {
      return (
        <option
          key={index}
          value={JSON.stringify({
            firstName: cpt.firstName,
            lastName: cpt.lastName,
            api_id: cpt.api_id,
          })}
        >
          {cpt.firstName} {cpt.lastName}
        </option>
      );
    });

  let hopperList =
    hopper.length > 0 &&
    hopper.map((hopp, index) => {
      return (
        <option
          key={index}
          value={JSON.stringify({
            firstName: hopp.firstName,
            lastName: hopp.lastName,
            api_id: hopp.api_id,
          })}
        >
          {hopp.firstName} {hopp.lastName}
        </option>
      );
    });

  return (
    <>
      <select
        className="bg-[#1f2022] text-[#1ce5be] border-[#ececec] border-1 focus:border-[#ececec] inline-block py-2 px-2 rounded-md text-lg leading-tight"
        name="hopper"
        onChange={(e) => onHandle(e, index)}
      >
        <option value={""} disabled className="text-[#ececec]">
          -- Hoppers on shift --
        </option>
        {hoppersOnShift}
        <option value="" disabled className="text-[#ececec]">
          -- Captains --
        </option>
        {captainList}
        <option value="" disabled className="text-[#ececec]">
          -- Hoppers --
        </option>
        {hopperList}
      </select>
    </>
  );
};

export default EmployeeDropdown;
