import { useState } from "react";
import { HiCheck, HiDocumentReport } from "react-icons/hi";
import { GoIssueOpened } from "react-icons/go";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeilibilarIssueById,
  getResolvedDeilibilarIssues,
  reset,
} from "../../../Features/deilibilarIssues/issuesSlice";
import { getDeilibilarProfile } from "../../../Features/newFleet/fleetSlice";
import ResolvedIssueTable from "../DailyCarResolvedIssues/ResolvedIssueTable";
import Spinner from "../../Spinner/Spinner";
import ReportTable from "../../SingleComponents/DailyCar/ReportTable";
import IssueTable from "../DailyCarIssues/IssueTable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const successToast = (message) => {
  toast.success(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

const errToast = (message) =>
  toast.error(message, {
    position: "top-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const DailyCarTabs = () => {
  const [toggleTab, setToggleTab] = useState(1);

  const handleToggle = (index) => {
    setToggleTab(index);
  };

  const { id } = useParams();

  const { issuesById, resolvedIssues, isLoading, isError, isSuccess, message } =
    useSelector((state) => state.deilibilarIssues);

  const { fleetInfo } = useSelector((state) => state.fleet);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      errToast(message);
      dispatch(reset());
    }

    if (isSuccess) {
      successToast(message);
      dispatch(reset());
    }

    dispatch(getDeilibilarProfile(id));
    dispatch(getDeilibilarIssueById(id));
    dispatch(getResolvedDeilibilarIssues(id));
  }, [dispatch, id, message, isError, isSuccess]);

  return (
    <>
      <div className="flex items-center flex-col justify-center">
        <div className="border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li className="mr-2">
              <span
                className={
                  toggleTab === 1
                    ? "inline-flex hover:cursor-pointer  items-center text-lg p-4 text-[#1ce5be] border-b-2 border-[#1ce5be] rounded-t-lg active dark:text-[#1ce5be] dark:border-[#1ce5be] group"
                    : "inline-flex hover:cursor-pointer  items-center text-lg p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                }
                onClick={() => handleToggle(1)}
              >
                <GoIssueOpened className="w-5 h-5 mx-2" />
                Issues{" "}
                <span
                  className="bg-[#f1c40f] text-lg
               rounded-full mx-2 w-7 h-7 text-[#1f2022]"
                >
                  {issuesById ? issuesById?.length : null}
                </span>
              </span>
            </li>

            <li className="mr-2">
              <span
                className={
                  toggleTab === 3
                    ? "inline-flex hover:cursor-pointer items-center text-lg p-4 text-[#1ce5be] border-b-2 border-[#1ce5be] rounded-t-lg active dark:text-[#1ce5be] dark:border-[#1ce5be] group"
                    : "inline-flex hover:cursor-pointer items-center text-lg p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                }
                onClick={() => handleToggle(3)}
              >
                <HiDocumentReport className="w-5 h-5 mx-2" />
                Reports
              </span>
            </li>

            <li className="mr-2">
              <span
                className={
                  toggleTab === 2
                    ? "inline-flex hover:cursor-pointer items-center text-lg p-4 text-[#1ce5be] border-b-2 border-[#1ce5be] rounded-t-lg active dark:text-[#1ce5be] dark:border-[#1ce5be] group"
                    : "inline-flex hover:cursor-pointer items-center text-lg p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                }
                onClick={() => handleToggle(2)}
              >
                <HiCheck className="w-5 h-5 mx-2" />
                Resolved
              </span>
            </li>
          </ul>
        </div>

        {/* Content */}
        <div className="">
          <div
            className={toggleTab === 1 ? "block md:block" : "hidden md:hidden"}
          >
            <IssueTable allIssues={issuesById} id={id} fleetInfo={fleetInfo} />
          </div>
          <div
            className={toggleTab === 3 ? "block md:block" : "hidden md:hidden"}
          >
            {isLoading ? <Spinner /> : <ReportTable />}
          </div>
          <div
            className={toggleTab === 2 ? "block md:block" : "hidden md:hidden"}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <ResolvedIssueTable resolvedIssues={resolvedIssues} />
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default DailyCarTabs;
