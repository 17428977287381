import { useState } from "react";
import { GoIssueOpened } from "react-icons/go";
import AnnouncementTable from "../AnnouncementTable/AnnouncementTable";

const AnnouncementTabs = () => {
  const [toggleTab, setToggleTab] = useState(1);

  const handleToggle = (index) => {
    setToggleTab(index);
  };

  return (
    <div className="flex items-center flex-col justify-center">
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
          <li className="mr-2">
            <span
              className={
                toggleTab === 1
                  ? "inline-flex hover:cursor-pointer  items-center text-lg p-4 text-[#1ce5be] border-b-2 border-[#1ce5be] rounded-t-lg active dark:text-[#1ce5be] dark:border-[#1ce5be] group"
                  : "inline-flex hover:cursor-pointer  items-center text-lg p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
              }
              onClick={() => handleToggle(1)}
            >
              <GoIssueOpened className="w-5 h-5 mx-2" />
              Active announcements
            </span>
          </li>
        </ul>
      </div>

      {/* Content */}
      <div className="">
        <div
          className={toggleTab === 1 ? "block md:block" : "hidden md:hidden"}
        >
          <AnnouncementTable />
        </div>
      </div>
    </div>
  );
};

export default AnnouncementTabs;
