const SwitchBtn = ({
  label,
  description,
  type,
  id,
  name,
  value,
  generalChecklist,
  setGeneralChecklist,
}) => {
  const isChecked = generalChecklist.checklist.includes(value);

  const handleChecklist = (e) => {
    const { value, checked } = e.target;
    const { checklist } = generalChecklist;

    // Case 1 : The user checks the box
    if (checked) {
      setGeneralChecklist({
        checklist: [...checklist, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setGeneralChecklist({
        checklist: checklist.filter((e) => e !== value),
      });
    }
  };

  return (
    <div className="flex my-2 flex-col">
      <div className="form-check form-switch">
        <input
          className="form-check-input appearance-none w-12 -ml-14 mr-3 px-4 rounded-full float-left h-7 align-top bg-[#ececec] bg-no-repeat bg-contain focus:outline-none cursor-pointer shadow-sm"
          type={type}
          id={id}
          name={name}
          value={value}
          checked={isChecked}
          onChange={handleChecklist}
        />
        <label className="form-check-label inline-block text-[#1ce5be] mt-1 text-lg w-52 md:w-64">
          {label}
        </label>
      </div>
      <div className="my-2">
        <ul>
          {description &&
            description.map((item, index) => (
              <li key={index} className="text-[#ff5783]">
                {item}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SwitchBtn;
