import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HopperHeader from "../Header/HopperHeader";

const HopperLayout = () => {
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return navigate("/");
    }
  }, [user, navigate]);

  return (
    <>
      <header>
        <HopperHeader />
      </header>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default HopperLayout;
